import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import ContactModal from "../../components/ContactModal";
import EditModal from "../../components/EditModal";
// import { Select } from 'react-select';
import Modal from "react-bootstrap/Modal";
import styles from ".././addContact.module.css";
import AddContactForm from '.././../components/AddContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessContact } from '../../Redux/BusinessContacts/action';


const Users = (props) => {
//   let user;
  let userrole;
  let usersid;
  let navigate = useNavigate();
  let id;
  const user = JSON.parse( window.localStorage.getItem('business-client'));
  const params = useParams();
  if(user?.user?._id){
    id=user?.user?._id;
  }else{
    id = null;
  }
  

  
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [nameOrder, setNameOrder] = useState("ASC");
  const [emailOrder, setEmailOrder] = useState("ASC");
  const [phoneorder, setPhoneorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [customerOrder, setCustomerOrder] = useState("ASC");
  const [brandOrder, setBrandOrder] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const shouldLog = useRef(true);
  const [csvData, setCsvData] = useState([]);
  const [showCsvModal, setshowCsvModal] = useState(false);
  const [partnerId,setPartnerId] = useState(id);
  const [sort, setSort] = useState("");
  const [showPage,setShowPage] = useState(1);
  const [disable, setDisable] = useState(false);
  // const [contactId,setContactId] = useState("");

  const handleClosehandleCsvModal = () => {
    setshowCsvModal(!showCsvModal);
  };



  
  const setCustomers = (userDataInString) => {
    var userDataInJSON = [];

    // Coverting String to Array of JSON Objects
    var rows = userDataInString.split("\n");

    var er = rows[0].search("\r");
    if(er>1){
      // rows[0] = rows[0].slice(0);
      rows[0] = rows[0].slice(0, -1);
    }else{
       rows[0] = rows[0].slice(0);
      //  rows[0] = rows[0].slice(0, -1);
    } 

    var fields = rows[0].split(",");


    if(fields[0] =="first_name" && fields[1] =="last_name" &&  fields[2] == "email" && fields[3] == "code" && fields[4] == "phone" && fields[5] == "contact_type"){  


    for (var i = 1; i < rows.length ; i++) {
      // To check null fields
      var flag = false;

      // Creating individual JSON objects from string
      var singleUserObject = {};

      // To handle the extra space in the end
      // rows[i] = rows[i].slice(0, -1);


      var ers = rows[i].search("\r");
      if(ers>=0){
      // rows[i] = rows[i].slice(0);
       rows[i] = rows[i].slice(0, -1);
       
      }else{
        rows[i] = rows[i].slice(0);
      //  rows[i] = rows[i].slice(0, -1);
      }



      // Extracting the data for the objects
      var singleRow = rows[i].split(",");

      for (var j = 0; j < fields.length; j++) {
        if (singleRow[j] === "") {
          flag = true;
        }

        // Key-Value Pairs
        singleUserObject[fields[j]] = singleRow[j];
        singleUserObject["partner_id"] = partnerId;
        singleUserObject["password"] = "cust@1234";
        singleUserObject["status"] = 1;
      }


      if (flag) {
        // To be added to Invalid Users
      } else {
        // To be sent to backend
        userDataInJSON.push(singleUserObject);
      }
    }
    setCsvData(userDataInJSON);
  }else{
    toast.warning("Invalid CSV File")
    setCsvData([]);
  }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true)
    setTimeout(() => {
      setDisable(false);
    }, 2000);

    if (csvData.length == 0) {
      toast.warning("Please Upload A CSV File");
      return false;
    }



    for(var i=0; i<csvData.length;i++){
      if(csvData[i].phone){
        if(csvData[i].phone.length<9){
          toast.warning("Phone number must be 10 digits");    
          return false;   
         }

      }


       let texts =csvData[i].first_name;
       let results;
       if(csvData[i].first_name){
        results = texts.replace(/^\s+|\s+$/gm,'');
       }
       if(results.length<=0){
        toast.warning("Please enter a Name");
        return false; 
       }
       let text =csvData[i].email;
       let result;
       if(csvData[i].email){
        result = text.replace(/^\s+|\s+$/gm,'');
       }
       if(result.length<=0){
        toast.warning("Please enter a valid email address");
        return false;       
      }
    }


      
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/businesscontact/csv`,
        csvData,{
          headers: {
            'Authorization' : `Bearer ${token}`
          }
        },
      )
      .then((res) => {
        if (res.data?.status === "failed") {
          toast.warning(res.data.message);
          return false;
        }
        handleClosehandleCsvModal();
        setTimeout(() => {
          // getAllCustomers();
          window.location.reload();
        }, 400);

        toast.success("Csv Uploaded Successfully");
        window.location.reload();
        // getAllCustomers();
        // navigate("/admin/customers");
      })
      .catch((err) => {
        console.log(err);
        if(err?.response?.data?.message){
        toast.error(err?.response?.data?.message);          
        }

      });
  };

  const [isLoading, setIsLoading] = useState(false);
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }
  useEffect(() => {
    
    getAllUsers(pageNumber);
    
    setIsLoading(false);
  }, [pageNumber, search, sort]);


  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
   
      setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const getAllUsers = (pageNumber) => {
   
      const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/customerpartners/${partnerId}?page=${pageNumber}&search=${search}&sort=${sort}`, {
          headers: {
            'Authorization' : `Bearer ${token}`
          }
      }
      )
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }

        const businessPartner = response.data.users;
        const totalPages = response.data.totalPages;
        //const slice = businessPartner.slice(offset - 1 , offset - 1 + postsPerPage)
        setCustomerData(businessPartner);
        setNumberOfPages(totalPages);
        // setPageCount(Math.ceil(businessPartner.length / postsPerPage))
      })
      .catch((error) =>{
        if (error.response.data.message === "Unauthorized User" ){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
      }
        console.error(`Error: ${error}`)
      });
  };

  const dispatch = useDispatch();
  
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    const id = user?.userData?._id;
    const token = user?.token;
  
    dispatch(getBusinessContact(id, token, navigate, toast));
  
  }, [dispatch, navigate, toast]);
  


  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }

  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  //   sorting

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   nameOrder

  const nameSorting = (nameOrder) => {
    if (nameOrder === "DSC") {
      setSort("dscName");

      setNameOrder("DSC");
    } else {
      setSort("ascName");

      setNameOrder("ASC");
    }
  };

  //   type of user

  const emailSorting = (emailOrder) => {
    if (emailOrder === "DSC") {
      setSort("dscEmail");

      setEmailOrder("DSC");
    } else {
      setSort("ascEmail");

      setEmailOrder("ASC");
    }
  };

  const phonesorting = (col) => {
    if (phoneorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("DSC");
    }
    if (phoneorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("ASC");
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
  };
 
  const deleteUser = (id) => {
 
     
      const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    
    axios
      .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/businesscontact/delete/${id}`,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      },)
      .then((response) => {
        toast.success(response.data.message);
        getAllUsers(pageNumber);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  function handleStatus(id, status) {
    if (status === false) {
      status = true;
    } else {
      status = false;
    }
  
      const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/businesscontact/status`, {
        id,
        status,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      },)
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        toast.success(response.data.message);
        getAllUsers(pageNumber);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }
  const [btnColor,setBtnColor] = useState('');
  const [loading,setLoading] = useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [secondaryColor,setSecondaryColor] = useState('');

  // useEffect(()=>{
  //   setLoading(true);
  //  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
  //  .then((res)=>{
  //    if(res.data?.cms?.default_ncentiva === false){ 
  //    setBtnColor(res.data?.cms?.btn_color);
  //    setPrimaryColor(res.data?.cms?.primary_color);
  //    setSecondaryColor(res.data?.cms?.secondary_color);
  //  }else{
  //      setBtnColor('#3D05B6');
  //      setPrimaryColor("#FF7020");
  //      setSecondaryColor("#F5C400");
  //    }
  //    setLoading(false);
  //  }).catch((err)=>{
  //    console.log(err);
  //  })
  // },[])



  return (
    <div className="p-3 p-lg-4 p-xl-5">
    <div className="d-flex mb-4 align-items-center">
      <h1 className="font20 text-dark">Users</h1>
        </div>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
        <Container>
            <Row>
              <Col xl={5} xxl={6} lg={4} className="mt-1" >
                <div className="textGray font12">

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                      <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>

                </div>
              </Col>
              {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
              <Col xl={7} xxl={6} lg={8} className="mt-1 align-items-end " >
                <Row className={styles.breakBtn} >

                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{width:"146.6px"}}>

                    <Button
                      variant="primary"
                      onClick={() => setshowCsvModal(!showCsvModal)}
                      className="fullRounded px-4 py-2 fw-bold font12"
                      style={{width:"136.6px"}}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Upload CSV</span>
                    </Button>
                  </Col>
                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{width:"160.5px"}} >
                    <ContactModal id={id} admin={props?.admin}/>
                  </Col>
                
                </Row>
              </Col>

              {/* end sort */}
            </Row>
          </Container>
          <Col md={12} className="mt-4">
            <div className="table-responsive">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">User Type</span>
                        {/* {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )} */}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Name</span>
                        {nameOrder === "ASC" ? (
                          <FaSortDown onClick={() => nameSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => nameSorting("ASC")} />
                        )}
                      </div>
                    </th>
                 
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Email</span>
                        {emailOrder === "ASC" ? (
                          <FaSortDown onClick={() => emailSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => emailSorting("ASC")} />
                        )}
                      </div>
                    </th>   
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Phone</span>
                        
                      </div>
                    </th>

                    <th >Status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
                  {isLoading === true ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                      <Bars
                      height="80"
                      width="80"
                      color="#FF7020"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}/>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : 
                  (
                    <>
                     {customerData?.length>0 ? customerData?.map((cust,i) =>
                    (

                      <tr key={cust._id}>
                        <td className="font14">
                            {cust.role}
                        </td>
                        <td className="font14">
                            {cust.name} 
                        </td>
                        <td className="font14">{cust.email}</td>
                        <td className="font14 ps-0 ">{cust.code} {cust.phone}</td>
                        <td className="font14">
                          <Form.Check
                            type="switch"
                            label={cust?.status ? "Active" : "Inactive"}
                            className="switchLabel"
                            checked={cust?.status}
                            onChange={(e) => {
                              handleStatus(cust._id, cust.status);
                            }}
                          />
                        </td>
                        <td>
                          {role === "Admin" ? (
                            <DropdownButton
                              variant="white"
                              className="p-0  tableActionDropDown text-center"
                              title={
                                <div className="actionBtn">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              }
                              drop="start"
                            >
                              <Dropdown.Item
                                className="font14 fw-medium"
                              >
                                <EditModal id={cust._id} admin={props.admin}/>
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                onClick={() => {
                                  deleteUser(cust._id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          ) : (
                            <DropdownButton
                            variant="white"
                            className="p-0  tableActionDropDown text-center"
                            title={
                              <div className="actionBtn">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            }
                            drop="start"
                          >
                            <Dropdown.Item
                              className="font14 fw-medium"
                            >
                              <EditModal id={cust._id}/>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="font14 fw-medium"
                              onClick={() => {
                                deleteUser(cust._id);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </DropdownButton>
                          )}
                        </td>
                      </tr>
                    )): <tr>
                       <td colSpan="6" className="text-center">
                        <h1 className="font16 text-dark">No Contacts</h1>
                        </td>
                      </tr>}
                    </> 
                  )}
                </tbody>
              </table>
            </div>
          </Col>


          {/* <Col xs lg="2">
          <input  style={{width:"30px"}} type="number" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input>/ {numberOfPages} 
          </Col> */}
        {numberOfPages >0 ?   <Col className="mt-5 mb-4">
            <ul className="custPagination d-flex justify-content-center list-unstyled font14">

              {pageNumber === 0 ? (
                ""
              ) : (

                <li onClick={prevPage}>Previous </li>

              )}


              {items}

              {numberOfPages - 1 === pageNumber ? (
                ""
              ) : (
                <li onClick={nextPage}>Next</li>
              )}
               <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
            </ul>
          </Col>:''}

        </Row>
      </Container>
      <Modal
        show={showCsvModal}
        onHide={handleClosehandleCsvModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
              File Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>
            <AddContactForm variant="primary" setCustomerData={setCustomers} />
          </Modal.Body>
          <Modal.Footer>{disable? <Button
              variant="primary"
              className="fullRounded px-4 py-2 fw-bold font12"
            >
              Upload
            </Button>
          :
          <Button
              variant="primary"
              type="submit"
              className="fullRounded px-4 py-2 fw-bold font12"
            >
              Upload
            </Button>
          }
            
            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => handleClosehandleCsvModal()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
