import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  // import Category from './elements/Category';
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";

const BrandDetails = () => {
  var token;
  const { id } = useParams();
  // const [active, setActive] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [redemptionInstructions, setRedemptionInstructions] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [imagepath, setImagePath] = useState('');
  useEffect(() => {
    if (id) {
      getbrands();
    }
  }, []);
  let navigate = useNavigate()
  
  const getbrands = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbrandforcoupon/${id}`, {
          headers: {
            'Authorization' : `Bearer ${token}`
          }
        })
        .then((response) => {
          if(response.data.message == "Please Login"){
            localStorage.removeItem('client');
            navigate('/admin');
            toast.error("Session Expired Please Login Again")
          }
          setName(response.data.brand.brandname);
          setDescription(response.data.brand.description);
          setRedemptionInstructions(response.data.brand.redemptionInstructions);
          setTermsAndConditions(response.data.brand.termsAndConditions);
          setImagePath(response.data.brand.img_path)
        })
        .catch((error) => console.error(`Error: ${error}`));
  };
  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="align-items-center pb-5">
            <Col md={5}>
              <div>
                <Link
                  to="/admin/brands"
                  className="p-0 btn btnOrangeTransparent fw-semibold"
                  variant="white"
                >
                  <span className="me-2 fw-semibold">
                    <img
                      src="/assets/images/back-org.png"
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <span>Back</span>
                </Link>
              </div>
            </Col>
            <Col md={7}></Col>
            <Col md={12} className="mt-4">
              <h1 className="font20 text-dark fw-bold mb-4">Coupons Details : {name}</h1>
              <div className="row fw-medium">
                <Col md={6}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={12} className="mb-4">
                      <img src={imagepath} className="img-fluid" alt="" />
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Description</h3>
                        <p>{description}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Redemption Instructions</h3>
                        <p>{redemptionInstructions}</p>
                      </div>

                      <div className="mb-3">
                        <h3 className="text-dark font14 mb-2">Terms and conditions</h3>
                        <p>{termsAndConditions}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={6}>
                  <Row className="mb-4 align-items-center">
                    <Col lg={12}>
                      <h3 className="text-dark font14 mb-3">Available Denominations : <span className="text-primary">500</span></h3>
                      <h3 className="text-dark font14">Discount : <span className="text-primary">50%</span></h3>
                    </Col>

                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BrandDetails;
