// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tabs-destails {
  border: none;
}

.custom-tabs-destails .nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-tabs-destails .nav-link {
  width: 100%;
  border-radius: 0;
  margin: 2px 0px;
}

.nav-tabs .nav-link {
  border-color: #e9ecef #e9ecef #dee2e6;
  color: black;
}

.tab-content {
  overflow-y: auto;
  height: 100px;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #4C00EF #EEEEEE;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.tab-content::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}
.tab-content::-webkit-scrollbar-track {
  background: rgba(180, 178, 178, 0.4509803922);
  border-radius: 10px;
}
.tab-content::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}
.tab-content::-webkit-scrollbar-thumb:hover {
  background: #808080;
  border-radius: 10px;
}

.nav-tabs .nav-link:hover {
  box-shadow: 1px 1px 8px rgba(17, 17, 17, 0.5) !important;
  transition: box-shadow 0.3s ease;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/elements/viewcardTab.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;;AAEI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACR;;AACI;EACI,WAAA;EACA,gBAAA;EACA,eAAA;AAER;;AAAI;EACI,qCAAA;EACA,YAAA;AAGR;;AADA;EACQ,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,qBAAA;EACA,gCAAA;EAOA,UAAA;EAMA,WAAA;EAMA,oBAAA;AAZR;AALQ;EACI,UAAA;EACA,mBAAA;AAOZ;AAHQ;EACI,6CAAA;EACA,mBAAA;AAKZ;AADQ;EACI,mBAAA;EACA,mBAAA;AAGZ;AACQ;EACI,mBAAA;EACA,mBAAA;AACZ;;AAEA;EACI,wDAAA;EACA,gCAAA;EACA,gBAAA;AACJ","sourcesContent":["\n    .custom-tabs-destails{\n        border: none;\n    }\n    .custom-tabs-destails .nav-item{    \n        display: flex;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n    }\n    .custom-tabs-destails .nav-link{\n        width: 100%;\n        border-radius: 0;\n        margin: 2px 0px;\n    }\n    .nav-tabs .nav-link{\n        border-color: #e9ecef #e9ecef #dee2e6;\n        color: black;\n    }\n.tab-content{\n        overflow-y: auto;\n        height: 100px;\n        overflow-x: hidden;\n        scrollbar-width: thin;\n        scrollbar-color: #4C00EF #EEEEEE;\n\n        &::-webkit-scrollbar {\n            width: 5px;\n            border-radius: 10px;\n        }\n\n        /* Track */\n        &::-webkit-scrollbar-track {\n            background: #b4b2b273;\n            border-radius: 10px;\n        }\n\n        /* Handle */\n        &::-webkit-scrollbar-thumb {\n            background: #808080;\n            border-radius: 10px;\n        }\n\n        /* Handle on hover */\n        &::-webkit-scrollbar-thumb:hover {\n            background: #808080;\n            border-radius: 10px;\n        }\n}\n.nav-tabs .nav-link:hover{\n    box-shadow: 1px 1px 8px rgba(17, 17, 17, 0.5) !important;\n    transition: box-shadow 0.3s ease;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
