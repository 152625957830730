import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
const CustomerSendPasswordResetEmail = () => {
  const nameDomain = (window.location.host.split('.'));
  useEffect(() => {
    document.title = "ncentiva || Verify"
  }, [])

  // const navigate = useNavigate();

  const [email, setEmail] = useState("");
  // const [disable, setDisable] = useState(false);
  const [cmsData, setCmsData] = useState(false)

  const [stillSendingOtp, setStillSendingOtp] = useState(false)
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const [getData, setGetData] = useState(false);
  const [partners, setPartners] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdwon, setDropdown] = useState("");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // useEffect(() => {


  // }, []);
  const handleClick = event => {
    // setTimeout(() => setStillSendingOtp(true), 100) 
    // setTimeout(() => setStillSendingOtp(false), 100)

  };

  function keyDown(e) {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key == 32) { //space
      e.preventDefault();
    }

  }

  function checkWhitespace(event) {
    var data = event.clipboardData.getData("text/plain");
    var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

    if (isNullOrContainsWhitespace) {
      event.preventDefault();
    }

  }

  const handleBusinessPartner = (selectedOption) => {
    const dropdownvalue = selectedOption.target.value;
    if (dropdownvalue === "") {
      setDropdown("");
    } else {
      setDropdown(dropdownvalue);
    }

  }


  const handleKeyUp = async (event) => {

    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customer-partners`, {
        email: email
      });
      const { status, partners } = response.data;

      if (status === 'Success') {
        setPartners(partners);
      } else {
        console.error('Error fetching partners:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }


  };


  const handleSubmit = async (e) => {
    setTimeout(() => setStillSendingOtp(true), 100)
    e.preventDefault();
    try {
      if (partners.length > 1) {
        setTimeout(() => setStillSendingOtp(false), 2000)
        if (dropdwon.trim() === "") {
          toast.warning("Please Select Business Partner");
          return;
        }
      }

      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/send-reset-password-email`, {
        email,
        partner_id: dropdwon

      });
      if (data.message === "Email doesn't exists") {
        toast.warning(data.message);
      } else if (data.message === "Email Field is Required") {
        toast.warning(data.message);
      } else {
        toast.success(data.message);
      }

      if (data.status === "success") {
        setStillSendingOtp(false)

      }
      else if (data.status === "failed") {
        setTimeout(() => setStillSendingOtp(false), 1500)

      }

    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err.response.data);
      stillSendingOtp(true);
    }
  }



  useEffect(() => {
    if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
        .then((res) => {
          setGetData(true);
          if (res.data?.cms?.default_ncentiva === false) {
            setCmsData(false)
            setBtnColor(res.data?.cms?.btn_color);
            setBtnText(res.data?.cms?.btn_text);
            setImage(res.data?.cms?.brand_logo)
          } else {
            setCmsData(true)

            setImage("https://dev.ncentiva.com/assets/images/logo.png");
            setBtnColor('#3D05B6');
            setBtnText('white');
          }
        }).catch((err) => {
          console.log(err);
        })
    }
  }, []);

  return (
    <>
      <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#fff8f3' }}>
        <Container
          className="bg-white loginContainer p-0 rounded"
          style={{ maxWidth: "1065px" }}
        >
          <Row className="align-items-center g-0">
            <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
              <div className="">
                <h4 className="text-dark">Reset password</h4>
                <p className="font16 text-dark">

                </p>



                <Form className="form mt-5" onSubmit={handleSubmit}>

                  <Form.Group className="mb-3">

                    <Form.Control
                      className="font16"
                      type="email"
                      value={email}
                      onChange={(e) =>
                        setEmail(
                          e.target.value
                        )
                      }
                      onKeyUp={handleKeyUp}
                      placeholder="Enter Email Address"
                      onKeyPress={(e) => keyDown(e)}
                      onPaste={(e) => checkWhitespace(e)}

                    />
                  </Form.Group>
                  {partners.length > 1 ?
                    <Form.Group className="mb-3" controlId="formBasicPartner">
                      <Form.Label className="text-dark">Select Your Account</Form.Label>
                      <div
                        className="position-relative"
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', }}
                        onClick={toggleDropdown}
                      >
                        <Form.Control
                          as="select"
                          onChange={handleBusinessPartner}
                          style={{ padding: "14px 28px", cursor: 'pointer' }}

                        >
                          <option value="">Select Your Account</option>
                          {partners.map((partner, index) => (
                            <option key={index} value={partner.value}>
                              {partner.label}
                            </option>
                          ))}
                        </Form.Control>
                        <span
                          className="position-absolute"
                          style={{
                            right: '12px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 1,
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                          }}
                          onClick={(e) => {


                            toggleDropdown();
                          }}

                        >
                          {isDropdownOpen ? <MdOutlineKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                        </span>
                      </div>

                    </Form.Group> : ""}
                  <Form.Group className="mb-3">
                    <h5>Forgot Password ?</h5>
                    <p style={{ fontSize: '12px', color: 'black' }}>Enter your registered Email and we will send you a reset password link.</p>
                    <br />


                    {
                      stillSendingOtp ?
                        <Button type="submit" variant="primary" className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                          // style={{backgroundColor:`${btnColor}80`,color:btnText,border:`1px solid transparent`}} 
                          style={cmsData
                            ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                          }
                          disabled>Please wait ....</Button> :
                        <>{btnColor ?
                          <Button type="submit" variant="primary" className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary button_hover"
                            style={cmsData
                              ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                              : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                            } onClick={handleClick} >Send Mail</Button>
                          : ''}
                        </>
                    }
                    {/* <Button
        type="submit"
        variant="primary"
        className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
    >
        
    </Button> */}

                    {/* {stillSendingOtp ? 'Please wait ....' : 'Send Mail'} */}







                  </Form.Group>

                </Form>
              </div>
            </Col>
            <Col
              md="6"
              className="loginRight order-1 order-md-2 d-flex justify-content-center flex-column align-items-center"
              style={{ height: "100%" }}
            >
              <Link to="/" >
                {getData ? <img
                  src={image}
                  className="imgLogo img-fluid img_hover"
                  alt=""
                  style={{ width: '170px', height: '110px', objectFit: 'scale-down' }}
                />
                  : ""
                }
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <ToastContainer position="top-center" /> */}
    </>

  );
};

export default CustomerSendPasswordResetEmail;
