import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
const CustomerResetPassword = () => {
    const nameDomain = (window.location.host.split('.'));
    useEffect(() => {
        document.title = "ncentiva || Verify"
    }, [])


    const navigate = useNavigate();
    const { id, token } = useParams();
    const [password, setPassword] = useState("");
    const [password_confirmation, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState(false);
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState("");
    const [cmsData, setCmsData] = useState(false)

    useEffect(() => {


    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password && !password_confirmation) {
            toast.warning("All Fields Are Required")
        }
        else if (!password) {
            toast.warning("Please Enter Your Password")
        }
        else if (!password_confirmation) {
            toast.warning("Please Enter Your Confirm Password")
        } else {
            if (password !== password_confirmation) {
                toast.warning("Password and Confirm Password Doesn't Match");
            } else {
                if (password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
                    toast.warning(<div>
                        <p><b>Password must be</b></p>
                        <br />
                        <ul>
                            <li>
                                Use at least 8 characters
                            </li>
                            <li>
                                Use upper and lower case characters
                            </li>
                            <li>
                                Use 1 or more numbers
                            </li>
                            <li>
                                Use 1 or more special character
                            </li>
                        </ul>
                    </div>);
                    // toast("password must be 8 characters including 1 uppercase letter, 1 special character and alphanumeric characters ");
                } else {
                    try {

                        const credentials = {
                            password: password,
                            password_confirmation: password_confirmation,
                        };
                        const jsonString = JSON.stringify(credentials);
                        const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/reset-password/${id}/${token}`, {
                            securedLogin
                        });
                        toast.success(data.message);
                        if (data.status === "success") {
                            window.localStorage.setItem("customer_data", JSON.stringify(data));
                            navigate('/customer/verify');
                        }
                    } catch (err) {
                        console.log(`Error : ${err}`);
                        toast.error(err.response.data);

                    }
                }
            }

        }


    }

    useEffect(() => {
        setLoading(true);
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setImage(res.data?.cms?.brand_logo)
                    } else {
                        setCmsData(true)
                        setImage("https://dev.ncentiva.com/assets/images/logo.png")
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setLoading(false);
        }
    }, []);

    function checkWhitespace(event) {
        var data = event.clipboardData.getData("text/plain");
        var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

        if (isNullOrContainsWhitespace) {
            event.preventDefault();
        }

    }


    return (
        <>
            {loading ? '' :
                <>
                    <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#fff8f3' }}>
                        <Container
                            className="bg-white loginContainer p-0 rounded"
                            style={{ maxWidth: "1065px" }}
                        >
                            <Row className="align-items-center g-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img
                                        src="/assets/images/logo.png"
                                        className="imgLogo img-fluid mt-4"
                                        alt=""
                                        style={{
                                            width: '200px',
                                            height: '100px',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>
                                <Col style={{ height: "100%", borderRight: "1px solid #ccc" }} md={6} className="loginLeft j_login_left">
                                    <div className="">
                                        <h4 className="text-dark">Change password</h4>
                                        <p className="font16 text-dark">

                                        </p>



                                        <Form className="form mt-5" onSubmit={handleSubmit}>

                                            <Form.Group className="mb-3">
                                                <Form.Label className=" text-dark">Password</Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control
                                                        type={showPassword ? 'text' : 'password'}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onPaste={(e) => checkWhitespace(e)}
                                                        placeholder="Enter Password"
                                                        value={password}

                                                    />
                                                    {password.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowPassword(!showPassword) }} >
                                                        {!showPassword ? <FaEyeSlash /> : <FaEye />}
                                                    </button>}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label className=" text-dark">Confirm Password</Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        // value={password}
                                                        onChange={(e) => {
                                                            let trimValue = e.target.value;
                                                            trimValue = trimValue.replace(/\s/g, '')
                                                            setConfirmPassword(trimValue)
                                                        }}
                                                        onPaste={(e) => checkWhitespace(e)}
                                                        placeholder="Enter Confirm Password"
                                                        value={password_confirmation}
                                                    />
                                                    {password_confirmation.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} >
                                                        {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                    </button>}
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                {error && btnColor ? <Button
                                                    variant="primary"
                                                    className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                    // style={{backgroundColor:`${btnColor}80`,color:btnText,border:`1px solid transparent`}}
                                                    style={cmsData
                                                        ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                        : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                    }
                                                >
                                                    Submit
                                                </Button> :
                                                    <>{
                                                        btnColor ?
                                                            <Button
                                                                type="submit"
                                                                variant="primary"
                                                                // style={{backgroundColor:`${btnColor}80`,color:btnText,border:`1px solid transparent`}}
                                                                style={cmsData
                                                                    ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                                }
                                                                className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                            >
                                                                Submit
                                                            </Button>
                                                            : ''}
                                                    </>
                                                }

                                            </Form.Group>
                                        </Form>

                                    </div>
                                </Col>
                                {/* <Col
                        md="6"
                        className="loginRight order-1 order-md-2 d-flex justify-content-center flex-column align-items-center"
                        style={{ height: "100%" }}
                    > 
                     <Link to="/" >
                        <img
                            src={image}
                            className="imgLogo img-fluid"
                            alt=""
                            style={{width: '170px',height: '110px',objectFit:'scale-down'}}
                            
                        />
                        </Link>
                    </Col> */}
                                <Col
                                    md="6"
                                    className="loginRight j_login_right  align-items-center"
                                    style={{ height: "100%" }}
                                >
                                    <div>
                                        <p className="verification-text text-dark">
                                            <span className="verification-title" style={{ fontWeight: '500' }}>One time password verification.</span> <br /><br />
                                            We will send an OTP code to your email.<br />
                                            Please use it before it expires within 5 minutes.<br />


                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    {/* <ToastContainer position="top-center" /> */}
                </>}
        </>

    );
};

export default CustomerResetPassword;
