
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,

} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";


const CustomerTransctionDetail = () => {
  const user = JSON.parse(window.localStorage.getItem("customer"));
  const defaultNcentiva = localStorage.getItem("defaultNcentivaCms");

  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {

    usersid = user.customer._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }


  const { id } = useParams();

  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [sort, setSort] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fund, setFund] = useState("");
  const [isGiftcardFailed, setIsGiftcardFailed] = useState(false);
  const [realTimeGiftcards, setRealTimeGiftcards] = useState([]);
  const [nonRealTimeGiftcards, setNonRealTimeGiftcards] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const [failedData, setFailedData] = useState([]);
  const [isFailed, setIsFailed] = useState(false);
  const [giftcardsLength, setGiftcardslength] = useState(false);
  const [status, setStatus] = useState([]);
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const nameDomain = (window.location.host.split('.'));
  const [image, setImage] = useState("");
  const [businessTypeOption, setBusinessTypeOption] = useState("");

  let token;

  const refundFunc = (Id) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/refund-gcip-order/${Id}`)
      .then((res) => {
        toast.success(res.data.message)
        getAllGiftcard()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllGiftcard = () => {
    const user = JSON.parse(window.localStorage.getItem("customer"));
    if (user) {
      token = user.token;

    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/gettransactiondetails/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem('customer');
          navigate('/login');
          toast.error("Session Expired Please Login Again");
        }
        const transaction = response.data.transaction;
        const detailStatus = response.data.transaction.status;
        const totalPages = response.data.totalPages;
        let length;
        length = response.data.transaction.card_length;
        length = parseInt(length);
        setStatus(detailStatus);
        setCustomerData(transaction);
        setNumberOfPages(totalPages);
        setBusinessTypeOption(response.data.transaction.business_type_option);
        setRealTimeGiftcards(response.data.realgiftcards);
        setNonRealTimeGiftcards(response.data.nonrealgiftcards);
        let balanceFormat;
        balanceFormat = response.data.transaction.fund;
        balanceFormat = Number(balanceFormat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setFund(balanceFormat);
        if (response.data.transaction.giftcards.length == length) {
          setGiftcardslength(true);
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem('customer');
          navigate('/login');
          toast.warning("Session Expired Please Login Again")
          window.location.reload();
        }
      })
  }

  useEffect(() => {
    getAllGiftcard(id)
  }, [id])


  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }


  const retryFunc = (Id) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setIsFailed(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/retry-gcip-order/${Id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message)
        }
        setIsFailed(false);
        getAllGiftcard(pageNumber);
      }).catch((err) => {
        console.log(err);
      })
  }

  function handleButtonClick(cust) {
    if (cust && cust.ncentivaUrl) {
      const ncentivaUrl = cust.ncentivaUrl.replace(/"/g, '');
      window.open(ncentivaUrl, '_blank');

    }
  }

  const [primaryColor, setPrimaryColor] = useState('');
  useEffect(() => {
    if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
        .then((res) => {
          if (res.data?.cms?.default_ncentiva === false) {
            setBtnColor(res.data?.cms?.btn_color);
            setBtnText(res.data?.cms?.btn_text);
            setPrimaryColor(res.data?.cms?.primary_color);
            if (res.data?.cms?.brand_logo) {
              setImage(res.data?.cms?.brand_logo)
            }
          } else {
            setImage("https://dev.ncentiva.com/assets/images/logo.png");
            setBtnColor('#3D05B6');
            setBtnText('white');
            setPrimaryColor("#FF7020");
          }
          setIsLoading(false);
        }).catch((err) => {
          console.log(err);
        })
    } else {
      setIsLoading(false);
    }
  }, []);



  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <div className="d-flex mb-4 align-items-center ">
        <Link
          onClick={() => navigate(-1)}
          // to="/admin/transaction"
          className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
          variant="white"
        >
          <span className="me-2 fw-semibold">
            <img
              src="/assets/images/back-org.png"
              className="img-fluid"
              alt=""
            />
          </span>
          <span>Back</span>
        </Link>
        <h1 className="font20 text-dark">Transaction Details</h1>
      </div>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={7} xxl={8} lg={7} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Recipient : {customerData?.customer_first_name} {customerData?.customer_last_name}</h3>
                    }
                  </div>
                </div>
              </Col>
              <Col xl={5} xxl={4} lg={5} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Transaction Id : {customerData?.transaction_id}</h3>
                    }
                  </div>
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Total : ${fund}</h3>
                    }
                  </div>
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Business Partner : {customerData?.customer_business_partner}</h3>
                    }
                  </div>
                  {apiResponse ?

                    failedData?.length > 0 && giftcardsLength ?
                      <div>
                        <Button
                          variant="primary"
                          style={{ marginTop: "10px" }}
                          type="button"
                          className="fullRounded px-4 py-2 fw-bold font12"
                          onClick={(e) => refundFunc(id)}
                        >
                          <span className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                            </svg>
                          </span>
                          <span>Complete Transaction</span>
                        </Button>
                      </div>
                      : ""

                    : ""}


                </div>
              </Col>

            </Row>
          </Container>
          <Col md={12} className="px-0">
            <div className="px-3">
              {realTimeGiftcards.length > 0 ?
                <>
                  <Row className="justify-content-between g-3">
                    <Col lg='auto' xl='auto' md="auto" >
                      <h3 className="font16 pt-3 text-dark">Realtime Giftcards</h3>

                    </Col>
                    <Col lg='auto' xl='auto' md="auto" className="">

                    </Col>
                  </Row>
                  <div className="table-responsive table_scroll_customer d-none d-md-block">
                    <table
                      className="table couponTable table-borderless fontGilroy"
                      style={{ verticalAlign: "middle", marginTop: "10px", minWidth: '1000px' }}
                    >
                      <thead className="bgLightRed font14">
                        <tr className="text-center">
                          <th className="text-start" width="110px">
                            Brand
                          </th>
                          <th width="250px">Product Name</th>
                          <th width="210px">Order Id</th>
                          <th>Cost</th>
                          <th>Bonus / Discount</th>
                          <th>Card Value</th>
                          <th>Gift Cards Url</th>
                          <th>Status</th>

                        </tr>
                      </thead>
                      <tbody className="font14 fw-medium text-center">

                        {isLoading || isFailed === true ? (
                          <tr style={{ backgroundColor: "#eee" }}>
                            <td colSpan="8">
                              <Bars
                                height="80"
                                width="100%"
                                margin='auto'
                                color={primaryColor}
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            </td>
                          </tr>
                        ) : (
                          realTimeGiftcards?.map((cust) => (

                            <tr
                              key={cust._id}
                            >
                              <td className="font14 text-start">
                                <img
                                  src={cust.img_path}
                                  alt="img"
                                  width="100px"
                                />
                              </td>
                              <td className="font14 fw-medium">

                                {cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}

                              </td>
                              <td className="py-3 font14 fw-medium ">
                                {cust.purchaseOrderName}
                              </td>
                              <td>
                                {businessTypeOption === "Bonus" ?
                                  <>
                                    ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                  :
                                  <>
                                    ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                }
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                <span className="me-1">
                                  {businessTypeOption === "Bonus" ? '+' : '-'}
                                </span>
                                ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                {businessTypeOption === "Bonus" ?
                                  <>
                                    ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                  :
                                  <>
                                    ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                }
                              </td>
                              <td className="py-3 font14 fw-medium" style={{ width: '150px' }}>

                                <Button
                                  type="button"
                                  variant={cust.status === 'Success' && status === 'Success' || cust.status === 'Success' && status === 'Refund' ? 'primary' : 'secondary'}
                                  className="px-4 py-2 fullRounded  font12 button_hover"
                                  // style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`}} 
                                  style={defaultNcentiva === "true"
                                    ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                  onClick={() => handleButtonClick(cust)}
                                  disabled={cust.status !== 'Success' && status !== 'Success' || cust.status !== 'Success' && status !== 'Refund' || cust.status === 'Success' && status === 'Pending'}
                                >
                                  <span>Get Code</span>

                                </Button>
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                <span>{cust.status}</span>
                              </td>




                            </tr>
                          ))
                        )}

                      </tbody>
                    </table>
                  </div>

                  {isLoading || isFailed === true ? (
                    <div style={{ backgroundColor: "#eee" }}>
                      <div colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin='auto'
                          color={primaryColor}
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </div>
                  ) :
                    (realTimeGiftcards?.map((cust) =>
                    (
                      <div className="container-fluid p-0">
                        <div className="border card_container_transaction position-relative d-block d-md-none">
                          <div className="position-absolute custom_status_tag"
                            style={{
                              backgroundColor: cust.status.trim() === "Success" ? "#20A76E" :
                                cust.status.trim() === "Pending" ? "#F5C400" :
                                  cust.status.trim() === "inOrder" ? "#3d05b6" :
                                    "Red"
                            }}>
                            {cust.status}
                          </div>
                          <div className="d-flex flex-row mb-2">
                            <div className="col-5 p-0">
                              <img src={cust.img_path} alt="" style={{ height: '50px', width: "80px" }} />
                            </div>
                            <div className="col-7 ">
                              <p className="m-0" style={{ fontWeight: "bold" }}>Product Name:</p>
                              <p className="m-0">{cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}
                              </p>
                            </div>
                          </div>
                          <div className="row d-flex mb-2">
                            <div className="col-3">
                              <p className="mb-1"><strong>Cost:</strong></p>
                              <p>
                                {businessTypeOption === "Bonus" ?
                                  <>
                                    ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                  :
                                  <>
                                    ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                }
                              </p>
                            </div>
                            <div className="col-3">
                              <p className="mb-1"><strong>Bonus:</strong></p>
                              <p>
                                <span className="me-1">
                                  {businessTypeOption === "Bonus" ? '+' : '-'}
                                </span>
                                ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </p>
                            </div>
                            <div className="col-6">
                              <p className="mb-1"><strong>Card Value:</strong></p>
                              <p>
                                {businessTypeOption === "Bonus" ?
                                  <>
                                    ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                  :
                                  <>
                                    ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  </>
                                }
                              </p>
                            </div>
                          </div>
                          <div className="row d-flex flex-column px-3 mb-2">
                            <h6 className="p-0 mb-2">Gift Card URL:</h6>
                            <Button
                              variant={cust.status === 'Success' && status === 'Success' || cust.status === 'Success' && status === 'Refund' ? 'primary' : 'secondary'}
                              className="btn-dark py-2 button_hover"
                              // style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`, borderRadius:"20px"}} 
                              style={defaultNcentiva === "true"
                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                              onClick={() => handleButtonClick(cust)}
                              disabled={cust.status !== 'Success' && status !== 'Success' || cust.status !== 'Success' && status !== 'Refund' || cust.status === 'Success' && status === 'Pending'}
                            >Get Code</Button>
                          </div>
                        </div>
                      </div>
                    )))}
                </> : ""}


              {nonRealTimeGiftcards.length > 0 && isFailed === false ? <>

                <Row className="justify-content-between g-3 mt-2">
                  <Col lg="auto" xl="auto" md="auto" >
                    <h3 className="font16 pt-3 text-dark">Non Realtime Giftcards</h3>
                  </Col>
                  <Col lg="auto" xl="auto" md="auto" className="">
                  </Col>
                </Row>
                <div className="table-responsive  d-none d-md-block">
                  <table
                    className="table couponTable table-borderless fontGilroy"
                    style={{ verticalAlign: "middle", marginTop: "15px", minWidth: '1000px' }}
                  >
                    <thead className="bgLightRed font14">
                      <tr className="text-center">
                        <th className="text-start" width="110px">
                          Brand
                        </th>
                        <th width="250px">Product Name</th>
                        <th width="210px">Order Id</th>
                        <th>Cost</th>
                        <th>Bonus / Discount</th>
                        <th>Card Value</th>
                        <th>Gift Cards Url</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="font14 fw-medium text-center">

                      {isLoading === true ? (
                        <tr style={{ backgroundColor: "#eee" }}>
                          <td colSpan="8">
                            <Bars
                              height="80"
                              width="100%"
                              margin='auto'
                              color="#FF7020"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </td>
                        </tr>
                      ) : (
                        nonRealTimeGiftcards?.map((cust) => (

                          <tr
                            key={cust._id}
                          >
                            <td className="font14 text-start">
                              <img
                                src={cust.img_path}
                                alt="img"
                                width="100px"
                              />
                            </td>
                            <td className="font14 fw-medium">

                              {cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}

                            </td>
                            <td className="py-3 font14 fw-medium ">
                              {cust.purchaseOrderName}
                            </td>
                            <td>
                              {businessTypeOption === "Bonus" ?
                                <>
                                  ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                                :
                                <>
                                  ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                              }
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              <span className="me-1">
                                {businessTypeOption === "Bonus" ? '+' : '-'}
                              </span>
                              ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              {businessTypeOption === "Bonus" ?
                                <>
                                  ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                                :
                                <>
                                  ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                              }
                            </td>
                            <td className="py-3 font14 fw-medium" style={{ width: '150px' }}>
                              <Button
                                type="button"
                                variant={status === 'Success' ? 'primary' : 'secondary'}
                                className="px-4 py-2 fullRounded  font12 button_hover"
                                onClick={() => handleButtonClick(cust)}
                                disabled={status !== 'Success'}
                                style={defaultNcentiva === "true"
                                  ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                  : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                              >
                                <span>Get Code</span>

                              </Button>
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              {status}
                            </td>
                          </tr>
                        ))
                      )}

                    </tbody>
                  </table>
                </div>
                {
                  isLoading === true ? (
                    <div style={{ backgroundColor: "#eee" }}>
                      <div colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin='auto'
                          color="#FF7020"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </div>
                  ) :
                    (nonRealTimeGiftcards?.map((cust) =>
                    (
                      <div className="border card_container_transaction position-relative mx-auto  d-block d-md-none">
                        <div className="position-absolute custom_status_tag"
                          style={{
                            backgroundColor: status.trim() === "Success" ? "#20A76E" :
                              status.trim() === "Pending" ? "#F5C400" :
                                status.trim() === "inOrder" ? "#3d05b6" :
                                  "Red"
                          }}>{status}</div>
                        <div className="d-flex flex-row mb-2">
                          <div className="col-5 p-0">
                            <img src={cust.img_path} alt="" style={{ height: '50px', width: "80px" }} />
                          </div>
                          <div className="col-7 col-sm-4">
                            <p className="m-0" style={{ fontWeight: "bold" }}>Product Name:</p>
                            <p className="m-0">{cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}</p>
                          </div>
                        </div>
                        <div className="row d-flex mb-2">
                          <div className="col-3">
                            <p className="mb-1"><strong>Cost:</strong></p>
                            <p>
                              {businessTypeOption === "Bonus" ?
                                <>
                                  ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                                :
                                <>
                                  ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                              }
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="mb-1"><strong>Bonus:</strong></p>
                            <p>
                              <span className="me-1">
                                {businessTypeOption === "Bonus" ? '+' : '-'}
                              </span>
                              ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="mb-1"><strong>Card Value:</strong></p>
                            <p>
                              {businessTypeOption === "Bonus" ?
                                <>
                                  ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                                :
                                <>
                                  ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </>
                              }
                            </p>
                          </div>
                        </div>
                        <div className="row d-flex mb-2 flex-column px-3" >
                          <h6 className="p-0 mb-2">Gift Card URL:</h6>
                          <Button
                            variant={status === 'Success' ? 'primary' : 'secondary'}
                            onClick={() => handleButtonClick(cust)}
                            disabled={status !== 'Success'}
                            style={defaultNcentiva === "true"
                              ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                              : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                            className="btn-dark py-2 button_hover"
                          >Get Code</Button>
                        </div>
                      </div>
                    )))
                }
              </>
                : ""}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CustomerTransctionDetail;