import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProductItem from '../components/elements/ProductItem';
import { useDispatch, useSelector } from 'react-redux';
import "./PromotionalSlider.scss";
import { SlArrowRight,SlArrowLeft } from "react-icons/sl";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ViewCard from '../components/elements/ViewCard';

const PromotionalSlider = (props) => {
  const nameDomain = (window.location.host.split('.'));
  const [couponData, setCouponData] = useState([]);
  const [noGift, setNoGift] = useState(true);
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const [giveClass, setGiveClass] = useState(false);
  const [btnColor, setBtnColor] = useState();
  const [btnText, setBtnText] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const rbalance_redux = useSelector((state) => state.customer_balance_Reducer.data);
var balance = 0;
const [rFund, setrFund] = useState(balance);
const [clickedItemId, setClickedItemId] = useState(null);

const [selectedPromotion, setSelectedPromotion] = useState(null);

  const checkCms = nameDomain[0].split('-');
  const navigate = useNavigate();
    const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  var partner_id;
  if (customer) {
    // var cust_id = customer.customer._id;
    partner_id = customer.customer.business_partner_id;
    // name = customer.customer.first_name +" "+ customer.customer.last_name;
  } else{
   //partner_id = "NCT-B-878611"
  }
  const dataArray = useMemo(() => {
    let array = [];
    if (props?.promoData?.length < 7) {
      for (let i = 0; i < 8; i++) {
        array.push(props?.promoData[i % props?.promoData?.length]);
      }
    } else {
      array.push(...props?.promoData);
    }
    return array;
  }, [props.promoData]);

  const handleItemClick = useCallback((promotional) => {
    console.log('Item clicked:', promotional._id);
    setClickedItemId(promotional._id);
    setSelectedPromotion(promotional);
    props.setModalShowDC(true);
  }, [props]);
  const allDefined =  dataArray.some((element)=> typeof element === 'undefined')

  const sliderRef = useRef(null);

  
  let token;
  useEffect(() => {
    document.title = "ncentiva || Products";
    // getCouponsData();
    setBtnColor(props?.btnColor);
    setBtnText(props?.btnText);
  }, [])

  // const getCouponsData = () => {
  //   let check_name = "cms_url"
  //   if (nameDomain[0] === "dev" || nameDomain[0] === "staging" || nameDomain[0] === "payouts" || nameDomain[0] === "uat" || nameDomain[0] === "localhost:3000") {
  //     nameDomain[0] = "";
  //   }
  //   if (checkCms[1] === "cms") {
  //     nameDomain[0] = checkCms[0];
  //     check_name = "partner_url"
  //   }
  //   if (customer) {
  //     token = customer.token;
  //   } else {
  //     token = null;
  //   }
  //   // setIsLoading(true);
  //   axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/promotedbrands?${check_name}=${nameDomain[0]}&partner_id=${partner_id}`, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //     .then((response) => {
  //       if (response.data.message === "Please Login") {
  //         localStorage.removeItem('customer');
  //         localStorage.removeItem('listItem');
  //         navigate('/');
  //         toast.warning("Session Expired Please Login Again")
  //         // window.location.reload();
  //       }
  //       const allcoupons = response.data.coupons;
  //       //setIsLoading(false);
  //       setGiveClass(false);
  //       if (allcoupons.length < 1) {
  //         setNoGift(false);
  //       } else {
  //         if (allcoupons.length < 3) {
  //           setGiveClass(true);
  //         }
  //         setCouponData(allcoupons);
  //         setNoGift(true);
  //       }
  //     })
  //     .catch(error => console.error(`Error: ${error}`));
  // }
  const cartData = useSelector((state) => state.cartReducer);
  const contactList = cartData?.data?.cart ?? [];
  let wUsedbalance = Number(0);
  for (let i = 0; i < contactList?.length; i++) {
    if (contactList[i].wallet == true) {
      wUsedbalance += parseFloat(contactList[i].cost);
    }
  }
  wUsedbalance = parseFloat(props?.wallet_balance) - parseFloat(wUsedbalance);

  wUsedbalance.toFixed(2);
   const CustomNextArrow = (props) => (
    <button {...props} className="carousel-button next-button">
      <SlArrowRight className='customArrow_customer'/>
    </button>
 );

 const CustomPrevArrow = (props) => (
    <button {...props} className="carousel-button prev-button">
     <SlArrowLeft className='customArrow_customer'/>
    </button>
 );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 492,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
    accessibility: false, // This disables keyboard navigation
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <div className='slider-container' onKeyDown={handleKeyDown}>
    <Slider {...settings} ref={sliderRef} className="py-2" >
    {(allDefined === true ? '' : rbalance_redux && dataArray?.map((promotional) => (
        <div key={promotional?._id} className="carousel_slide hover-effect">
          <Link className="brand-block responsive_class_width_banner hover-effect_sp" style={{ border: "none" }}>
            <div className="brand-image position-relative" onClick={() => handleItemClick(promotional)}>
              <img src={promotional?.img_path} alt={promotional?.brand_name} className="img-fluid" style={{ width: '100%', height: '100%', borderRadius: "20px", objectFit: 'cover' }} />
              <div className="p-1 position-absolute text-center custom_discount_promoBrand">
                <span>+
                  {(!promotional?.discount || !promotional?.bonusFee || !promotional?.bonusType) ? '0.00 %' :
                     (promotional?.bonusType === 'Bonus percentage' || promotional?.bonusType === 'Percentage') ?
                      `${(promotional?.discount * (promotional?.bonusFee / 100)).toFixed(2)} %` :
                      `${(Number(promotional?.discount) - Number(promotional?.bonusFee)).toFixed(2)} %`}
                </span>
              </div>
            </div>
          </Link>
        </div>
      )))}
    </Slider>
    {selectedPromotion && props.modalShowDC && (
      <ViewCard 
      onChange={props.modalOnchange}
      payment={props.paymentOption}
      rfund={rFund}
      // countValue={count}
      handleConfirm={props.onConfirm}
      walletBalace={wUsedbalance.toFixed(2)}
      // brand_id={data.brand_id}
      primaryColor={props.primaryColor}
      secondaryColor={props.secondaryColor}
      btnColor={props.btnColor}
      btnText={props.btnText}
      // isfixedValue={isFixed}
      coupon={selectedPromotion._id}
      data={selectedPromotion}
      dataType={"promotion"}
      rbalance_redux={rbalance_redux.balance}
      disableBtn={props.disableBtn}
      setDisableBtn={props.setDisableBtn}
      wallet_balance={rbalance_redux.wallet_balance}
      setModalShowDC={props.setModalShowDC}
      modalShowDC={props.modalShowDC}
      ws={props.ws}
      carousel={"carousel"}
    />
    )}
  </div>





      // </Swiper>
    // </div>
  )
}

export default PromotionalSlider