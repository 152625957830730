import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import logo from "../assets/images/logo.png";
import "jspdf-autotable";
import "../styles/transaction.css"
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "../App.scss"
import Select from "react-select";
const Transaction = () => {
  useEffect(() => {
    const adminCheck = window.location.pathname;
    if (adminCheck.includes('admin')) {
      document.title = "ncentiva || Admin Transaction"
    }
  }, [])
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "var(--darkblue)" : "var(--color)",
      fontSize: 12,
      padding: 6,
      marginBottom: 0,
      backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
    }),

    control: () => ({
      width: "250px",
      display: "flex",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "var(--color)";
      const fontSize = "14px";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color, fontSize };
    },
  };

  const statusCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "var(--darkblue)" : "var(--color)",
      fontSize: 16,
      padding: 6,
      marginBottom: 0,
      backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
    }),

    control: () => ({
      width: "250px",
      display: "flex",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "var(--color)";
      const fontSize = "14px";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color, fontSize };
    },
  };

  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [role] = useState(userrole);
  const [userid] = useState(usersid);
  const [customerData, setCustomerData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [customerMaxValue, setCustomerMaxValue] = useState([]);
  const [customerUpdateAtMaxValue, setCustomerUpdateAtMaxValue] = useState([]);
  const [sendMail, setSendMail] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [businessName, setBusinessName] = useState("ASC");
  const [valueorder, setValueorder] = useState("ASC");
  const [noshow, setNoShow] = useState(false);
  const [updateorder, setUpdateorder] = useState("ASC");
  const [giftcardorder, setGiftcardorder] = useState("ASC");
  const [showPage, setShowPage] = useState(1);

  const [sort, setSort] = useState("");
  const [allproducts, setAllProducts] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [show, setShow] = useState(false);
  const [custFilter, setCustFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statusOption, setStatusOption] = useState("");
  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };


  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");

    } else {
      setSort("ascName");

      setOrder("ASC");

    }
  };

  const businessNamesorting = (businessOrder) => {
    if (businessOrder === "DSC") {
      setSort("dscBusiness");

      setBusinessName("DSC");
    } else {
      setSort("ascBusiness");

      setBusinessName("ASC");
    }
  };

  const valuesorting = (valueorder) => {
    if (valueorder === "DSC") {
      setSort("dscValue");

      setValueorder("DSC");
    } else {
      setSort("ascValue");

      setValueorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };

  const giftcardsorting = (giftcardorder) => {
    if (giftcardorder === "DSC") {
      setSort("dscGiftcard");

      setGiftcardorder("DSC");
    } else {
      setSort("ascGiftcard");

      setGiftcardorder("ASC");
    }
  };

  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    pagenumberSearch(1);                                    
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getTransactionData(pageNumber, startDate, endDate, selectedRowsPerPage, custFilter, statusFilter);
  };


  const getTransactionData = (pageNumber, start, end, pageSize, custFilter, statusFilter, click) => {
    if (click !== undefined && click) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
    const emailQueryParam = encodeURIComponent(custFilter);
    if (pageSize === undefined) {
      pageSize = 10
    }
    // setIsLoading(true);
    setNoShow(false);
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getalltransaction?page=${pageNumber}&search=${search}&sort=${sort}&startDate=${start}&endDate=${end}&pageSize=${pageSize}&filter=${emailQueryParam}&statusFilter=${statusFilter}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.data.message == "Please Login") {
          localStorage.removeItem('client');
          navigate('/admin');
          toast.error("Session Expired Please Login Again")
        }
        setStatusOption(response.data.uniqueStatuses);
        const alltransaction = response.data.transaction;
        if (alltransaction.length < 1) {
          setNoShow(true);
        } else {
          setNoShow(false);
        }
        const totalPages = response.data.totalPages;
        // const maxValue = response.data.maxValue;
        const updateMaxValue = response.data.maxValue.createdAt.slice(0, 16).replace("T", "      ");
        setCustomerData(alltransaction);
        setAllProducts(alltransaction);
        setNumberOfPages(totalPages);
        // setCustomerMaxValue(maxValue)
        setCustomerUpdateAtMaxValue(updateMaxValue)
        setIsLoading(false);
      })
      .catch((error) =>{
        console.log(error)
        if (error?.response?.data?.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
      })
  };

  const [filterData, setFilterData] = useState([])

  const getCustomerFilter = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/transactionfilter`)
      .then((response) => {
        setFilterData(response.data.data)
      }).catch((err) => {
        console.log(err);
      })
  }


  const handleChange = (selectedOption) => {
    setPageNumber(0);
    setShowPage(1);
    const parts = selectedOption.label.split('-').map(part => part.trim());

    const email = parts[1];
    if (selectedOption.value === "All Customers") {
      setCustFilter("");
      getTransactionData(pageNumber, startDate, endDate, rowsPerPage, statusFilter)
      setIsLoading(false);
    } else {
      const promise = new Promise((resolve, reject) => {
        setCustFilter(email);
        resolve();
      });
      promise.then(() => {
        getTransactionData(pageNumber, startDate, endDate, rowsPerPage, email, statusFilter)
        getCustomerFilter()
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };

  const handleStatusChange = (selectedOption) => {
    setPageNumber(0);
    setShowPage(1);
    setStatusFilter(selectedOption.value);
    if (selectedOption.value === "All Status") {
      setStatusFilter("");
      getTransactionData(pageNumber, startDate, endDate, rowsPerPage, custFilter )
    } else {
      setStatusFilter(selectedOption.value);
      getTransactionData(pageNumber, startDate, endDate, rowsPerPage, custFilter, selectedOption.value)
    }
  };
  const modifiedOptions = filterData.map((customer) => ({
    value: customer.value,
    label: customer.email ? `${customer.label} - ${customer.email}` : customer.label,
  }));

  const statusArray = Array.from(statusOption);
  const statusInOptions = statusArray.map((status) => ({
    value: status,
    label: status
  }));
  

  //for Retry
  const retryFunc = (status) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/retry-gcip-order/${status}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.success(response.data.message);
      }).catch((err) => {
        console.log(err);
      })
  }


  //for PDF

  const pdfDownlod = (id) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/gettransactiondetails/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        const totalAmount = parseFloat(response.data.transaction.fund) + parseFloat(response.data.transaction.bonus);
        const getTransactionDataByID = response.data.transaction
        setTransactionData(response.data.transaction);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);
        var paragraphSecond = "6860 Dallas Pkwy";
        var paragraphThird = "Suite 200";
        var paragraphFourth = "Plano, TX 75024,";
        var paragraphFifth = "United States.";


        var lMargin2 = 450;
        var rMargin2 = 5;
        var pdfInMM2 = 690;

        var lMargin3 = 450;
        var rMargin3 = 5;
        var pdfInMM3 = 690;

        var lMargin4 = 450;
        var rMargin4 = 5;
        var pdfInMM4 = 690;

        var lMargin5 = 450;
        var rMargin5 = 5;
        var pdfInMM5 = 690;


        // doc.setFont("normal")
        doc.setFontSize(12)
        // doc.setFont('bold')
        // doc.addFont('bold');
        // doc.getFontList('Roboto-Regular', 'bold');
        doc.setFont('Roboto-Regular', 'bold');
        var lines2 = doc.splitTextToSize(paragraphSecond, (pdfInMM2 - lMargin2 - rMargin2));
        doc.text(lMargin2, 100, lines2);
        doc.setFontSize(12)
        var lines3 = doc.splitTextToSize(paragraphThird, (pdfInMM3 - lMargin3 - rMargin3));
        doc.text(lMargin3, 115, lines3);
        doc.setFontSize(12)
        var lines4 = doc.splitTextToSize(paragraphFourth, (pdfInMM4 - lMargin4 - rMargin4));
        doc.text(lMargin4, 130, lines4);
        doc.setFontSize(12)
        var lines5 = doc.splitTextToSize(paragraphFifth, (pdfInMM5 - lMargin5 - rMargin5));
        doc.text(lMargin5, 145, lines5);

        const logos = { logo };
        doc.addImage(logo, "png", 30, 40, 150, 30);

        doc.setFontSize(18);
        doc.text(30, 200, "RECIPIENT:");
        doc.setFontSize(12);
        doc.text(30, 220, `${response.data.transaction.customer_first_name} ${response.data.transaction.customer_last_name}`);
        doc.setFontSize(10);
        doc.text(30, 235, `${response.data.transaction.customer_email}`);

        var total = `$ ${Number(response.data.transaction.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        var datass = [
          { name: "Process", amount: `${response.data.transaction.status}` },
          { name: "Transaction Date", amount: `${response.data.transaction.createdAt.slice(0, 10)}` },
          { name: "Transaction Time", amount: `${response.data.transaction.createdAt.slice(11, 16)}` }
        ];

        var body = [...datass?.map(el => [el.name, el.amount]),
        [{
          content: `Total                                     ${totalAmount}`, colSpan: 2,
          styles: { fillColor: "#fff3ed" }
        }]]

        doc.autoTable({
          startY: 160,
          headStyles: { fillColor: "#fff3ed" },
          head: [['Transaction ID', `${response.data.transaction.transaction_id}`]],
          styles: { textColor: [10, 10, 10] },
          body: body,
          margin: { right: 30, left: 320 }
        });

        doc.setFontSize(15);
        const headers = [
          ["Product Name", "Cost", "Bonus / Discount", "Cost Value", "Purchase Order"]];

        // doc.setFontSize(20)
        // doc.setFont("times");
        // doc.text(30, 30, `Customers List By ${response.data.transaction.customer_email} Business Partner`)
        let info = []
        response.data.transaction?.giftcards?.map((element) => {
          var costs = `$ ${Number(element.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          var bonuss = `$ ${Number(element.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          var costvalues = `$ ${Number(element.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          info.push([element.productName || element.productname, costs, bonuss, costvalues, element.purchaseOrderName])
        })

        let content = {
          startY: 350,
          head: headers,
          headStyles: { fillColor: "#fff3ed" },
          styles: { textColor: [10, 10, 10] },
          body: info,
          margin: { top: 10, right: 30, bottom: 0, left: 30 }

        };

        doc.setFontSize(16)
        doc.text(340, 600, "Subtotal")
        doc.text(420, 600, `$ ${Number(response.data.transaction.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
        doc.line(335, 605, 525, 605);

        doc.text(340, 620, "Bonus")
        doc.text(420, 620, `$ ${Number(response.data.transaction.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
        doc.line(335, 625, 525, 625);

        doc.text(340, 640, "Amount")
        doc.text(420, 640, `$ ${totalAmount}`);
        doc.line(335, 645, 525, 645);

        doc.autoTable(content);
        doc.save(`${response.data.transaction.customer_first_name}.pdf`);
        // doc.output("dataurlnewwindow", `${response.data.transaction.customer_first_name}.pdf`);

      })
      .catch((error) => {
        console.log(error)
      })


  }


  //for mail send
  const transactionMail = (id) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/sendmailtransaction/${id}`)
      .then((response) => {
        const allData = response.data;
        setSendMail(allData);
        setIsLoading(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      })
  }



  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
        {number + 1}
      </li>,
    );
  }

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    }
    else {
      setPageNumber(0);
      setShowPage(pages);
    }
  }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setPageNumber(0);
    setShowPage(1);
  };

  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getTransactionData(pageNumber, startDate, endDate, rowsPerPage, custFilter, statusFilter);
      redirectToLogin();
      getCustomerFilter();
      setIsLoading(false);
    }, 600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort]);

  const handleSelect = (date) => {
    let filtered = allproducts.filter((product) => {
      let productDate = new Date(product["createdAt"]);
      return (
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
      );
    })
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setCustomerData(filtered);
    getTransactionData(pageNumber, date?.selection?.startDate, date?.selection?.endDate, rowsPerPage, custFilter, statusFilter);
    setPageNumber(0);
    setNumberOfPages(0);
    setShowPage(1);
  }


  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleSearchError = () => {

    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/reset-error-code`)
      .then((response) => {
        let messages = response.data.message;
        // let users = response.data.user?.status
        //     if(users === false){
        //         window.localStorage.removeItem('client');
        //         navigate('/admin');
        //         toast.warning("Your account has been deactivated");  
        // }
        if (messages) {
          toast.success(messages);
        }

      })
      .catch((error) => console.error(`Error: ${error}`));


  }




  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const formatDate = (date) => {
    // Convert the date to EST time zone
    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    // Format the date as per the EST time zone, without seconds
    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };

  const handleFilter = (value) => {
    setPageNumber(0);
    setShowPage(1);
    setStatusFilter(value);
    getTransactionData(pageNumber, startDate, endDate, rowsPerPage, custFilter, value)
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "green";
      case "Failed":
        return "red";
      case "Pending":
        return "darkgray";
      case "Refund":
        return "blue";
      default:
        return "black";
    }
  };
  
  const userStatus = (userIds) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
      .then((response) => {
        let users = response.data.user?.status
        if (users === false) {
          window.localStorage.removeItem('client');
          navigate('/admin');
          toast.warning("Your account has been deactivated");
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  useEffect(() => {
    userStatus(userIds);
  }, [userIds])

  const [openDrawerId, setOpenDrawerId] = useState(null);

  const openDrawer = (transactionId) => {
    getTransactionData(pageNumber, startDate, endDate, rowsPerPage, custFilter, statusFilter,"click");
    setOpenDrawerId((prevId) => (prevId === transactionId ? null : transactionId));
  };

  return (
    <>
      <div className="p-3 p-lg-5">
        <Row>
          <Col md={4}>
            <h1 className="font20 mb-4 text-dark">Transactions</h1>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
        </Row>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="g-3">
            <Col lg={12}>
              <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between" style={{ gap: '5px' }}>
                <div className="searchWrapper_search">
                  <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                    <Form.Control
                      type="text"
                      className="border-0 searchInput"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                    <Button
                      variant="primary"
                      title="Click here to search"
                      className="searchBtn d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="/assets/images/search.png"
                        className="img-fluid"
                        alt=""
                      />
                    </Button>
                  </div>
                </div>

                <div className="custom-select transction_select mt-sm-0 mt-2" style={{zIndex: "100"}}>
                  <Select
                    styles={customStyles}
                    onChange={handleChange}
                    options={[
                      {
                        value: "All Customers",
                        label: "All Customers",
                        // email: "Filter by:All",
                      },
                      ...modifiedOptions,
                    ]}
                    defaultValue={{
                      value: "Filter by : Customers",
                      label: "Filter by : Customers",
                      // email: "Filter by:All",
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>


                <div className="custom-select transction_select mt-sm-0 mt-2" style={{zIndex: "98"}}>
                  <Select
                    styles={statusCustomStyles}
                    onChange={handleStatusChange}
                    options={[
                      {
                        value: "All Status",
                        label: "All Status",
                      },
                      // { value: "Success", label: "Success" },
                      // { value: "Pending", label: "Pending" },
                      // { value: "Refund", label: "Refund" },
                      // { value: "Failed", label: "Failed" }
                      ...statusInOptions,
                    ]}
                    defaultValue={{ value: "Filter by : Status", label: "Filter by : Status" }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>


                <div className="d-flex align-items-center justify-content-lg-end button_style transaction-date-range">
                  <Button variant="primary"
                    type="button"

                    className="fullRounded fw-bold font14 me-3 mb-2 mb-sm-0 responsive_wthTable"
                    onClick={handleShow}>
                    <span>Search With Date</span>
                  </Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Date Range</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
                    </Modal.Body>
                  </Modal>

                  <Button variant="primary"
                    type="button"

                    className="fullRounded fw-bold font14 mb-2 mb-sm-0 responsive_wthTable2"
                    onClick={handleSearchError}>
                    <span>Reset GCIP </span>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={12} className="mt-4">
              <div className="table-scroll">
                <table className="table transaction_table couponTable" style={{ verticalAlign: "middle", textAlign: 'center' }}>
                  <thead className="bgLightRed font14 ">
                    <tr className="text-center">
                      <th className="text-start py-3 " >
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Name</span>
                          {order === "ASC" ? (
                            <FaSortDown onClick={() => sorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => sorting("ASC")} />
                          )}
                        </div>
                      </th>

                       <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Business Partner</span>
                          {businessName === "ASC" ? (
                            <FaSortDown onClick={() => businessNamesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => businessNamesorting("ASC")} />
                          )}
                        </div>
                      </th> 

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Funds</span>
                          {valueorder === "ASC" ? (
                            <FaSortDown onClick={() => valuesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => valuesorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Bonus / Discount</span>
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Transaction ID</span>{" "}
                        </div>{" "}
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="me-1">Date</span>
                          {updateorder === "ASC" ? (
                            <FaSortDown onClick={() => updatesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => updatesorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="me-1">Gift Card(s)</span>
                          {giftcardorder === "ASC" ? (
                            <FaSortDown onClick={() => giftcardsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => giftcardsorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="py-3">Status</th>
                      <th className="py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font12 fw-medium ">
                 
                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="9">
                          <Bars
                            height="80"
                            width="100%"
                            margin='auto'
                            color="#FF7020"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {noshow ? <tr >
                          <td colSpan="9" className="text-center">
                            <h1 className="font16 text-dark" >No Transactions</h1>
                          </td>
                        </tr>
                          :
                          customerData.map((cust) => (
                            <React.Fragment key={cust._id}>
                            <tr onClick={() => openDrawer(cust._id)} style={{cursor:'pointer'}}>
                              <td className="font14 fw-medium  py-3">
                                {cust.customer_first_name} {cust.customer_last_name}
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                {cust.customer_business_partner}
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                  {
                                    cust.business_type_option === "Bonus" ? (
                                      <>
                                        ${Number(cust.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </>
                                    ) : (
                                      <>
                                        ${Number(cust.fund - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                      </>
                                    )
                                  }
                              </td>
                              <td>
                                <span className="me-1">
                                  {cust.business_type_option === "Bonus" ? '+' : '-'}
                                </span>
                                ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                {cust.transaction_id}
                              </td>
                              <td className="py-3 font14 fw-medium">
                                {" "}
                                {formatDate(cust.createdAt).slice(0, 16).replace("T", "      ")}
                              </td>
                              <td className="py-3 font14 fw-medium">
                                <Link to={`${cust._id}/giftcards`}>
                                  {cust.giftcards.length} Gift Card(s)
                                  <br />
                                  {cust.giftcards.length > 0 &&
                                    !cust.giftcards.every((giftcard) => giftcard.status === 'inOrder') &&

                                    (
                                      cust.giftcards.filter((giftcard) => giftcard.status === 'Success') &&
                                        cust.giftcards.filter((giftcard) => giftcard.status === 'Cancelled' || giftcard.status === 'Failed' || giftcard.status === 'Mismatch') &&
                                        cust.giftcards.filter((giftcard) => giftcard.status === 'Refund')
                                        ? (
                                          <span>

                                            (
                                            {cust.giftcards.filter((giftcard) => giftcard.status === 'Success').length > 0 ? (
                                              <span style={{ color: 'green' }}>
                                                {cust.giftcards.filter((giftcard) => giftcard.status === 'Success').length} Success
                                              </span>
                                            ) : (
                                              ""
                                            )}

                                            {cust.giftcards.filter((giftcard) => giftcard.status === 'Cancelled' || giftcard.status === 'Failed' || giftcard.status === 'Mismatch').length > 0 &&
                                              cust.giftcards.filter((giftcard) => giftcard.status === 'Success').length > 0
                                              ?
                                              <span> & </span> :
                                              ""}

                                            {cust.giftcards.filter((giftcard) => giftcard.status === 'Cancelled' || giftcard.status === 'Failed' || giftcard.status === 'Mismatch').length > 0 ? (
                                              <span style={{ color: 'red' }}>
                                                {cust.giftcards.filter((giftcard) => giftcard.status === 'Cancelled' || giftcard.status === 'Failed' || giftcard.status === 'Mismatch').length} Failed
                                              </span>
                                            ) : ""
                                            }

                                            {cust.giftcards.filter((giftcard) => giftcard.status === 'Success').length > 0 &&
                                              cust.giftcards.filter((giftcard) => giftcard.status === 'Refund').length > 0 ?
                                              <span> & </span> :
                                              ""}

                                            {
                                              cust.giftcards.filter((giftcard) => giftcard.status === 'Refund').length > 0 ? (
                                                <span style={{ color: 'blue' }}>
                                                  {cust.giftcards.filter((giftcard) => giftcard.status === 'Refund').length} Refund
                                                </span>
                                              )
                                                : (
                                                  ''
                                                )
                                            }
                                            )
                                          </span>
                                        )
                                        : cust.giftcards.some((giftcard) => giftcard.status === 'Success') ? (
                                          <span style={{ color: 'green' }}>
                                            {' '}
                                            ( {cust.giftcards.filter((giftcard) => giftcard.status === 'Success').length} Success )
                                          </span>
                                        ) : (
                                          <span style={{ color: 'red' }}>

                                            ( {cust.giftcards.filter((giftcard) => giftcard.status === 'Cancelled' || giftcard.status === 'Failed' || giftcard.status === 'Mismatch').length} Failed )
                                          </span>
                                        )
                                    )}
                                </Link>
                              </td>
                              <td className="py-3  font14 fw-medium">
                                {cust.status}
                              </td>
                              <td>
                                {role === "Admin" ? (
                                  <DropdownButton
                                    variant="white"
                                    className="p-0  tableActionDropDown text-center"
                                    title={
                                      <div className="actionBtn">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    }
                                    drop="start"
                                  >{cust.status === "Success" || cust.status === "Refund" ?
                                    <>
                                      {cust.giftcards.some((giftcard) => giftcard.status === 'Success') ? <>
                                        <Dropdown.Item
                                          className="font14 fw-medium"
                                          onClick={(e) => pdfDownlod(cust._id)}
                                        >
                                          Download PDF
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="font14 fw-medium"
                                          onClick={(e) => transactionMail(cust._id)}
                                        >
                                          Send Email
                                        </Dropdown.Item>
                                      </> : ""}
                                    </> : <></>}
                                    <Dropdown.Item
                                      className="font14 fw-medium"
                                      onClick={() => navigate(`${cust._id}/giftcards`)}
                                    >
                                      Details

                                    </Dropdown.Item>
                                  </DropdownButton>
                                ) : (
                                  <DropdownButton
                                    variant="white"
                                    className="p-0  tableActionDropDown text-center"
                                    title={
                                      <div className="actionBtn">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    }
                                    drop="start"
                                  >
                                    {cust.status === "Success" || cust.status === "Refund" ?
                                      <>
                                        <Dropdown.Item
                                          className="font14 fw-medium"
                                          onClick={(e) => pdfDownlod(cust._id)}
                                        >
                                          Download PDF
                                        </Dropdown.Item>
                                        {cust.giftcards.some((giftcard) => giftcard.status === 'Success') ?
                                          <Dropdown.Item
                                            className="font14 fw-medium"
                                            onClick={(e) => transactionMail(cust._id)}
                                          >
                                            Send Email
                                          </Dropdown.Item>
                                          : ""}
                                      </> : <></>}
                                    <Dropdown.Item
                                      className="font14 fw-medium"
                                      onClick={() => navigate(`${cust._id}/giftcards`)}
                                    >
                                      Details

                                    </Dropdown.Item>
                                  </DropdownButton>
                                )}
                              </td>
                              </tr>
                              {openDrawerId === cust._id && (
                                <tr>
                                  <td colSpan="12">
                                  <div className={`transaction-drawer ${openDrawerId === cust._id ? 'drawer-open' : ''}`}>
                                      {cust.giftcards.length > 0 && (
                                        <div>
                                          <table className="table drawerTable" style={{ verticalAlign: "middle", textAlign: 'center' }}>
                                            <thead>
                                              <tr>
                                                <th className="py-1">Product Name</th>
                                                <th className="py-1">Order ID</th>
                                                <th className="py-1">Cost</th>
                                                <th className="py-1">Bonus / Discount</th>
                                                <th className="py-1">Card Value</th>
                                                <th className="py-1">Status</th>
                                                <th className="py-1">Status Code</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {cust.giftcards.map((giftcard) => (
                                                <tr key={giftcard._id}>
                                                  <td className="font14 fw-medium  py-1">
                                                    {giftcard.productName}</td>
                                                  <td className="font14 fw-medium  py-1">
                                                    {giftcard.purchaseOrderName}</td>
                                                  <td className="font14 fw-medium  py-1">
                                                    {cust.business_type_option === "Bonus" ? (
                                                      <>
                                                        ${Number(giftcard.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      </>
                                                    ) : (
                                                      <>
                                                        ${Number(giftcard.cost - giftcard.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      </>
                                                    )
                                                    }
                                                  </td>
                                                  <td className="font14 fw-medium  py-1">
                                                    <span className="me-2">
                                                      {giftcard.business_type === "Bonus" ? "+" : "-"}
                                                    </span>
                                                    ${Number(giftcard.bonus).toFixed(2)}
                                                  </td>
                                                  <td className="font14 fw-medium  py-1">
                                                    {giftcard.business_type === "Bonus" ?
                                                      <>
                                                        ${Number(giftcard.costValue).toFixed(2)}
                                                      </>
                                                      :
                                                      <>
                                                        ${Number(giftcard.cost).toFixed(2)}
                                                      </>
                                                    }
                                                  </td>
                                                  <td className="font14 fw-medium  py-1">
                                                    {giftcard.status}</td>
                                                  <td className="font14 fw-medium  py-1">
                                                    {giftcard.gcipStatusCode}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>

                              )}
                            </React.Fragment>
                          ))}
                      </>
                    )

                    }

                  </tbody>
                </table>
              </div>
            </Col>

            <Col className="mt-4" sm={12}>
              <Row>
            <Col md={3} className="">
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"

                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>

            {
              numberOfPages === 0 ? "" :
                <Col md={6} className=" text-center  d-flex  justify-content-center align-items-center">
                  {
                    <ul className="custPagination mb-0 d-flex justify-content-center list-unstyled font14">
                      {pageNumber === 0 ? (
                        ""
                      ) : (

                        <li onClick={prevPage}>Previous </li>

                      )}


                      {items}


                      {numberOfPages - 1 === pageNumber ? (
                        ""
                      ) : (
                        <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14"><input style={{ width: "25px", border: '1px solid #4C00EF', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => { pagenumberSearch(e.target.value) }}  ></input> <span style={{ color: '#4C00EF' }}>/</span> {numberOfPages}</li>
                    </ul>
                  }
                </Col>
            }
            </Row>
            </Col>
            {/* </> */}
            {/* } */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Transaction;