import LeftSidebar from "../parts/LeftSidebar";
import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import {Outlet, useNavigate} from "react-router-dom"
import DashTopBar from "../parts/DashTopBar";
const DasboardLayout = () =>{
    useEffect(()=>{
        document.title = "ncentiva || Admin Dashboard"
    },[]);

    // useEffect(() => {
    //     let isTabClosing = false;
    
    //     const handleTabClose = () => {
    //       if (isTabClosing) {
    //         localStorage.removeItem("client");
    //       }
    //     };
    
    //     const handleVisibilityChange = () => {
    //       if (document.visibilityState === 'hidden') {
    //         isTabClosing = true;
    //        // localStorage.removeItem("client");
    //       }
    //     };
    
    //     window.addEventListener('beforeunload', handleTabClose);
    //     document.addEventListener('visibilitychange', handleVisibilityChange);
    
    //     return () => {
    //       window.removeEventListener('beforeunload', handleTabClose);
    //       document.removeEventListener('visibilitychange', handleVisibilityChange);
    //     };
    //   }, []);

    

    const navigate = useNavigate();
    var token;
    useEffect(() => {
        redirectToLogin();
    });
    const redirectToLogin = () => {
        const user = JSON.parse( window.localStorage.getItem('client'));
        if(user){
             token = user.token;
        } else{
             token = null;
        }
        
        if(token === null){
            navigate('/admin')
        }
    }
    return (
        <>
        <LeftSidebar />
        <div className="mainContent">
            <Container
                fluid
                className="p-0"
                style={{
                    backgroundColor: "#F5F5F5",
                    minHeight: "100vh",
                }}
            >
                <DashTopBar />
                <Outlet />
            </Container>
        </div>
        </>
    )
}

export default DasboardLayout;