import React, { useState, useEffect } from "react";
// import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { Bars} from "react-loader-spinner";
import axios from "axios";
import {
  Col,
  Container,
  Row,
  DropdownButton,
  // Dropdown,
  Button,
  Form,
} from "react-bootstrap";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { toast } from 'react-toastify';

const Coupons = () => {
  useEffect(()=>{
    const adminCheck= window.location.pathname;
    if(adminCheck.includes('admin')){ 
    document.title = "ncentiva || Admin Gift Cards"
    }
},[])

  // const [counter, setCounter] = useState(10);
  // const shouldLog = useRef(true);
  var userrole;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    userrole = user.user.role;
  } else {
    userrole = null;
  }
  const [search, setSearch] = useState("");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [currencyorder, setCurrencyorder] = useState("ASC");
  const [typedominationorder, setTypdeominationorder] = useState("ASC");
  const [availabledominationorder, setAvailableominationorder] = useState("ASC");
  const [discountorder, setDiscountOrder] = useState("ASC");
  const [feeorder, setFeeorder] = useState("ASC");
  const [sort, setSort] = useState("dscName");
  const [noData,setNoData] = useState(false);
  const [showPage,setShowPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(false);
  var token;

  const navigate = useNavigate();

  
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {

    pagenumberSearch(1)
    const selectedRowsPerPage = event.target.value;
    if(selectedRowsPerPage===20){

    setRowsPerPage(selectedRowsPerPage);
    getCouponsData(pageNumber, selectedRowsPerPage);
    }else{

    setRowsPerPage(selectedRowsPerPage);
    getCouponsData(pageNumber, selectedRowsPerPage);
    }
  };

  
  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getCouponsData(pageNumber, rowsPerPage);
      redirectToLogin();
      setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  // sorting

  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort('ascName')

      setOrder('DSC')

    } else {
      setSort('dscName')

      setOrder('ASC')

    }
  };

  // name

  // currency

  const currencysorting = (currencyorder) => {
    if (currencyorder === "DSC") {
      setSort('dscCurr')

      setCurrencyorder('DSC')

    }
    else {
      setSort('ascCurr')

      setCurrencyorder('ASC')

    }
  };

  // currency
  // Discount

  const discountsorting = (discountorder) => {
    if (discountorder === "DSC") {
      setSort('dscDis')


      setDiscountOrder('DSC')
    }
    else {
      setSort('ascDis')


      setDiscountOrder('ASC')
    };
  }



  // Discount

  // fee

  const feesorting = (feeorder) => {
    if (feeorder === "DSC") {
      setSort('dscFee')

      setFeeorder("DSC");
    }
    else {
      setSort('ascFee')

      setFeeorder('ASC')
    };
  };

  // fee



  // sorting


  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  const getCouponsData = (pageNumber, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 10
    }
    setIsLoading(true);
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcoupons?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      .then((response) => {
        if(response.data.message === "Please Login"){
          localStorage.removeItem('client');
        localStorage.removeItem('user');
        navigate('/admin');
        toast.error("Session Expired Please Login Again")
        // window.location.reload();
        }
        const allcoupons = response.data.coupons;
        setAllData(response.data.allData)
        if(allcoupons.length <1){
          if(pageNumber > 1){
            var d=pageNumber-1
            
          //  setPageNumber(d)
          }else{
            setNoData(true);
          }
        }else{
          setNoData(false);
        }
        const totalPages = response.data.totalPages;

        //const slice = allcoupons.slice(offset - 1 , offset - 1 + postsPerPage)
        setCustomerData(allcoupons);
        setNumberOfPages(totalPages);
        setIsLoading(false);
        //setPageCount(Math.ceil(allcoupons.length / postsPerPage))
      })
      .catch((error) => {
        console.error(`Error: ${error}`)
        setIsLoading(false);
        setIsApiCall(false);
        // if (error.response.data.message) {
        //   localStorage.removeItem('client');
        //   localStorage.removeItem('user');
        //   navigate('/admin');
        //   // toast.error("Session Expired. Please Login Again")
        // }
      });
      
  };


  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (id) => {
    const updatedSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];
    setSelectedItems(updatedSelectedItems);
  };


  const handleSelectAll = () => {
    const allIds = allData.map((cust) => cust.brand_id);
    const allSelected = selectedItems.length === allIds.length;
  
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allIds);
    }
  };

  const handleApiCall = () => {
    setIsApiCall(true);
    setIsLoading(true);
    const payload = {
      selectedItems,
      allSelected: selectedItems.length === allData.length,
    };

    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/pullcards-gcip`,{
        body: payload
      })
      .then((response) => {

        if (response.data.status === "success") {
          setIsLoading(false);
          getCouponsData(pageNumber, rowsPerPage);
          setIsApiCall(false);
          setSelectedItems([])
          toast.success(response.data.message);
        } else {
          setIsApiCall(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(`Error ${err}`);
        setIsApiCall(false);
        setIsLoading(false);
        if (err?.response?.data?.status === "failed") {
          toast.error(err?.response?.data?.message ?? "Unable to Pull Brands")
        } else {
          toast.error("Unable to Pull Brands");
        }
      })
  }



  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }


  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
      setShowPage(pageNumber+2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
      setShowPage(pageNumber);
    }
  }





  // const gotoPrevious = () => {
  //   setIsLoading(true);
  //   setPageNumber(Math.max(0, pageNumber - 1));
  //   getCouponsData(pageNumber);
  // };

  // const gotoNext = () => {
  //   setIsLoading(true);
  //   setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  //   getCouponsData(pageNumber);
  // };


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.error("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }

  useEffect(()=>{
      userStatus(userIds);
  } ,[userIds])



  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <h1 className="font20 mb-4 text-dark  "> Gift Cards</h1>
        <Container fluid className="bg-white p-4 rounded shadow">

          <Row className="align-items-center p-2 g-3">

            <Col md={4}>
              <div style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={({ currentTarget: input }) =>
                      handleSearch(input.value)
                    }
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            {/* <Col md={4}> </Col> */}
            <Col md={8} className="d-flex align-items-center justify-content-md-end">
              {isApiCall === false?
                    <Button
                      variant="primary"
                      className="fullRounded px-4 py-2 fw-bold font12"
                      onClick={() => { handleApiCall() }}
                      style={{ maxWidth: "180px" }}
                    >
                     
                      <span>Update Giftcards</span>
                    </Button>
                : 
                  <Button
                    variant="primary"
                    className="fullRounded px-4 py-2 fw-bold font12"
                    style={{ maxWidth: "180px" }}
                    disabled
                  >
                   
                    <span>Update Giftcards</span>
                  </Button>
              }
            </Col>
          </Row>
          {/* <Col className="mt-2" style={{ marginLeft: '10px' }}>
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col> */}
          {
            // <h1 className="font20 mb-4 text-dark fw-medium pt-4">
            //     Gift Cards
            // </h1>
          }

          <Row>
            <Col md={12} className="mt-4">
              <div className="table-scroll">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                    {/* <th className="text-start" onClick={handleSelectAll}> Select</th> */}
                      {/* <th className="text-start" onClick={handleSelectAll}>
                        <div style={{ cursor: 'pointer', minWidth: '82px' }}>
                          <span className="me-2" >
                            {isLoading ?
                              <input type="checkbox" /> 
                              :
                              <input type="checkbox" checked={selectedItems.length === allData.length} onChange={handleSelectAll} />
                            }
                          </span>
                          Select All
                        </div>
                      </th> */}
                      <th className="text-start">Brand</th>

                      <th className="text-center">
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Product Name</span>
                          {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />}
                        </div>
                      </th>
                      <th className="text-center">
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Product Category</span>
                          {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />}
                        </div>
                      </th>

                      <th className="text-center" >
                        <div style={{ cursor: 'pointer' }} >
                          <span className="me-1">Currency</span>
                          {currencyorder === "ASC" ? <FaSortDown onClick={() => currencysorting('DSC')} /> : <FaSortUp onClick={() => currencysorting('ASC')} />}
                        </div>
                      </th>

                      <th className="text-center">
                        <div >
                          <span className="me-1">Type of Denomination</span>
                          {
                            // typedominationorder === "ASC" ? <FaSortDown  onClick={() => denominationtypesorting('DSC')} /> : <FaSortUp  onClick={() => denominationtypesorting('ASC')}  />
                          }
                 </div>
                      </th>

                      <th className="text-center" >
                        <div >
                          <span className="me-1">Available Denomination</span>
                          {
                            // availabledominationorder === "ASC" ? <FaSortDown  onClick={() => availablesorting('DSC')} /> : <FaSortUp  onClick={() => availablesorting('ASC')}  />
                          }
                        </div>
                      </th>

                      <th className="text-center">
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Discounts</span>
                          {discountorder === "ASC" ? <FaSortDown onClick={() => discountsorting('DSC')} /> : <FaSortUp onClick={() => discountsorting('ASC')} />}
                        </div>
                      </th>

                      <th className="text-center" >
                        <div style={{ cursor: 'pointer' }} >
                          <span className="me-1">Fees</span>
                          {feeorder === "ASC" ? <FaSortDown onClick={() => feesorting('DSC')} /> : <FaSortUp onClick={() => feesorting('ASC')} />}
                        </div>
                      </th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font12 fw-regular text-center">
                  {isLoading ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        <>
                        
                        {noData? <tr >
                        <td colSpan="8" className="text-center">
                        <h1 className="font16 text-dark" >No Gift Cards</h1>
                        </td>
                      </tr>
                   :
                      customerData.map((cust) => (
                        <tr key={cust._id}>
                          {/* <td className="font14 fw-medium">
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(cust.brand_id)}
                              onChange={() => handleCheckboxChange(cust.brand_id)}
                            />
                          </td> */}
                          <td className="font14 fw-medium text-start">
                            <img src={cust.img_path} alt="img" width="100px" />
                          </td>
                          <td className="font14 fw-medium ">{cust.productname}</td>
                          <td className="font14 fw-medium ">{cust.brandCategory}</td>

                          <td className="font14 fw-medium">{cust.currency}</td>

                          <td className="font14 fw-medium">{cust.denominationtype}</td>
                          <td className="font14 fw-medium">
                          {cust.faceValue === 0.0
                            ? `$ ${Number(cust.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(cust.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                            : cust.fixed.map((fix, index, array) => (
                              `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                              ))
                          }
                          </td>
                          <td className="font14 fw-medium">

                            {!cust.discount
                              ? Number(0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              : Number(cust.discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            %
                          </td>
                          <td className="font14 fw-medium">
                            $ {!cust.fee ? Number(0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : Number(cust.fee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td>
                            {role === "Admin" ? (
                              <DropdownButton
                                variant="white"
                                className="p-0  tableActionDropDown"
                                title={
                                  <div className="actionBtn">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                }
                                drop="start"
                              >
                                <Link
                                  className="dropdown-item font12 fw-medium"
                                  to={`/admin/coupons/details/${cust.brand_id}/${cust._id}`}
                                >
                                  Details
                                </Link>
                              </DropdownButton>
                            ) : (
                              <DropdownButton
                                variant="white"
                                className="p-0  tableActionDropDown"
                                title={
                                  <div className="actionBtn">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                }
                                drop="start"
                              >
                                <Link
                                  className="dropdown-item font12 fw-medium"
                                  to={`/admin/coupons/details/${cust.brand_id}/${cust._id}`}
                                >
                                  Details
                                </Link>
                              </DropdownButton>
                            )}
                          </td>
                        </tr>
                      ))
                              }
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
        

         <Col className="mt-4" sm={12}>
              <Row>
            <Col md={3} className="">
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>

            {
              numberOfPages === 0 ? "" :
              <Col md={6} className="text-center  d-flex  justify-content-center align-items-center">
              {
                <ul className="custPagination mb-0 d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                  <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }
            </Row>
            </Col>
            {/* </Row> */}

          </Row>
        </Container>
      </div>
    </>
  );
};

export default Coupons;
