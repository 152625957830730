import React, { useState, useEffect, useRef } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Col, Container, Row, DropdownButton } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
const Coupons = () => {
    const [counter, setCounter] = useState(10);
    const shouldLog = useRef(true);
    var userrole;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
        userrole = user.user.role;
    } else {
        userrole = null;
    }

    const { id } = useParams();
    const { bid } = useParams();
    const [role] = useState(userrole);
    const [customerData, setCustomerData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    var token;
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);
        getCouponsData();
        redirectToLogin();

        setIsLoading(false);

    }, []);
    const redirectToLogin = () => {
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        if (token === null) {
            navigate("/admin");
        }
    };
    const getCouponsData = (pageNumber) => {
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/partners/${id}/${bid}/coupons`, {
                headers: {
                    'Authorization': `Bearer ${token}` }})
            .then((response) => {
                const allcoupons = response.data.coupons;
                setCustomerData(allcoupons);
            })
            .catch((error) => console.error(`Error: ${error}`)); };

    return isLoading === true ? (
        <div className="d-flex justify-content-center mt-5 pt-5">
            <Bars
            height="80"
            width="80"
            color="#FF7020"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}/>
        </div>
    ) : (
        <>
            <div className="p-3 p-lg-4 p-xl-5">

                <Container fluid className="bg-white p-4 rounded shadow">
                    <div>
                        <Link
                            to={`/admin/businesspartners/brands/${id}`}
                            className="p-0 btn btnOrangeTransparent fw-semibold"
                            variant="white"
                        >
                            <span className="me-2 fw-semibold">
                                <img
                                    src="/assets/images/back-org.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </span>
                            <span>Back</span>
                        </Link>
                    </div>
                    <h1 className="font20 mb-4 text-dark fw-bold pt-4">
                        Gift Cards
                    </h1>
                    <Row>
                        <Col md={12}>
                            <div className="table-responsive">
                                <table
                                    className="table couponTable table-borderless fontGilroy"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead className="bgLightRed font14">
                                        <tr className="text-center">
                                            <th className="text-start">
                                                Brand
                                            </th>
                                            <th>Product Name</th>
                                            <th>Currency</th>
                                            <th>Type of Denomination</th>
                                            <th>Available Denomination</th>
                                            <th>Discounts</th>
                                            <th>Fees</th>
                                            <th className="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="font12 fw-regular text-center">
                                        {customerData.map((cust) => (
                                            <tr key={cust._id}>
                                                <td className="font14 text-start">
                                                    <img
                                                        src={cust.img_path}
                                                        alt="img"
                                                        width="100px"
                                                    />
                                                </td>
                                                <td className="font14 fw-medium">

                                                    {cust.brand_name}

                                                </td>

                                                <td className="font14 fw-medium">
                                                    {cust.currency}
                                                </td>

                                                <td className="font14 fw-medium">
                                                    {cust.denominationtype}
                                                </td>
                                                <td className="font14 fw-medium">

                                                {cust.faceValue === 0.0
                            ? `$ ${Number(cust.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(cust.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                            : cust.fixed.map((fix, index, array) => (
                              `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                              ))
                          }
                                                </td>
                                                <td className="font14 fw-medium">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span
                                                            className="fs-6 px-2 d-inline-block"
                                                            style={{
                                                                minWidth:
                                                                    "60px",
                                                            }}
                                                        >
                                                            {Number(cust.discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="font14 fw-medium">
                                                {!cust.fee ? Number(0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : Number(cust.fee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                                                </td>
                                                <td>
                                                    {role === "Admin" ? (
                                                        <DropdownButton
                                                            variant="white"
                                                            className="p-0  tableActionDropDown"
                                                            title={
                                                                <div className="actionBtn">
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            }
                                                            drop="start"
                                                        >


                                                            <Link
                                                                className="dropdown-item font12 fw-medium"
                                                                to={`/admin/businesspartners/${id}/${bid}/coupons/details`}
                                                            >
                                                                Details
                                                            </Link>
                                                        </DropdownButton>
                                                    ) : (
                                                        <DropdownButton
                                                            variant="white"
                                                            className="p-0  tableActionDropDown"
                                                            title={
                                                                <div className="actionBtn">
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            }
                                                            drop="start"
                                                        >
                                                            <Link
                                                                className="dropdown-item font12 fw-medium"
                                                                to={`/admin/businesspartners/${id}/${bid}/coupons/details`}
                                                            >
                                                                Details
                                                            </Link>
                                                        </DropdownButton>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Col>


                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Coupons;
