import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row, Form} from "react-bootstrap";
import {  toast } from 'react-toastify';
import Select from 'react-select';
import axios from "axios";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const AdditionalDiscount = () => {
  const user = JSON.parse(window.localStorage.getItem('client'));
  const userrole = user.user.role;
  // const [role, setRoles] = useState(userrole);
  const [bonus_type, setBonusType] = useState("");
  const [bonus, setBonus] = useState("");
  const [getbrand, setGetBrand] = useState([]);
  const [selectBrand, setSelectBrand] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [error,setError] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
 let token;
  function isValidNumber(bonus){
    return /^\d+(?:\.\d{0,2})?$/.test(bonus);
  }
  const handleBonus = event =>{
    if(!isValidNumber(event.target.value) || event.target.value>100 ){
      setError(true);
    } else {
      setError(false);
    }
    setBonus(event.target.value);
  }

  //const [flat, setFlat] = useState(false);
  // const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);

    getbrands();
    getdiscountbrands();


    setIsLoading(false);

  }, []);
  const getbrands = () => {
    setIsLoading(true);
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          if(response.data.message === "Please Login"){
            localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          toast.error("Session Expired Please Login Again")
          // window.location.reload();
          }
        
         setGetBrand(response.data.brands);
         setIsLoading(false);
         
        
        })
        .catch((error) => console.error(`Error: ${error}`));
  };
  const getdiscountbrands = () => {
    setIsLoading(true);
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
     token = user.token;
    } else {
      token = null;
    }
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getdiscountbrands`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          if(response.data.message === "Please Login"){
            localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          toast.warning("Session Expired Please Login Again")
          // window.location.reload();
          }
        
         setCustomerData(response.data.brands);
         setIsLoading(false);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectBrand.length > 0) {
      if (bonus_type === "flat" && selectBrand[0].value === "All Brands") {
        toast.warning("Please Select Specific Brand");
        return false;
      }
    }

    if (bonus.length === 0) {
      toast.warning("Please Add A Discount");
      return false;
    }
    if (selectBrand.length === 0) {
      toast.warning("Please Select Brand");
      return false;
    }
    if(error === true){
      toast.warning("Please Add A Valid Discount");
      return;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    var token;
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
      try {
           const { data } = await axios.post(
               `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bonus`,
               {
                   bonus,
                   bonus_type,
                   selectBrand,
               }, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              },
           );
          // toast(data.message);
           if (data.status === "success") {

              toast.success(data.message);
              setBonusType("");
              setBonus("");
              setSelectBrand("");
              setTimeout(()=>{
              window.location.reload();
              },1000)              

           }
           else{
            toast.success(data.message);

           }
       } catch (err) {
          console.log(`Error : ${err}`);
           err.response.data.errors.map((error) => {
               toast.error(error);
           });
       }
       
  };


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.warning("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }

  useEffect(()=>{
      userStatus(userIds);
  } ,[userIds])



  return (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <h1 className="font20 mb-4 text-dark">Bonus Configuration</h1>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Form className="bg-white p-2 rounded containerShadow" onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col md={12} className="mt-4">

                <Form.Check inline label="Percentage" value="Percentage" name="path" onChange={(e) => setBonusType(e.target.value)} type="radio" className="text-dark labelControl" />
                <Form.Check inline label="Flat" value="flat" name="path" onChange={(e) => setBonusType(e.target.value)} type="radio" className="text-dark labelControl" />

                {bonus_type === "Percentage" ?
                  <>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Group className="mb-3" style={error?{marginTop:'1rem'}:{}}>
                          <Form.Label className="font13 text-dark fw-medium">Percentage <span className="text-danger">*</span></Form.Label>
                          <Form.Control className="font15" value={bonus} style={error?{ border:'1px solid red'}:{}} onChange={handleBonus} type="text" placeholder="Enter Percentage" />
                          {error && <div className="text-danger font14">Invalid bonus</div>}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Select Brands <span className="text-danger">*</span></Form.Label>
                          <Select isMulti options={getbrand} onChange={setSelectBrand} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  :
                  ''
                }
                {bonus_type === "flat" ?
                  <>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Group className="mb-3" style={error?{marginTop:'1rem'}:{}}>
                          <Form.Label className="font13 text-dark fw-medium">Flat <span className="text-danger">*</span></Form.Label>
                          <Form.Control className="font15" value={bonus} style={error?{ border:'1px solid red'}:{}} onChange={handleBonus} type="text" placeholder="Enter Flat Amount" />
                          {error && <div className="text-danger font14">Invalid bonus</div>}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="font13 text-dark fw-medium">Select Brands <span className="text-danger">*</span></Form.Label>
                          <Select isMulti options={getbrand} onChange={setSelectBrand} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  :
                  ''
                }

              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Button type="submit" variant="primary" className="px-4 py-2 fullRounded shadowPrimary font12">Add Bonus</Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                      <th className="text-start">
                        Brand
                      </th>
                      <th>Brand Name</th>
                      <th>Business Type</th>
                      <th>Bonus</th>
                      <th>Gift Cards</th>
                    </tr>
                  </thead>
                  <tbody className="font12 fw-regular text-center">



                  {isLoading ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (


                        customerData.map((cust) => (
                          <tr key={cust._id}>
                            <td className="font14 fw-medium text-start">
                              <img
                                src={cust.img_path}
                                alt="img"
                                width="100px"
                              />
                            </td>
                            <td className="font14 fw-medium">

                              {cust.brandname}

                            </td>
                            <td className="font14 fw-medium">

                              {cust.bonusType}
                            </td>
                            <td className="font14 fw-medium">

                              {cust.bonusType === 'percentage' ? Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%' : Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%'

                              }

                            </td>
                            <td className="font14 fw-medium">
                              <Link
                                to={`/admin/${cust.coupon_id}/coupons`}
                              >
                                <div>{cust.coupons} Gift Cards</div>
                              </Link>
                            </td>
                          </tr>
                        ))

                      )
                    }

                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  );
};

export default AdditionalDiscount;
