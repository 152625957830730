import axios from 'axios';
import { SET_BUSINESS_CONTACT_REQUEST, SET_BUSINESS_CONTACT_SUCCESS, SET_BUSINESS_CONTACT_FAILURE } from './actionTypes';

export const setBusinessContactRequest = () => ({
  type: SET_BUSINESS_CONTACT_REQUEST
});

export const setBusinessContactSuccess = (data) => ({
  type: SET_BUSINESS_CONTACT_SUCCESS,
  payload: data
});

export const setBusinessContactFailure = (err) => ({
  type: SET_BUSINESS_CONTACT_FAILURE,
  payload: err
});

export const getBusinessContact = (id, token, navigate, toast) => (dispatch) => {
  dispatch(setBusinessContactRequest());

  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinesscontact/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      const businessContactData = response.data.businessContactId;

      if (businessContactData.status === false || businessContactData.soft_delete === true) {
        localStorage.removeItem('business-client');
        navigate('/business-login');
        toast.warn("Your Account Is Deactivated");
        dispatch(setBusinessContactFailure("Account Deactivated"));
      } else {
        dispatch(setBusinessContactSuccess(businessContactData));
      } 
    })
    .catch((error) => {
      console.error("Error fetching business contact:", error);
      dispatch(setBusinessContactFailure(error));
    });
};
