import React from 'react'
import FooterBrand from './elements/FooterBrand'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios  from 'axios';
import {useDispatch, useSelector} from 'react-redux'
import {getCms } from '../Redux/GetCms/actions';

const footerbrandss = [
    {
        logo_one: "assets/images/newfooter-logo.png",
        logo_two: "",
        logo_three: "",
    },
]

const Footer = (props) => {
    const nameDomain = ( window.location.host.split('.')); 
    const [footerbrands,setFooterBrands] = useState();
    const [isCMs,setIsCMs] = useState(false);
    const cmsData = useSelector((state) => state.cmsReducer);
    const dispatch = useDispatch();
    const [showLoader,setShowLoader]= useState(true);

    // useEffect(() => {
    //     if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
    //         dispatch(getCms(nameDomain[0]));
    //     }
    // }, [])

    useEffect(() => {
        const data = cmsData.data?.cms;
        if (data?.default_ncentiva === false) {
            if (data?.footer_logos.length > 0) {
                setFooterBrands(data.footer_logos)
                setIsCMs(true);
                setShowLoader(false);
            }
        } else if(data?.default_ncentiva === true) {
            setFooterBrands(footerbrandss);
            setIsCMs(false)
            setShowLoader(false);
        }
    }, [cmsData])


    const [termsLink, setTermsLink] = useState('');
    const [privacyLink, setPrivacyLink] = useState('');

    useEffect(() => {
        getDocument();
      }, []);

    const getDocument = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getTermsConditionAndPrivacyPolicyFile`)
          .then((response) => {
           
            setTermsLink(response.data?.termsFile?.fileLink);
            setPrivacyLink(response.data?.privacyFile?.fileLink);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const openPDF = (link) => {
        if (link) {
          window.open(link, '_blank');
        }
      };
    
      const handleTextClick = (link) => {
        openPDF(link);
      };

    
  return (
    <>
    {showLoader ? '' 
    : <footer className={`footer py-2 py-sm-3 ${props.fixedFooter ? 'fixed-custom-footer-customer' : ''}  ${props.search ? 'customSearchMarginFooter' : ''} ${props.category!=="" && !props.search ? 'customCategoryMarginFooter' : ''}`}>
    <div className="container-fluid px-4 px-lg-5">
        <div className="row">
        <div className="col-md-3 col-xl-6 d-flex justify-content-center justify-content-lg-start">
            <div className="footer-left mb-sm-4 mb-lg-0">
            <div className="d-flex flex-wrap align-item-center">
                {isCMs === true ?
                (footerbrands?.map((footerbrand,i) => 
                    <div key={i}>
                        <img src="assets/images/cyber_img.png" alt="New Footer Logo" style={{maxWidth: "72px", marginLeft:"-15px"}}/>
                        <FooterBrand src={footerbrand.logo_two} product={footerbrand.product}/>
                        <FooterBrand src={footerbrand.logo_three} product={footerbrand.product}/>
                    </div>
                    ))
                    :
                    (
                        <img src="assets/images/cyber_img.png" alt="New Footer Logo" style={{maxWidth: "72px", marginLeft:"-15px"}}/>
                    )
                }
                {/* <FooterBrand/>
                <FooterBrand src="assets/images/footer-logos/iso.png"/>
                <FooterBrand src="assets/images/footer-logos/soc.png"/> */}
            </div>
            </div>
        </div>
        <div className="col-md-9 col-xl-6 d-flex flex-column flex-sm-row justify-content-center justify-content-lg-end">
        <div className="footer-right me-sm-5 mt-1">
            <div className='d-flex align-items-center justify-content-center d-sm-block' style={{ color: "#4c00ef" }}>
            <span>

                <img
                    style={{height:"18px"}}
                    src="logo192.png"
                    alt="footer logo"
                    
                />
                </span>
                <span className='ms-2' style={{cursor: 'pointer'}}>

                <a className='termsuse_hover' href={privacyLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(termsLink)}>Terms of Use</a>
                </span>
            </div>
            <div className='d-flex align-items-center justify-content-center d-sm-block'style={{ color: "#4c00ef"}}>
                <span>

                <img
                    style={{height:"18px"}}
                    src="logo192.png"
                    alt="footer logo"
                    
                />
                </span>
                <span className='ms-2' style={{cursor: 'pointer'}}>

            <a  className='termsuse_hover' href={privacyLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(privacyLink)}>Privacy Policy</a>
                </span>
            </div>

           </div>
            <div className="footer-right d-flex align-items-center justify-content-center  d-sm-block">
            
            <div className="" >
                <p>Powered by:</p> 
                <img
                style={{height:"29px"}}
                src="assets/images/footer-logo.png"
                alt="footer logo"
                />
            </div>
        


            </div>
        </div>
        </div>
    </div>
    </footer>}
    </>
  )
}

export default Footer
