import React, { useState, useEffect } from "react";
// import ReactPaginate from 'react-paginate';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Col, Container, Row, DropdownButton } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
const Coupons = () => {
    // const [counter, setCounter] = useState(10);
    // const shouldLog = useRef(true);
    var userrole;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
        userrole = user.user.role;
    } else {
        userrole = null;
    }

    const { id } = useParams();
    const [role] = useState(userrole);
    const [customerData, setCustomerData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    var token;
    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);

        getCouponsData(pageNumber);
        redirectToLogin();

        setIsLoading(false);

    }, [pageNumber]);
    const redirectToLogin = () => {
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }

        if (token === null) {
            navigate("/admin");
        }
    };
    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
    const getCouponsData = (pageNumber) => {
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }


        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/couponsbybrand/${id}?page=${pageNumber}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                const allcoupons = response.data.coupons;
                const totalPages = response.data.totalPages;

                //const slice = allcoupons.slice(offset - 1 , offset - 1 + postsPerPage)
                setCustomerData(allcoupons);
                setNumberOfPages(totalPages);
            })
            .catch((error) => {
                console.error(`Error: ${error}`)
                if (error.response.data.message) {
                    localStorage.removeItem('client');
                    localStorage.removeItem('user');
                    navigate('/admin');
                    // toast.error("Session Expired. Please Login Again")
                  }
            });
    };
    const gotoPrevious = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
        getCouponsData(pageNumber);
    };

    const gotoNext = () => {
        setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
        getCouponsData(pageNumber);
    };


    const userID = localStorage.getItem('client');
    const users = JSON.parse(userID);
    const userIds = users?.user._id;
  
  
    const userStatus = (userIds) => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
            .then((response)=>{
                let users = response.data.user?.status
                if(users === false){
                    window.localStorage.removeItem('client');
                    navigate('/admin');
                    toast.warning("Your account has been deactivated");
            }
            })
            .catch((error) => console.error(`Error: ${error}`));
        }
  
    useEffect(()=>{
        userStatus(userIds);
    } ,[userIds])
  


    return isLoading === true ? (
        <div className="d-flex justify-content-center mt-5 pt-5">
            <Bars
            height="80"
            width="100%"
            margin='auto'
            color="#FF7020"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
        </div>
    ) : (
        <>
            <div className="p-3 p-lg-4 p-xl-5">

                <Container fluid className="bg-white p-4 rounded shadow">
                    <div>
                        <Link
                            to="/admin/brands"
                            className="p-0 btn btnOrangeTransparent fw-semibold"
                            variant="white"
                        >
                            <span className="me-2 fw-semibold">
                                <img
                                    src="/assets/images/back-org.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </span>
                            <span>Back</span>
                        </Link>
                    </div>
                    <h1 className="font20 mb-4 text-dark fw-bold pt-4">
                        Gift Cards
                    </h1>
                    <Row>
                        <Col md={12}>
                            <div className="table-responsive">
                                <table
                                    className="table couponTable table-borderless fontGilroy"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead className="bgLightRed font14">
                                        <tr className="text-center">
                                            <th className="text-start">
                                                Brand
                                            </th>
                                            <th>Product Name</th>
                                            <th>Currency</th>
                                            <th>Type of Denomination</th>
                                            <th>Available Denomination</th>
                                            <th>Discounts</th>
                                            <th>Fees</th>
                                            <th className="">Action</th>
                                        </tr>
                                    </thead>
                                    {customerData.length>0 ? <tbody className="font12 fw-regular text-center">{customerData.map((cust) => (
                                            <tr key={cust._id}>
                                                <td className="font14 text-start">
                                                    <img
                                                        src={cust.img_path}
                                                        alt="img"
                                                        width="100px"
                                                    />
                                                </td>
                                                <td className="font14 fw-medium">

                                                    {cust.productname}

                                                </td>

                                                <td className="font14 fw-medium">
                                                    {cust.currency}
                                                </td>

                                                <td className="font14 fw-medium">
                                                    {cust.denominationtype}
                                                </td>
                                                <td className="font14 fw-medium">

                                                {cust.faceValue === 0.0
                                                    ? `$ ${Number(cust.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(cust.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                : cust.fixed.map((fix, index, array) => (
                                                    `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                                                     ))
                                                }

                                                </td>
                                                <td className="font14 fw-medium">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span
                                                            className="fs-6 px-2 d-inline-block"
                                                            style={{
                                                                minWidth:
                                                                    "60px",
                                                            }}
                                                        >
                                                            {Number(cust.discount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="font14 fw-medium">
                                                    {cust.fee ?
                                                        Number(cust.fee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                    : 
                                                        "0.00"
                                                    }

                                                </td>
                                                <td>
                                                    {role === "Admin" ? (
                                                        <DropdownButton
                                                            variant="white"
                                                            className="p-0  tableActionDropDown"
                                                            title={
                                                                <div className="actionBtn">
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            }
                                                            drop="start"
                                                        >


                                                            <Link
                                                                className="dropdown-item font12 fw-medium"
                                                                to={`/admin/coupons/details/${cust.brand_id}/${cust._id}`}
                                                            >
                                                                Details
                                                            </Link>
                                                        </DropdownButton>
                                                    ) : (
                                                        <DropdownButton
                                                            variant="white"
                                                            className="p-0  tableActionDropDown"
                                                            title={
                                                                <div className="actionBtn">
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                            }
                                                            drop="start"
                                                        >
                                                            <Link
                                                                className="dropdown-item font12 fw-medium"
                                                                to={`/admin/coupons/details/${cust.brand_id}/${cust._id}`}
                                                            >
                                                                Details
                                                            </Link>
                                                        </DropdownButton>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>: <tbody><tr>
                       <td colSpan="8" className="text-center">
                        <h1 className="font16 text-dark">No Gift Card Available</h1>
                        </td>
                      </tr>
                      </tbody>}
                                </table>
                            </div>
                        </Col>

                        {customerData.length > 0 ?<Col className="mt-5 mb-4">
                            <ul className='custPagination d-flex justify-content-center list-unstyled font14'>
                                {pageNumber === 0 ? '' : <li onClick={gotoPrevious}>Previous</li>}

                                {pages.map((pageIndex) => (

                                    <li key={pageIndex} className={pageIndex === pageNumber ? 'active' : ''} onClick={() => { setPageNumber(pageIndex); getCouponsData(pageNumber); }}>
                                        {pageIndex + 1}
                                    </li>
                                ))}
                                {numberOfPages - 1 === pageNumber ? '' : <li onClick={gotoNext}>Next</li>}
                            </ul>
                        </Col>:''}
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Coupons;
