import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Bars, ThreeDots } from "react-loader-spinner";


const BusinessGiftId = () => {
  const user = JSON.parse(window.localStorage.getItem("business-client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    localStorage.removeItem('business-client')
    navigate("/business-login");
  }

  const [search, setSearch] = useState('');

  const { id } = useParams();

  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [sort, setSort] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fund, setFund] = useState("");
  const [businessTypeOption, setBusinessTypeOption] = useState("");
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  if (user) {
    token = user.token;
  } else {
    token = null;
  }


  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber);
      redirectToLogin();
      setIsLoading(false);
    }, 600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const getAllUsers = (pageNumber) => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/gettransactiondetails/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      )
      .then((response) => {
        if (response.data.message == "Please Login") {
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }
        const transaction = response.data.transaction;
        const totalPages = response.data.totalPages;
        setBusinessTypeOption(response.data.transaction.business_type_option);
        setCustomerData(transaction);
        setNumberOfPages(totalPages);
        let balanceFormat;
        balanceFormat = response.data.transaction.fund;
        balanceFormat = Number(balanceFormat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setFund(balanceFormat);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }





  const retryFunc = (Id) => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/retry-gcip-order/${Id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        toast.then(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      }).catch((err) => {
        console.log(err);
      })
  }





  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      localStorage.removeItem('business-client')
      navigate("/business-login");
    }
  };

  const [primaryColor, setPrimaryColor] = useState('#FF7020');

  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <div className="d-flex mb-4 align-items-center">
        <Link
          onClick={() => navigate(-1)}
          className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
          variant="white"
        >
          <span className="me-2 fw-semibold">
            <img
              src="/assets/images/back-org.png"
              className="img-fluid"
              alt=""
            />
          </span>
          <span>Back</span>
        </Link>
        <h1 className="font20 text-dark">Transaction Details</h1>
      </div>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={7} xxl={8} lg={7} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Recipient : {customerData?.customer_first_name} {customerData?.customer_last_name}</h3>
                    }
                  </div>
                </div>
              </Col>
              <Col xl={5} xxl={4} lg={5} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Transaction Id : {customerData?.transaction_id}</h3>
                    }
                  </div>
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Total : ${fund}</h3>
                    }
                  </div>
                </div>
              </Col>

            </Row>
          </Container>
          <Col md={12} className="mt-4">
            <div className="table-responsive table_scroll">
              <table
                className="table couponTable table-borderless fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr className="text-center">
                    <th className="text-start">
                      Brand
                    </th>
                    <th>Product Name</th>
                    <th>Order Id</th>
                    <th>Cost</th>
                    <th>Bonus / Discount</th>
                    <th>Card Value</th>
                    <th>Status</th>

                  </tr>
                </thead>
                <tbody className="font14 fw-medium text-center">

                  {isLoading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin='auto'
                          color={primaryColor}
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </td>
                    </tr>
                  ) : (
                    customerData?.giftcards?.map((cust) => (

                      <tr
                        key={cust._id}
                      >
                        <td className="font14 text-start">
                          <img
                            src={cust.img_path}
                            alt="img"
                            width="100px"
                          />
                        </td>
                        <td className="font14 fw-medium">

                          {cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}

                        </td>
                        <td className="py-3 font14 fw-medium ">
                          {cust.purchaseOrderName}
                        </td>
                        <td>
                          {businessTypeOption === "Bonus" ?
                            <>
                              ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            :
                            <>
                              ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                          }
                        </td>
                        <td className="py-3 font14 fw-medium ">
                          <span className="me-1">
                            {businessTypeOption === "Bonus" ? '+' : '-'}
                          </span>
                          ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                          {businessTypeOption === "Bonus" ?
                            <>
                              ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            :
                            <>
                              ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                          }
                        </td>
                        <td className="py-3 font14 fw-medium ">
                          {cust?.status || "Success"}
                        </td>

                      </tr>
                    ))
                  )}

                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>


    </div>
  );
};

export default BusinessGiftId;

