import { useState,useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

function Dashboard() {

    useEffect(()=>{
        document.title = "ncentiva || Admin Dashboard"
    },[])

    const [partnerList] = useState([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
    ]);
    const [gift] = useState({
        sold: 60,
        open: 80,
        expire: 30,
    });
    const [bonus] = useState(45);

    //175 == 100%
    //max == 2975

    // 175 - (175*per)

    // 175 - (175 * per ) / 100

    return (
        <>
            <div className="p-3 p-lg-4 p-xl-5 container-fluid text-dark fontGilroy">
                <Row>
                    <Col lg={8}>
                        <Container
                            fluid
                            className="p-4 welcomencentiva bg-white mt-2 mt-lg-0"
                            style={{
                                backgroundImage:
                                    'url("/assets/images/welcomebg.png")',
                            }}
                        >
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <div className="p-3 font12">
                                        <h2 className="font20 text-dark fw-semibold mb-3">
                                            Welcome To ncentiva
                                        </h2>
                                        <p>
                                            Mr. John Doe login into orem ipsum
                                            dolor sit amet, consectetur
                                            adipiscing elit. Sed eget tellus
                                            eget ante cursus ultricies. Mauris
                                            imperdiet suscipit placerat.
                                        </p>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            className="fw-bold font12 fullRounded shadowPrimary px-4 py-2 mt-4"
                                        >
                                            View Profile
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <img
                                        src="/assets/images/welcomeGroup.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                        </Container>

                        <Container fluid className="p-0 mt-4">
                        {
                           // <img src="/assets/images/redeem-graph.jpg" className="w-100" alt=""/>
                        }
                        </Container>

                        <Container className="mt-4 p-0">
                            <Row>
                                <Col md={6}>
                                {
                                    // <div className="rounded bg-white bonusWrapper  px-4 py-5"   style={{   height: "100%",  backgroundImage:   'url("/assets/images/dollar.png")', }} >
                                    //     <h2 className="font20">Bonus</h2>

                                    //     <div className="progressBar">
                                    //         <div
                                    //             className="pToolTip font12 text-white text-center"
                                    //             style={{ left: bonus + "%" }}
                                    //         >
                                    //             {bonus}%
                                    //         </div>
                                    //         <ProgressBar
                                    //             variant="primary"
                                    //             now={bonus}
                                    //         />
                                    //     </div>

                                    //     <img
                                    //         src="/assets/images/bottom-left-curve.png"
                                    //         className="img-fluid bottomLeft"
                                    //         alt=""
                                    //     />
                                    // </div>
                                }
                                </Col>
                                <Col md={6}>
                                {
                                    // <div
                                    //     className="rounded giftCardWrapper text-center text-white p-3 p-lg-4 p-xl-5"
                                    //     style={{
                                    //         backgroundImage:
                                    //             'url("/assets/images/giftcardbg.png")',
                                    //     }}
                                    // >
                                    //     <div className="title1 text-uppercase mb-3">
                                    //         Gift Card
                                    //     </div>
                                    //     <div className="title2 text-uppercase mb-4">
                                    //         Pick Your Brand
                                    //     </div>
                                    //     <Button
                                    //         variant="primary"
                                    //         className="fullRounded shadowPrimary px-4 py-2"
                                    //     >
                                    //         Apply Now
                                    //     </Button>
                                    // </div>
                                }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={4}>
                        <Container className="dashboardRightSidebar ms-0 p-0 ">
                            <Row>
                                <Col sm={6} lg={12}>
                                    <div className="bg-white p30 rounded circularCounterWrpr mt-4 mt-lg-0">
                                        <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div className="circularCounter position-relative rounded d-inline-block me-3">
                                                <svg>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                    ></circle>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                        style={{
                                                            strokeDashoffset:
                                                                175 -
                                                                (175 *
                                                                    gift.sold) /
                                                                    100,
                                                            stroke: "#FF7020",
                                                        }}
                                                    ></circle>
                                                </svg>
                                                <div className="text text-center">
                                                    {gift.sold}%
                                                </div>
                                            </div>
                                            <div className="font14">
                                                Gift Card Sold
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                                            <div className="circularCounter position-relative rounded d-inline-block me-3">
                                                <svg>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                        style={{
                                                            stroke: "#FFF5CD",
                                                        }}
                                                    ></circle>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                        style={{
                                                            strokeDashoffset:
                                                                175 -
                                                                (175 *
                                                                    gift.open) /
                                                                    100,
                                                            stroke: "#F5C400",
                                                        }}
                                                    ></circle>
                                                </svg>
                                                <div className="text text-center">
                                                    {gift.open}%
                                                </div>
                                            </div>
                                            <div className="font14">
                                                Gift Card Open
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <div className="circularCounter position-relative rounded d-inline-block me-3">
                                                <svg>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                        style={{
                                                            stroke: "#E2E2E2",
                                                        }}
                                                    ></circle>
                                                    <circle
                                                        cx="38"
                                                        cy="38"
                                                        r="27.5"
                                                        style={{
                                                            strokeDashoffset:
                                                                175 -
                                                                (175 *
                                                                    gift.expire) /
                                                                    100,
                                                            stroke: "#595959",
                                                        }}
                                                    ></circle>
                                                </svg>
                                                <div className="text text-center">
                                                    {gift.expire}%
                                                </div>
                                            </div>
                                            <div className="font14">
                                                Gift Card Expire
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} lg={12}>
                                    <div className="bg-white rounded partnersWrpr overflow-hidden mt-4">
                                        <div className="d-flex justify-content-between mb-4">
                                            <div>
                                                <h2 className="font20">
                                                    Partners
                                                </h2>
                                            </div>
                                            <div>
                                                <span className="cursor-pointer text-primary fw-bold font12">
                                                    View All
                                                </span>
                                            </div>
                                        </div>
                                        <div className="listBox">
                                            <ul className="partnerList list-unstyled font12">
                                                {partnerList.map(
                                                    (item, index) => (
                                                        <li
                                                            key={index}
                                                            className="d-flex align-items-center mb-4"
                                                        >
                                                            <div className="me-2">
                                                                <img
                                                                    src="/assets/images/partner-1.png"
                                                                    className="img-fluid thumb"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div>
                                                                <span className="d-block">
                                                                    Olivia
                                                                    Johnson
                                                                </span>
                                                                <span className="d-block fw-semibold">
                                                                    olivia.johnson@gmail.com
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Dashboard;
