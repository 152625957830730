import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars, ThreeDots } from "react-loader-spinner";
import ContactModal from "../components/ContactModal";
import { replace } from "formik";
// import { Select } from 'react-select';


const GiftId = () => {
  const user = JSON.parse(window.localStorage.getItem("client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }
  
  const [search,setSearch] = useState('');
  const [role] = useState(userrole);
  const [order, setOrder] = useState("ASC");
  const {id} = useParams();
  const [phoneorder, setPhoneorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [customerOrder, setCustomerOrder] = useState("ASC");
  const [brandOrder, setBrandOrder] = useState("ASC");
  const [customerData, setCustomerData] = useState([]);
  const [custProductName, setCustProductName] = useState(null);
  const [custPurchaseOrderName, setCustPurchaseOrderName] = useState(null);
  const [custCost, setCustCost] = useState(null);
  const [custBonus, setCustBonus] = useState(null);
  const [custStatus, setCustStatus] = useState(null);
  const [custCostValue, setCustCostValue] = useState(null);
  const [verifyProductName, setVerifyProductName] = useState(null);
  const [verifyPurchaseOrderName, setVerifyPurchaseOrderName] = useState(null);
  const [verifyPurchaseOrderId, setVerifyPurchaseOrderId] = useState(null);
  const [verifyQuantity, setVerifyQuantity] = useState(null);
  const [verifyAmount, setVerifyAmount] = useState(null);
  const [verifyStatus, setVerifyStatus] = useState(null);
  const [newPurchaseOrderName, setNewPurchaseOrderName] = useState('');
  const [custId, setCustId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [sort, setSort] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fund, setFund] = useState("");
  const [isGiftcardFailed, setIsGiftcardFailed] = useState(false);  
  const [realTimeGiftcards, setRealTimeGiftcards] = useState([]);
  const [nonRealTimeGiftcards, setNonRealTimeGiftcards] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);
  const [failedData, setFailedData] = useState([]);
  const [ isFailed, setIsFailed ] = useState(false);
  const [ giftcardsLength, setGiftcardslength ] = useState(false);
  const [showUpdateModal, setshowUpdateModal] = useState(false);
  const [showVerifyModal, setshowVerifyModal] = useState(false);
  const [ checkTransaction, setCheckTransaction ] = useState([]);
  const [show, setShow] = useState(false);
  const [ secondShow, setSecondShow ] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [businessTypeOption, setBusinessTypeOption] = useState("");
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  if (user) {
    token = user.token;
  } else {
    token = null;
  }
  let giftcardsLen;
  const handleCloseUpdateModal = () => {
    setshowUpdateModal(!showUpdateModal);
    setNewPurchaseOrderName('');


  };
  const handleCloseVerifyModal = () => {
    setshowVerifyModal(!showVerifyModal);
    


  };
  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber);
      redirectToLogin();
      setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const getAllUsers = (pageNumber) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/gettransactiondetails/${id}`, {
          headers: {
            'Authorization' : `Bearer ${token}`
          }
      }
      )
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('client');
          navigate('/admin');
          toast.error("Session Expired Please Login Again")
        }  
        setApiResponse(true)
        const transaction = response.data.transaction;
        const totalPages = response.data.totalPages;
        setCustomerData(transaction);
        const data = transaction?.giftcards?.filter((giftcard) => giftcard.status === 'Failed' || giftcard.status === 'Cancelled' || giftcard.status === 'Mismatch');
        setFailedData(data);
        const inprogressGiftcards = transaction?.giftcards?.filter((giftcard) => giftcard.status === 'inOrder');
        setCheckTransaction(inprogressGiftcards)
        let length;
        length = response.data.transaction.card_length;
        length = parseInt(length);
        setNumberOfPages(totalPages);
        setRealTimeGiftcards(response.data.realgiftcards);
        setBusinessTypeOption(response.data.transaction.business_type_option);
        setNonRealTimeGiftcards(response.data.nonrealgiftcards);
        let balanceFormat;
        balanceFormat = response.data.transaction.fund;
        balanceFormat = Number(balanceFormat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setFund(balanceFormat);
        giftcardsLen = transaction.giftcards
        if(response.data.transaction.giftcards.length > 0) {
          setGiftcardslength(true);
        }
        for (let i = 0; i < giftcardsLen?.length; i++) {
          if(giftcardsLen[i].status === "Failed" || giftcardsLen[i].status === 'Mismatch') {
            setIsGiftcardFailed(true)
          }
        }

      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }


  // const [isRealTime, setIsRealTime] = useState(false);
  

  const retryFunc = (Id) =>{
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setIsFailed(true);
    axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/retry-gcip-order/${Id}?loginUserId=${usersid}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      if(response.data.message === "Cart available at GCIP"){
        handleShow(Id)
      }else {

        if (response.data.status === "success") { 
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message)
        }
      }
      setIsFailed(false);
      getAllUsers(pageNumber)
    //  setTimeout(()=>{
    //   window.location.reload();
    //  },1500)
    }).catch((err)=>{
      console.log(err);
    })
  }



  const deleteGcipCart = (id) => {
    const Id = modalId;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setIsLoading(true)
    handleClose();
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-gcipcart/${Id}`, { 
        headers: {
          'Authorization' : `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          setIsLoading(false);
          handleShowNextModal();
          // handleClose()
          getAllUsers()
        } else {
          toast.warning(response.data.message)
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const refundFunc = (Id) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/refund-gcip-order/${Id}`, 
      {
        loginUserId: usersid
      })
      .then((res) => {
        toast.success(res.data.message)
        getAllUsers()
      })
      .catch((err)=> {
        console.log(err)
      })
  }
  const handleUpdateModalToggle = (data) => {
    // Update modal visibility state
    setshowUpdateModal(!showUpdateModal);
  
    // Use the data as needed, for example, you can set it to a state variable
    setCustProductName(data.productName);
    setCustPurchaseOrderName(data.purchaseOrderName)
    setCustCost(data.cost)
    setCustBonus(data.bonus)
    setCustCostValue(data.costValue)
    setCustStatus(data.status)
    setCustId(data._id)
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value.trim();
    setNewPurchaseOrderName(inputValue);
  };
  
const handleReplaceOrder = async() => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/replace-order`,
    {
      custId,
      custPurchaseOrderName,
      newPurchaseOrderName,
      loginUserId: usersid
    },{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  },
  
  );
  if(data.message === "Success"){
    setshowUpdateModal(!showUpdateModal);
    setshowVerifyModal(!showVerifyModal);
    setVerifyProductName(data.giftcard.items[0].cardType)
    setVerifyPurchaseOrderName(data.giftcard.name)
    setVerifyPurchaseOrderId(data.giftcard.items[0].purchaseOrderId)
    setVerifyQuantity(data.giftcard.items[0].quantity)
    setVerifyAmount(data.giftcard.items[0].amount)
    setVerifyStatus(data.giftcard.status)
    setNewPurchaseOrderName('');

  }else{
    toast.error(data.message);
  }
};

  const handleRetry = () => {
    setshowVerifyModal(!showVerifyModal);
    retryFunc(custId);

  }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   nameOrder


  const phonesorting = (col) => {
    if (phoneorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("DSC");
    }
    if (phoneorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("ASC");
    }
  };

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.success("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }

  useEffect(()=>{
      userStatus(userIds);
  } ,[userIds])

  const handleClose = () => {
    setShow(false);
    setModalId(null); 
  };

  const handleShow = (Id) => {
    setShow(true);
    setModalId(Id); 
  };


  const handleCloseNextModal = () => {
    setSecondShow(false);
  };

  const handleShowNextModal = (Id) => {
    setSecondShow(true);
  };



  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <div className="d-flex mb-4 align-items-center">
        <Link
          onClick={() => navigate(-1)}
          to="/admin/transaction"
          className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
          variant="white"
        >
          <span className="me-2 fw-semibold">
            <img
              src="/assets/images/back-org.png"
              className="img-fluid"
              alt=""
            />
          </span>
          <span>Back</span>
        </Link>
        <h1 className="font20 text-dark">Transaction Details</h1>
      </div>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={7} xxl={8} lg={7} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Recipient : {customerData?.customer_first_name} {customerData?.customer_last_name}</h3>
                    }
                  </div>
                </div>
              </Col>
              <Col xl={5} xxl={4} lg={5} className="mt-1" >
                <div className="text-dark font12">
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Transaction ID : {customerData?.transaction_id}</h3>
                    }
                  </div>
                  <div className="d-flex align-items-center w-100 ms-0">
                    {isLoading === true ?
                      ""
                      :
                      <h3 className="font20 text-dark">Total : ${fund}</h3>
                    }
                  </div>
                  {apiResponse ?

                    failedData?.length > 0 && giftcardsLength && checkTransaction.length == 0?
                      <div>
                        <Button
                          variant="primary"
                          style={{ marginTop: "10px" }}
                          type="button"
                          className="fullRounded px-4 py-2 fw-bold font12"
                          onClick={(e) => refundFunc(id)}
                        >
                          <span className="me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                              <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                              <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                            </svg>
                          </span>
                          <span>Complete Transaction</span>
                        </Button>
                      </div>
                      : ""

                    : ""}
                  
                  {/* {isGiftcardFailed ?
                    <div
                      onClick={(e) => refundFunc(id)}
                      className="d-flex align-items-center w-100 ms-0"
                      style={{ cursor: "pointer", marginLeft: "20px" }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isLoading === true ? (
                        ""
                      ) : (
                        <>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill={isHovered ? "red" : "blue"}
                            className="bi bi-bootstrap-reboot"
                            viewBox="0 0 16 16"
                          >
                            <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
                            <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
                          </svg>
                          {isHovered && <span style={{ marginLeft: "5px", fontSize: "18px", fontWeight: "700" }}>Refund</span>}
                        </>
                      )}
                    </div>
                    : ""} */}
                </div>
              </Col>

            </Row>
          </Container>
          <Col md={12} className="px-0">
            <div className="px-3">
              {realTimeGiftcards.length > 0 ? <>
              <Row className="justify-content-between g-3">
                <Col lg='auto' xl='auto' md="auto" >
                  <h3 className="font16 pt-3 text-dark">Realtime Giftcard(s)</h3>

                </Col>
                <Col lg='auto' xl='auto' md="auto" className="">
                  {/* <Button
                    variant="primary"
                    type="button"
                    className="fullRounded px-4 py-2 fw-bold font12"
                    onClick={(e) => refundFunc(id, true)}
                  >
                    <span className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                      </svg>
                    </span>
                    <span>Complete Transaction</span>
                  </Button> */}
                </Col>
              </Row>
              <div className="table-responsive table_scroll_admin">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle", marginTop: "10px", minWidth: '1000px' }}
                >
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                      <th className="text-start" width="110px">
                        Brand
                      </th>
                      <th width="250px">Product Name</th>
                      <th width="210px">Order ID</th>
                      <th>Cost</th>
                      <th>Bonus / Discount</th>
                      <th>Card Value</th>
                      <th>Status</th>
                      <th>Status Code</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">

                    {isLoading || isFailed === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                          <Bars
                            height="80"
                            width="100%"
                            margin='auto'
                            color="#FF7020"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      realTimeGiftcards?.map((cust) => (

                        <tr
                          key={cust._id}
                        >
                          <td className="font14 text-start">
                            <img
                              src={cust.img_path}
                              alt="img"
                              width="100px"
                            />
                          </td>
                          <td className="font14 fw-medium">

                            {cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}

                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {cust.purchaseOrderName}
                          </td>
                          <td>
                            {businessTypeOption === "Bonus" ? 
                            <>
                            ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            :
                            <>
                            ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            }
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            <span className="me-1">
                              {businessTypeOption === "Bonus" ? '+' : '-'}
                            </span>
                            ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td className="py-3 font14 fw-medium ">
                          {businessTypeOption === "Bonus" ? 
                            <>
                            ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            :
                            <>
                            ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </>
                            }
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {cust.status || "Success"}
                          </td>
                          <td className="py-3 font14 fw-medium ">
                          {cust.status === "inOrder" ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Bars
                            height="35"
                           width="100%"
                           margin='auto'
                          color="#FF7020"
                          ariaLabel="bars-loading"
                           wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          />
                          </div>:
                          <>
                            {cust.gcipStatusCode || "200"}
                          </>
                      }
                          </td>
                          <td>
                            {cust.status === "Failed" || cust.status === 'Mismatch'?
                            <>
                            <span onClick={(e) => retryFunc(cust._id)} style={{ cursor: "pointer" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ color: 'red', margin: '2px'  }} className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                  <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                </svg>
                              </span>
                              <span onClick={(e) => handleUpdateModalToggle(cust)} style={{ cursor: "pointer" }}>
                              <svg class="feather feather-edit" fill="none" width="22" height="22" stroke="currentColor" style={{ color: 'blue' }} stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                            </span>
                            </>
                              
                              :
                              <>
                                {cust.status === "retried" ?
                                  <div onClick={(e) => retryFunc(cust._id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ color: 'red'}} className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                      <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                    </svg>
                                  </div>
                                  : <>
                                    {
                                      cust.status === "Success" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2-circle" style={{ color: 'green' }} viewBox="0 0 16 16">
                                          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                        </svg>
                                        :
                                        <>
                                          {
                                            cust.status === "Pending" || cust.status === "inOrder" ?
                                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Bars
                                                  height="35"
                                                  width="100%"
                                                  margin='auto'
                                                  color="#FF7020"
                                                  ariaLabel="bars-loading"
                                                  wrapperStyle={{}}
                                                  wrapperClass=""
                                                  visible={true}
                                                /> 
                                              </div>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2-circle" style={{ color: 'green' }} viewBox="0 0 16 16">
                                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                              </svg>
                                          }
                                        </>
                                    }
                                  </>}
                              </>

                            }

                          </td>
                        </tr>
                      ))
                    )}

                  </tbody>
                </table>
              </div>
              </> : ""}

                          
              {nonRealTimeGiftcards.length > 0 && isFailed === false ?  <>
              
              <Row className="justify-content-between g-3 mt-2">
                <Col lg="auto" xl="auto" md="auto" >
                  <h3 className="font16 pt-3 text-dark">Non Realtime Giftcard(s)</h3>
                </Col>
                <Col lg="auto" xl="auto" md="auto" className="">
                  {/* <Button
                    variant="primary"
                    type="button"
                    className="fullRounded px-4 py-2 fw-bold font12"
                    onClick={(e) => refundFunc(id, false)}
                  >
                    <span className="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg>
                  </span>
                    <span>Complete Transaction</span>
                  </Button> */}
                </Col>
              </Row>
              <div className="table-responsive">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle", marginTop: "15px", minWidth: '1000px' }}
                >
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                      <th className="text-start" width="110px">
                        Brand
                      </th>
                      <th width="250px">Product Name</th>
                      <th width="210px">Order ID</th>
                      <th>Cost</th>
                      <th>Bonus / Discount</th>
                      <th>Card Value</th>
                      <th>Status</th>
                      <th>Status Code</th>
                      <th>Action</th>

                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">

                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                          <Bars
                            height="80"
                            width="100%"
                            margin='auto'
                            color="#FF7020"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      nonRealTimeGiftcards?.map((cust) => (

                        <tr
                          key={cust._id}
                        >
                          <td className="font14 text-start">
                            <img
                              src={cust.img_path}
                              alt="img"
                              width="100px"
                            />
                          </td>
                          <td className="font14 fw-medium">

                            {cust?.productName?.replace(/['"]+/g, '') || cust?.productname?.replace(/['"]+/g, '')}

                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {cust.purchaseOrderName}
                          </td>
                          <td>
                            {businessTypeOption === "Bonus" ?
                              <>
                                ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                              :
                              <>
                                ${Number(cust.cost - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                            }
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            <span className="me-1">
                              {businessTypeOption === "Bonus" ? '+' : '-'}
                            </span>
                            ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {businessTypeOption === "Bonus" ?
                              <>
                                ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                              :
                              <>
                                ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                            }
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {cust.status || "Success"}
                          </td>
                          <td className="py-3 font14 fw-medium ">
                            {cust.status === "inOrder" ?
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Bars
                                  height="35"
                                  width="100%"
                                  margin='auto'
                                 color="#FF7020"
                                 ariaLabel="bars-loading"
                                 wrapperStyle={{}}
                                 wrapperClass=""
                                visible={true}
                                />
                              </div> :
                              <>
                                {cust.gcipStatusCode || "200"}
                              </>
                            }
                          </td>
                          <td>
                            {cust.status === "Failed" || cust.status === 'Mismatch' ?
                              <>
                              <span onClick={(e) => retryFunc(cust._id)} style={{ cursor: "pointer" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ color: 'red', margin: '2px'  }} className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                    <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                  </svg>
                                </span>
                                <span onClick={(e) => handleUpdateModalToggle(cust)} style={{ cursor: "pointer" }}>
                                <svg class="feather feather-edit" fill="none" width="22" height="22" stroke="currentColor" style={{ color: 'blue' }} stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                              </span>
                              </>

                              :
                              <>
                                {cust.status === "retried" ?
                                  <div onClick={(e) => retryFunc(cust._id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" style={{ color: 'red' }} className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                      <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                    </svg>
                                  </div>
                                  : <>
                                    {
                                      cust.status === "Success" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2-circle" style={{ color: 'green' }} viewBox="0 0 16 16">
                                          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                        </svg>
                                        :
                                        <>
                                          {
                                            cust.status === "Pending" || cust.status === "inOrder" ?
                                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Bars
                                                 height="35"
                                                 width="100%"
                                                 margin='auto'
                                                 color="#FF7020"
                                                 ariaLabel="bars-loading"
                                                 wrapperStyle={{}}
                                                 wrapperClass=""
                                                 visible={true}
                                                />
                                              </div>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check2-circle" style={{ color: 'green' }} viewBox="0 0 16 16">
                                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                              </svg>
                                          }
                                        </>
                                    }
                                  </>}
                              </>

                            }

                          </td>
                        </tr>
                      ))
                    )}

                  </tbody>
                </table>
              </div>
              </>
:""}
            </div>
          </Col>

          {/* <Col className="mt-5 mb-4">
            <ul className="custPagination d-flex justify-content-center list-unstyled font14">

              {pageNumber === 0 ? (
                ""
              ) : (

                <li onClick={prevPage}>Previous </li>

              )}


              {items}

              {numberOfPages - 1 === pageNumber ? (
                ""
              ) : (
                <li onClick={nextPage}>Next</li>
              )}

            </ul>
          </Col> */}
        </Row>
      </Container>
      {/* Reduce balance modal */}
      <Modal
        show={showUpdateModal}
        onHide={handleCloseUpdateModal}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Replace Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reduce_modal_body">
          <div className="row g-3">
          <div className="col-12">
                  <div className="my-2">
                    <label htmlFor="">Product Name: </label>
                    <span className="text-dark">  {custProductName} </span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Order ID: </label>
                    <span className="text-dark"> {custPurchaseOrderName}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Cost Value: </label>
                    <span className="text-dark"> ${Number(custCost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Bonus: </label>
                    <span className="text-dark"> ${Number(custBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Cart Value: </label>
                    <span className="text-dark"> ${Number(custCostValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Status: </label>
                    <span className="text-dark"> {custStatus}</span>
                  </div>
                </div>
              <div className="col-12">
                <label htmlFor="business-partner" className="form-label">New Order ID</label>
                <input type="text" className={`form-control`} value={newPurchaseOrderName} onChange={handleInputChange} placeholder="Enter New Order ID" style={{ maxWidth: "100%", }} />
              </div>
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseUpdateModal}>
            Close
          </Button>
          {newPurchaseOrderName !== "" ?<Button variant="primary"  className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" onClick={handleReplaceOrder}> Continue</Button> : <Button variant="primary"  className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" disabled> Continue</Button>}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVerifyModal}
        onHide={handleCloseVerifyModal}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify New Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reduce_modal_body">
          <div className="row g-3">
          <div className="col-12">
                  <div className="my-2">
                    <label htmlFor="">Product Name: </label>
                    <span className="text-dark">  {verifyProductName} </span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Purchase Order Name: </label>
                    <span className="text-dark"> {verifyPurchaseOrderName}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Purchase Order Id: </label>
                    <span className="text-dark"> {verifyPurchaseOrderId}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Quantity: </label>
                    <span className="text-dark"> {verifyQuantity}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Amount: </label>
                    <span className="text-dark"> ${Number(verifyAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  </div>
                  <div className="my-2">
                    <label htmlFor="">Status: </label>
                    <span className="text-dark"> {verifyStatus}</span>
                  </div>
                </div>
              </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseVerifyModal}>
            Close
          </Button>
          {verifyStatus === "Completed" ?<Button variant="primary"  className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" onClick={handleRetry}>Replace</Button> : <Button variant="primary"  className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" disabled>Replace</Button> }
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >

        <Modal.Body>
          <Col>
            <img className="modal-img"
              src="/assets/images/exclamation.svg"
              // className="img-fluid"
              alt=""
            />
            <br />
            <br />
            <h5 className="modal-text">This gift card is stuck in the GCIP cart, would you like to clear the cart to process the orders further?</h5>
            <br />
            <Row className="modal-row">
              <Col md={1}></Col>
              <Col md={4}>
                <Button className="btn btn-primary primary-btn"
                 onClick={() => deleteGcipCart()}
                 >

                  <span><b>Clear Cart</b></span>
                </Button>
              </Col>
              <Col className="middle-col" md={2}></Col>
              <Col md={4}>
                <Button className="btn btn-danger danger-btn"
                 onClick={handleClose}
                  >
                  <span><b>Cancel</b></span>
                </Button>
              </Col>
              <Col md={1}></Col>

            </Row>
            <br />
          </Col>
        </Modal.Body>

      </Modal>

      <Modal
        show={secondShow}
        onHide={handleCloseNextModal}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
      >
        <Modal.Body>
          <Col className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256">
              <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                <g transform="scale(5.33333,5.33333)">
                  <path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#4caf50"></path>
                  <path d="M34.602,14.602l-13.602,13.597l-5.602,-5.598l-2.797,2.797l8.399,8.403l16.398,-16.402z" fill="#ccff90"></path>
                </g>
              </g>
            </svg>
            <br />
            <br />
            < h4 className="modal-text">Cleared the cart and other orders has been processed.</  h4>
            <br />
            <Row className="modal-row">
              <Col className="text-center">
                <Button className="btn btn-primary text-align-center fullRounded px-4 py-2 fw-bold font12 primary-btn"
                  style={{ maxWidth: "150px" }}
                  onClick={() => handleCloseNextModal()}
                >
                  <span style={{ fontWeight: 'bolder', fontSize: 'larger' }}> Ok </span>
                </Button>
              </Col>
            </Row>
            <br />
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GiftId;

