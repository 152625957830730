import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
    Button,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
const Reminders = () => {
    useEffect(() => {
        const adminCheck = window.location.pathname;
        if (adminCheck.includes('admin')) {
            document.title = "ncentiva || Reminders"
        }
    }, [])


    let token;
    let id;
    let partner_id;
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
        token = user.token;
        id = user.user._id
        partner_id = user?.user?.business_partner_id;
    } else {
        token = null;
        id = null;
        partner_id = null;
    }
    const [selectedValue, setSelectedValue] = useState('');
    const [reminderFund, setreminderFund] = useState('');

    function getBusinessData() {
        axios.get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinesspartners/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            setreminderFund(response.data.businessPartnes.funds_reminder)
        })
    }

    useEffect(() => {
        getBusinessData()
    }, [])

    function deleteReminder() {
        axios.delete(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/delete-reminder/${partner_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            if (response.data.status === "success") {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
            getBusinessData();
        }).catch((error) => {
            console.log(`Error : ${error}`);
        })
    }


    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleSave = () => {
        if (!selectedValue) {
            toast.warning("Please select a margin amount");
            return;
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/set-reminder/${partner_id}`, { amount: selectedValue })
            .then(response => {
                toast.success(response.data.message);
                getBusinessData();
            })
            .catch(error => {
                console.error('Error saving data:', error);
            });
    };

    return (
        <>
            <div className="p-3 p-lg-5">
                <div className="d-flex mb-4 align-items-center">
                    <Link
                        to="/admin/businesspartners"
                        className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
                        variant="white"
                    >
                        <span className="me-2 fw-semibold">
                            <img src="/assets/images/back-org.png" className="img-fluid" alt="" />
                        </span>
                        <span>Back</span>
                    </Link>
                    <h1 className="font20 text-dark">Reminders</h1>
                </div>

                <Container fluid className="mt-4 bg-white p-4 rounded shadow">
                    <Row>
                        <Col md={4}>
                            <h1 className="font20 mb-4 text-dark">Set a Reminder</h1>
                        </Col>
                        <Col md={4}></Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <h1 className="font16">Set a reminder with a minimum margin, that we will send to your email in case your balance is low.</h1>
                        </Col>
                    </Row>
                    <div className="mt-5 ms-3 d-flex align-items-center justify-content-between">
                        <div>
                            <h5>Margin Amount</h5>
                            <div className="d-flex align-items-center mt-3 mb-2">
                                <label htmlFor="amount" className="me-3 flex-shrink-0">Amount</label>
                                <div className="position-relative" style={{minWidth: "180px"}}>
                                    <select className="form-control" id="amount" value={selectedValue} onChange={handleChange}>
                                        <option value="">Select an Amount</option>
                                        <option value="100">$ 100.00</option>
                                        <option value="500">$ 500.00</option>
                                        <option value="1000">$ 1,000.00</option>
                                    </select>
                                    <i className="fas fa-chevron-down position-absolute top-50 translate-middle-y" style={{ right: '6px' }}></i>
                                </div>
                            </div>

                            {selectedValue && <p>Selected Amount : ${Number(selectedValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>}
                        </div>
                        <Button variant="primary" className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 mt-5" style={{ minWidth: "140px" }} onClick={handleSave}>Save</Button>
                    </div>
                </Container>
            </div>
            {reminderFund > 0 ?
                <div className="p-3 p-lg-5">
                    <Container fluid className=" bg-white p-4 rounded shadow">
                        <h1 className="font20 mb-4 text-dark">
                            Margin Balance: ${Number(reminderFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            <span>

                                <Link className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font14 " style={{ minWidth: "140px" }} onClick={deleteReminder}>
                                    Withdraw Reminder
                                </Link>
                            </span>
                        </h1>
                        <p className="font16">This is your current reminder balance. Notifications will be triggered if your balance falls below the set margin.</p>
                    </Container>
                </div>
                : ""}
        </>

    )
}

export default Reminders
