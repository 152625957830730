import axios from "axios";
import { SET_CUS_DATA_REQUEST,SET_CUS_DATA_SUCCESS,SET_CUS_DATA_FAILURE } from "./actionType";


export const cus_req = () => ({
    type: SET_CUS_DATA_REQUEST
  });
  
  export const cus_succ = (data) => ({
    type: SET_CUS_DATA_SUCCESS,
    payload: data
  });
  
  export const cus_fail = (err) => ({
    type: SET_CUS_DATA_FAILURE,
    payload: err
  });

  export const getcus = (id,token) => (dispatch)=>{
    dispatch(cus_req())
    return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customerfrontend/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => {
     dispatch(cus_succ(response.data));
    })
    .catch(error => {
    dispatch(cus_fail(error));
    })

  }