import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';



const SendPasswordResetEmail = () => {
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        document.title = "ncentiva || Verify"
    }, [])

    const navigate = useNavigate();



    const [email, setEmail] = useState("");
    const [stillSendingOtp, setStillSendingOtp] = useState(false)

    useEffect(() => {


    }, []);

    const handleClick = event => {
        setTimeout(() => setStillSendingOtp(true), 100) 
        
    //   setTimeout(() => setStillSendingOtp(false), 100)
    // setStillSendingOtp(false)
      };



      function keyDown(e) { 
        // console.log("err")
        var e = window.event || e;
        var key = e.keyCode;
        //space pressed
         if (key == 32) { //space
          e.preventDefault();
         }
               
      }
      
      function checkWhitespace(event)
      {
          var data = event.clipboardData.getData("text/plain");
          var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));
        
          if(isNullOrContainsWhitespace)
          {
              event.preventDefault(); 
          }
        
      }


    const handleSubmit = async (e) => {
      e.preventDefault();




      if (!stillSendingOtp) {
     
      try {
          
          const  {data}  = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/send-reset-password-email`, {
            email,
          });
          if(data.message === "Email doesn't exists"){
            toast.warning(data.message);
        }else if(data.message === "Email Field is Required"){
            toast.warning(data.message);
        } else{
            toast.success(data.message);
        }

        
          if (data.status === "success") {
            setStillSendingOtp(false)
            // toast(data.status);
              
          }else if(data.status === "failed"){
            setTimeout(() => setStillSendingOtp(false), 1500)

          }else{
          setStillSendingOtp(false)


          }
        } catch (err) {
            console.log(`Error : ${err}`);

        }








    }


    }


    return (
        <>
            <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#fff8f3' }}>
                <Container
                    className="bg-white loginContainer p-0 rounded"
                    style={{ maxWidth: "1065px" }}
                >
                    <Row className="align-items-center g-0">
                        <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                            <div className="">
                                <h4 className="text-dark">Send Email For Reset password</h4>
                                <p className="font16 text-dark">

                                </p>



                                <Form className="form mt-5" onSubmit={handleSubmit}>

                                    <Form.Group className="mb-3">

                                        <Form.Control
                                            className="font16"
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Enter Email Address"
                                            onKeyPress={(e)=>keyDown(e)}
                                            onPaste={(e)=>checkWhitespace(e)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <h5>Forgot Password ?</h5>
                                        <p style={{ fontSize: '12px', color: 'black' }}>Enter your registered Email and we will send you a reset password link.</p>
                                        <br />


{
    stillSendingOtp ?   
      <Button type="submit" variant="primary"className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary" disabled>Please wait ....</Button> :
      <Button type="submit"variant="primary" className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary" onClick={handleClick} >Send Mail</Button>
}
                                        {/* <Button
                                            type="submit"
                                            variant="primary"
                                            className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                        >
                                            
                                        </Button> */}

                                        {/* {stillSendingOtp ? 'Please wait ....' : 'Send Mail'} */}







                                    </Form.Group>

                                </Form>
                            </div>
                        </Col>
                        <Col
                            md={6}
                            className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                            style={{ height: "100%" }}
                        > <Link to="/admin" >
                            <img
                                src="/assets/images/logo.png"
                                className="imgLogo img-fluid"
                                alt=""
                                style={{width: '48%',height: '50%', marginLeft: '18%'}}
                            />
                            </Link>

                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <ToastContainer position="top-center" /> */}
        </>

    );
};

export default SendPasswordResetEmail;
