import React from 'react'
import PropTypes from 'prop-types';
import { FaAngleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import  axios from 'axios';
import "../../src/App.scss"
import { useSelector } from 'react-redux';
import { VscThreeBars } from "react-icons/vsc";
const InnerBanner =  ({ categories, selectedCategories, onCategorySelect , category}) => {
  const nameDomain = ( window.location.host.split('.')); 
  // const mydata = props.loadData;
  const [primaryColor,setPrimaryColor] = useState("");
  const [secondaryColor,setSecondaryColor] = useState("");
  const [videoUrl,setVideoUrl] = useState("");
  const [bannerColor,setBannerColor] = useState("");
  const [bannerColor1,setBannerColor1] = useState("");
  const [checkColor,setCheckColor] = useState();
  const [isLoading,setIsLoading] = useState(true);
  const cmsData = useSelector((state) => state.cmsReducer);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
    const backgroundStyle = {
      backgroundImage: `url(${videoUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: `${100}% ${100}%`,
      boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
    };
    const backgroundColor = {
      backgroundImage:`linear-gradient(93.59deg, ${bannerColor} -0.51%, ${bannerColor1} 105.82%)`
    }

    useEffect(() => {
      const data = cmsData.data?.cms;       
      if (data?.default_ncentiva === false) {
        setVideoUrl(data?.banner_image);
        setCheckColor(data?.banner_image_color);
        setBannerColor(data?.bannerColorCombination);
        setBannerColor1(data?.bannerColorCombination1);
        setPrimaryColor(data?.primary_color);
        setSecondaryColor(data?.secondary_color);
        setIsLoading(false);
      } else if(data?.default_ncentiva === true){
        setCheckColor(true);
        setBannerColor("#FF7020");
        setBannerColor1("#F5C400");
        setPrimaryColor("#FF7020");
        setSecondaryColor("#F5C400");
        setIsLoading(false);
      }
  }, [cmsData])

    // useEffect(()=>{
    //   if(nameDomain[0] !== "dev" || "staging" || "payouts" || "uat"){

    //     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
    //     .then((res)=>{
    //       if(res.data?.cms.default_ncentiva === false){ 
    //       setVideoUrl(res.data?.cms?.banner_image);
    //       setCheckColor(res.data?.cms?.banner_image_color);
    //       setBannerColor(res.data?.cms?.bannerColorCombination);
    //       setBannerColor1(res.data?.cms?.bannerColorCombination1);
    //       setPrimaryColor(res.data?.cms?.primary_color);
    //       setSecondaryColor(res.data?.cms?.secondary_color);
          
    //     }else{
    //         setCheckColor(true);
    //         setBannerColor("#FF7020");
    //         setBannerColor1("#F5C400");
    //         setPrimaryColor("#FF7020");
    //         setSecondaryColor("#F5C400");
        
    //       }
    //       setIsLoading(false);
    //     }).catch((err)=>{
    //       console.log(err);
    //     })
    //   }else{
    //     setIsLoading(false);
    //   }
    // },[])
    
const cat=categories?.map((item)=>{
  return item.name;
})
const catSort=cat.sort();

useEffect(()=>{
    if(!category){
      setSelectedCategory("")
    }
}, [category])

  const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        onCategorySelect(category);  
        setIsDropdownVisible(false);
    };
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const breakpoints = {
      desktop: 1200,
      laptop: 992,
      tablet: 768,
      mobile: 576
    };
    const getCategoryCount = (width) => {
      if (width >= breakpoints.desktop) return selectedCategories.length;
      if (width >= breakpoints.laptop) return 4;
      if (width >= breakpoints.tablet) return 3;
      return 0; // Return 0 for mobile screens
    };
    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);  
  return (
    <>
      {isLoading ? (
        " "
      ) : (
        <section
          style={checkColor ? backgroundColor : backgroundStyle}
          className="hero-search-modified inner-page position-relative banner_style"
        >
          <div
            className="container-fluid d-flex justify-content-start category_div align-items-center "
            style={{ height: "50px", color: "white" }}
          >
            {/* <Link to="/" replace  className="back-to-btn">
            <FaAngleLeft className="me-2"/> <span className="hide-on-mobile">Return</span>
        </Link> */}
            {/* <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
                <h2 className="mb-0">{props.bannerTitle}</h2>
            </div>
        </div> */}

            {/* <div className='d-flex newBar'><VscThreeBars className='three_bars' /> <h2 >All Categories</h2></div> */}

            {/* <div class="dropdown">
            <button data-mdb-button-init
              data-mdb-ripple-init data-mdb-dropdown-init className="bg-transparent dropdown-toggle border-0 "
              type="button"
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
              style={{padding: "14px 0px"}}
            >
              <VscThreeBars className='three_bars' /><span className="hide-on-mobile" style={{fontSize: "16px", fontWeight:600}}>All Categories</span>
            </button>
            <ul className="dropdown-menu overflow-auto" aria-labelledby="dropdownMenuButton">
             { categories?.map((category, index)=>{
                return <li key={index}><a className="dropdown-item"  href="#">{category}</a></li>
              })}
            </ul>
          </div> */}

            <div
              className="dropdown"
              style={{ padding: "15px 0px " }}
              onMouseEnter={() => setIsDropdownVisible(true)}
              onMouseLeave={() => setIsDropdownVisible(false)}
            >
              <VscThreeBars className="three_bars" />
              {/* <span className="hide-on-mobile" style={{fontSize: "16px", fontWeight: 600}}>All Categories</span> */}
              {isDropdownVisible && (
                <ul
                  className="dropdown-content"
                  style={{ marginTop: "7px", color: "black" }}
                >
                  {catSort?.map((category, index) => (
                    <li key={index}>
                      <a
                        className={`dropdown-item ${selectedCategory === category ? "selected-category" : ""}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCategoryClick(category)}
                      >
                        {category}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* <span className="hide-on-mobile" style={{fontSize: "16px", fontWeight: 600}}>All Categories</span> */}

            <div className="row px-3" style={{height:"100%"}}>
              <div
                 className={`col-auto category-item ${selectedCategory === "" && category !==""? "selected-category" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={() => handleCategoryClick("")}
              >
                <h2>All Categories</h2>
              </div>
              {getCategoryCount(screenWidth) > 0 && selectedCategories.slice(0, getCategoryCount(screenWidth)).map((category, index) => (
                  <div
                  key={index}
                  className={`col-auto category-item ${
                    category.name === "Clothing & Accessories"
                      ? "vanish_responsive"
                      : ""
                  } ${category.name === "Gaming" ? "vanish_responsive1" : ""}
                  ${selectedCategory === category.name && category !=="" ? "selected-category" : ""}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  <h2>{category.name}</h2>
                </div>
                ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

InnerBanner.propTypes = {
    bannerTitle: PropTypes.string,
}

export default InnerBanner
