import { Button, Col, Nav, Row } from "react-bootstrap";
import "../styles/leftsidebar.scss";
import { useNavigate, NavLink, Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import axios from "axios";
const BusinessLeftSidebar = (props) => {
    const [isToggled, setIsToggled] = useState(false);
    const user = JSON.parse(window.localStorage.getItem('client'));
    var userrole;

    let token;
    let id;
    const businessClient = JSON.parse(window.localStorage.getItem('business-client'))

    if (businessClient) {
        token = businessClient.token
        id = businessClient?.user?._id
    } else {
        token = null;
        id = null
    }

    if (user !== null) {
        userrole = user.user.role;
    } else {
        userrole = null;
    }

    const [role] = useState(userrole);
    const navigate = useNavigate();
    const logout = async () => {

        await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/logout`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then((res)=> {
            toast.success("You are Successfully Logged Out")
            setTimeout(() => {
                localStorage.removeItem('business-client');
                localStorage.removeItem('business_email');
                localStorage.removeItem('business');
                navigate('/business-login');
        
            }, 1000);
          }).catch((err)=> {
            console.log('Error :',err);
          })
    }

    let location = useLocation();



    const [show, setShow] = useState(false);
    const [buisnessAccount, setBuisnessAccount] = useState(false);
    const [customizeAccount, setCustomizeAccount] = useState(false);
    const [customerSection, setCustomerSection] = useState(false);
    const [createCustomerSection, setCreateCustomerSection] = useState(false);


    useEffect(() => {

        if (location.pathname === "/partner/apikey" || location.pathname === "/partner/contact" || location.pathname === "/partner/profile" || location.pathname === "/partner/acounthistory") {
            setBuisnessAccount(true);
        }
        if (location.pathname === "/partner/brands" || location.pathname === "/partner/customize") {
            setCustomizeAccount(true);
        }

        if (location.pathname === "/partner/customers/add" || location.pathname === "/partner/modify" ||

            location.pathname === "/partner/customer" || location.pathname === "/partner/transaction"

            || location.pathname === "/partner/monetary") {
            setCustomerSection(true);
        }

    }, []);

    const handlebuisnessAccount = () => {
        setBuisnessAccount(!buisnessAccount);
    }

    const handleCustomizeAccount = () => {
        setCustomizeAccount(!customizeAccount);
    }

    const handleCustomerAccount = () => {
        setCustomerSection(!customerSection);
    }
    const handleCreateCustomer = () => {
        setCreateCustomerSection(!createCustomerSection);
    }
    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);

    }
    // useEffect(() => {
    //     axios.get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/dashboard/details/${id}`, {
    //         headers: {
    //           'Authorization' : `Bearer ${token}`
    //         }
    //       })
    //     .then((response) => {
    //         if(response?.data?.message == "Please Login"){
    //             localStorage.removeItem('business-client');
    //             localStorage.removeItem('business');
    //             navigate('/business-login');
    //           }    
           
    //     })
    //     .catch((error) => {
    //         if (error?.response?.data?.message === "Unauthorized User" ){
    //             localStorage.removeItem('business-client');
    //             localStorage.removeItem('business');
    //             navigate('/business-login');
    //         } 
    //         console.error(`Error: ${error}`);
    //     });
    // }, [id, token])
    

    // Implementing auto logout by inactivity
    useEffect(() => {
        let timeoutId;
        function onUserActivity() {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                localStorage.removeItem('business-client');
                navigate('/business-login');
                toast.error("Session Expired. Please Login Again");
            }, 900000);
        }

        function resetTimeout() {
            onUserActivity();
        }

        // Events for desktop
        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keydown', resetTimeout);

        // Events for mobile
        window.addEventListener('touchstart', resetTimeout);
        window.addEventListener('touchmove', resetTimeout);
        window.addEventListener('touchend', resetTimeout);

        // Initialize timeout on component mount
        onUserActivity();

        // Cleanup event listeners and timeout on component unmount
        return () => {
            // Cleanup
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keydown', resetTimeout);
            window.removeEventListener('touchstart', resetTimeout);
            window.removeEventListener('touchmove', resetTimeout);
            window.removeEventListener('touchend', resetTimeout);
            clearTimeout(timeoutId);
        };
    }, [navigate]);

    useEffect(() => {
        // Define the Freshworks widget configuration
        window.fwSettings = {
          'widget_id': 153000001586
        };
    
        const loadScript = () => {
          // Create a script element
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.defer = true;
          script.src = 'https://widget.freshworks.com/widgets/153000001586.js';
    
          // Append the script to the document body
          document.body.appendChild(script);
        };
    
        // Load the script when the component mounts
        loadScript();
    
        // Clean up function to remove the script when the component unmounts
        return () => {
          document.body.removeChild(document.querySelector('script[src="https://widget.freshworks.com/widgets/153000001586.js"]'));
        };
      }, []);
      
    return (
        <div className={`leftSideBar bg-white position-fixed top-0 left-0 overflow-hidden  ${isToggled ? '' : 'active'}    ${props.toggleTwo ? 'toggle_sidebar' : ""}`}>
            <div className="logoWrapper p-0" style={{ width: '100%' }}>
                <Link to='/partner/dashboard'>
                    <img
                        src="/assets/images/logo.png"
                        alt=""
                        className={`img-fluid hide_images ${props.toggleTwo ? 'image_show_button' : ""}`}

                        style={{
                            width: '100%',
                            maxWidth: '170px',
                            marginTop: '10px',
                            marginLeft: '29px'
                        }}
                    />
                    <img
                        src="/assets/images/myimage.png"
                        alt="image"
                        className={`img-fluid visible_images ${props.toggleTwo ? 'image_show_button2' : ""}`}
                        style={{
                            height: '40px',
                            marginTop: '15px',
                            marginLeft: '10px',
                            width: '44px'
                        }}
                    />
                </Link>

            </div>
            <div className=" left_sidebar_scroll">

                <div>
                    <ul className={`list-unstyled menuItemWrapper mt-0 ${props.toggleTwo ? 'active' : ''}`} >
                        <li>
                            <Nav.Link
                                as={NavLink}
                                to="/partner/dashboard"
                                className={`d-flex align-items-center w-100 ${location.pathname === "/partner/dashboard" ? "active" : ""} `}
                            >
                                <span className="icon">
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.03125 1.875V5.15625C7.03073 5.65337 6.83308 6.12996 6.48153 6.48153C6.12997 6.83309 5.65337 7.03074 5.15625 7.03125H1.875C1.37788 7.03073 0.901286 6.83308 0.549723 6.48153C0.198161 6.12997 0.000508929 5.65337 0 5.15625V1.875C0.000523152 1.37788 0.198171 0.901286 0.549723 0.549723C0.901275 0.198161 1.37788 0.000508929 1.875 0H5.15625C5.65337 0.000523152 6.12996 0.198171 6.48153 0.549723C6.83309 0.901275 7.03074 1.37788 7.03125 1.875ZM13.125 0H9.84375C9.34663 0.000523152 8.87004 0.198171 8.51847 0.549723C8.16691 0.901275 7.96926 1.37788 7.96875 1.875V5.15625C7.96927 5.65337 8.16692 6.12996 8.51847 6.48153C8.87003 6.83309 9.34663 7.03074 9.84375 7.03125H13.125C13.6221 7.03073 14.0987 6.83308 14.4503 6.48153C14.8018 6.12997 14.9995 5.65337 15 5.15625V1.875C14.9995 1.37788 14.8018 0.901286 14.4503 0.549723C14.0987 0.198161 13.6221 0.000508929 13.125 0ZM5.15625 7.96875H1.875C1.37788 7.96927 0.901286 8.16692 0.549723 8.51847C0.198161 8.87003 0.000508929 9.34663 0 9.84375V13.125C0.000523152 13.6221 0.198171 14.0987 0.549723 14.4503C0.901275 14.8018 1.37788 14.9995 1.875 15H5.15625C5.65337 14.9995 6.12996 14.8018 6.48153 14.4503C6.83309 14.0987 7.03074 13.6221 7.03125 13.125V9.84375C7.03073 9.34663 6.83308 8.87004 6.48153 8.51847C6.12997 8.16691 5.65337 7.96926 5.15625 7.96875ZM13.125 7.96875H9.84375C9.34663 7.96927 8.87004 8.16692 8.51847 8.51847C8.16691 8.87003 7.96926 9.34663 7.96875 9.84375V13.125C7.96927 13.6221 8.16692 14.0987 8.51847 14.4503C8.87003 14.8018 9.34663 14.9995 9.84375 15H13.125C13.6221 14.9995 14.0987 14.8018 14.4503 14.4503C14.8018 14.0987 14.9995 13.6221 15 13.125V9.84375C14.9995 9.34663 14.8018 8.87004 14.4503 8.51847C14.0987 8.16691 13.6221 7.96926 13.125 7.96875Z"
                                            fill="#FF7020"
                                        />
                                    </svg>
                                </span>
                                <span>Dashboard</span>
                            </Nav.Link>
                        </li>

                        <li>
                            <Nav.Link
                                as={NavLink}
                                to="*"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlebuisnessAccount();
                                }}
                                className={`d-flex align-items-center justify-content-between  w-100  ${buisnessAccount ? "active_account" : ""} ${(location.pathname === "/partner/apikey") || (location.pathname === "/partner/contact")
                                    || (location.pathname === "/partner/profile") || (location.pathname === "/partner/acounthistory") ? "active" : ""

                                    }`}
                            >
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
                                        <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                                    </svg>

                                </span>
                                <span className="j_sidebar_main_text">Buisness Account</span>
                                <span className="icon down_icon_three" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </span>
                            </Nav.Link>
                            <div className="header_class">
                                <ul className={`content_class responsive_one ${buisnessAccount ? "active_buisness" : ""}`}>
                                    <li><Nav.Link
                                        as={NavLink}
                                        to="/partner/profile"
                                        className="d-flex align-items-center w-100"
                                    >
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge" viewBox="0 0 16 16">
                                                <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                                            </svg>

                                        </span>
                                        <span>Profile</span>
                                    </Nav.Link></li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/contact"
                                            className=" d-flex align-items-center w-100"
                                        >
                                            <span className="icon">
                                                <svg
                                                    width="17"
                                                    height="14"
                                                    viewBox="0 0 17 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.1632 5.30739H6.41456L5.62543 7.37905L5.30973 6.03749H5.34922C5.4479 6.03749 5.54658 5.93882 5.54658 5.84014V5.46524C5.54658 5.36656 5.4479 5.26788 5.34922 5.26788H4.87567C4.777 5.26788 4.67832 5.36656 4.67832 5.46524V5.84014C4.67832 5.93882 4.777 6.03749 4.87567 6.03749H4.89535L4.57965 7.37905L3.79052 5.30739H3.71166C1.91622 5.30739 1.20595 7.24087 0.831044 8.26685C0.771865 8.40503 0.732367 8.54306 0.692868 8.64176C0.475974 9.19419 0.219424 10.2597 0.0220906 11.0883C-0.0765866 11.5618 0.160266 12.0551 0.633679 12.2523C0.633679 12.2523 0.653359 12.2523 0.653359 12.272C1.22545 12.5087 1.87657 12.1733 2.03441 11.5617C2.21195 10.7134 2.44881 9.82546 2.58684 9.45056C2.64602 9.3322 2.68552 9.17435 2.7447 9.0165L2.80388 12.0352C2.82356 12.5481 3.19844 12.9625 3.69168 13.0217C4.14543 13.0808 4.59933 13.1005 5.07273 13.1005C5.54629 13.1005 6.00004 13.0808 6.45379 13.0217C6.96673 12.9625 7.34159 12.5481 7.34159 12.0352L7.51913 7.3592H8.80149C9.0978 6.70822 9.51219 5.89926 10.1631 5.30737L10.1632 5.30739Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M7.48018 2.38738C7.48018 3.70582 6.41139 4.77475 5.09294 4.77475C3.7745 4.77475 2.70557 3.70582 2.70557 2.38738C2.70557 1.06893 3.7745 0 5.09294 0C6.41139 0 7.48018 1.06893 7.48018 2.38738Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M16.9789 11.0882C16.7815 10.2399 16.5251 9.19408 16.3081 8.66149C16.2686 8.56281 16.2094 8.42477 16.1699 8.28658C15.795 7.2606 15.0848 5.32711 13.2893 5.32711H13.2105L12.4213 7.39878L12.1056 6.05722H12.1451C12.2438 6.05722 12.3425 5.95855 12.3425 5.85987V5.48497C12.3425 5.38629 12.2438 5.28761 12.1451 5.28761H11.6716C11.5729 5.28761 11.4742 5.38629 11.4742 5.48497V5.85987C11.4742 5.95855 11.5729 6.05722 11.6716 6.05722H11.6913L11.3755 7.39878L10.5864 5.32711H10.5076C8.71212 5.32711 8.00184 7.2606 7.62694 8.28658C7.56776 8.42476 7.52827 8.56279 7.48877 8.66149C7.27173 9.19423 6.99552 10.2596 6.81799 11.108C6.71931 11.5816 6.95616 12.0748 7.42958 12.272H7.44926C8.02135 12.5088 8.69227 12.1734 8.83031 11.5618C9.02766 10.7135 9.2447 9.82551 9.38274 9.45061C9.44192 9.33225 9.48142 9.1744 9.5406 9.01655L9.59978 12.0352C9.61946 12.5481 9.99434 12.9625 10.4876 13.0217C10.9413 13.0809 11.4149 13.1006 11.8686 13.1006C12.3422 13.1006 12.8156 13.0809 13.2695 13.0217C13.7628 12.9625 14.1573 12.5481 14.1573 12.0352L14.2165 9.01655C14.2757 9.17441 14.3349 9.33226 14.3744 9.45061C14.5322 9.82551 14.7493 10.7133 14.9465 11.5618C15.0846 12.1734 15.7356 12.5088 16.3275 12.272H16.3472C16.821 12.0549 17.0774 11.5616 16.9789 11.0882L16.9789 11.0882Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M14.2961 2.38738C14.2961 3.70582 13.2272 4.77475 11.9087 4.77475C10.5903 4.77475 9.52148 3.70582 9.52148 2.38738C9.52148 1.06893 10.5903 0 11.9087 0C13.2272 0 14.2961 1.06893 14.2961 2.38738Z"
                                                        fill="#333333"
                                                    />
                                                </svg>
                                            </span>
                                            <span>Users</span>
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/acounthistory"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                                                </svg>
                                            </span>
                                            <span>Funding History</span>
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/apikey"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ui-radios" viewBox="0 0 16 16">
                                                    <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM0 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm7-1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM3 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                </svg>
                                            </span>
                                            <span>Api Keys</span>
                                        </Nav.Link>
                                    </li>
                                    {/* <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/reminder"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">
                                                <svg
                                                    viewBox="0 0 21 21"
                                                    fill="currentColor"
                                                    height="1em"
                                                    width="1em"
                                                    {...props}
                                                >
                                                    <path
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15.585 15.5H5.415A1.65 1.65 0 014 13a10.526 10.526 0 001.5-5.415V6.5a4 4 0 014-4h2a4 4 0 014 4v1.085c0 1.907.518 3.78 1.5 5.415a1.65 1.65 0 01-1.415 2.5zm1.915-11c-.267-.934-.6-1.6-1-2s-1.066-.733-2-1m-10.912 3c.209-.934.512-1.6.912-2s1.096-.733 2.088-1M13 17c-.667 1-1.5 1.5-2.5 1.5S8.667 18 8 17"
                                                    />
                                                </svg>
                                            </span>
                                            <span>Reminder</span>
                                        </Nav.Link>
                                    </li> */}
                                </ul>
                            </div>
                        </li>





                        <li>
                            <Nav.Link
                                as={NavLink}
                                to="*"
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleCustomizeAccount()
                                }}
                                className={`d-flex align-items-center justify-content-between ${customizeAccount ? "active_customize" : ""}  w-100   ${(location.pathname === "/partner/brands") || (location.pathname === "/partner/customize") ? "active" : ""} `}
                            >
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ticket-perforated-fill" viewBox="0 0 16 16">
                                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4-1v1h1v-1H4Zm1 3v-1H4v1h1Zm7 0v-1h-1v1h1Zm-1-2h1v-1h-1v1Zm-6 3H4v1h1v-1Zm7 1v-1h-1v1h1Zm-7 1H4v1h1v-1Zm7 1v-1h-1v1h1Zm-8 1v1h1v-1H4Zm7 1h1v-1h-1v1Z" />
                                    </svg>
                                </span>
                                <span className="j_sidebar_main_text">Customize Account</span>

                                <span className="icon down_icon_two" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </span>
                            </Nav.Link>
                            
                                <div className="header_class">
                                    <ul className={`content_class responsive_two ${customizeAccount ? "active_buisness" : ""}`}>
                                        <li>
                                            <Nav.Link
                                                as={NavLink}
                                                to="/partner/brands"
                                                className="d-flex align-items-center w-100"
                                            >
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ticket-perforated-fill" viewBox="0 0 16 16">
                                                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4-1v1h1v-1H4Zm1 3v-1H4v1h1Zm7 0v-1h-1v1h1Zm-1-2h1v-1h-1v1Zm-6 3H4v1h1v-1Zm7 1v-1h-1v1h1Zm-7 1H4v1h1v-1Zm7 1v-1h-1v1h1Zm-8 1v1h1v-1H4Zm7 1h1v-1h-1v1Z" />
                                                    </svg>
                                                </span>
                                                <span>Brands</span>
                                            </Nav.Link>
                                        </li>
                                        <li>
                                            <Nav.Link
                                                as={NavLink}
                                                to="/partner/customize"
                                                className="d-flex align-items-center w-100 "
                                            >
                                                <span className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable-circle-fill" viewBox="0 0 16 16">
                                                        <path d="M6.705 8.139a.25.25 0 0 0-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 0 0 .287.376l.808-.27-.595.894a.25.25 0 0 0 .287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 0 0-.288-.376l-.808.27.596-.894Z" />
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm-6.202-4.751 1.988-1.657a4.5 4.5 0 0 1 7.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.49 4.49 0 0 1-1.592-.29L4.747 14.2a7.031 7.031 0 0 1-2.949-2.951ZM12.496 8a4.491 4.491 0 0 1-1.703 3.526L9.497 8.5l2.959-1.11c.027.2.04.403.04.61Z" />
                                                    </svg>
                                                </span>
                                                <span>Branding/Emails</span>
                                            </Nav.Link>
                                        </li>
                                    </ul>
                                </div>
                            
                        </li>


                        <li>
                            <Nav.Link
                                as={NavLink}
                                to="*"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCustomerAccount();
                                }}
                                className={`d-flex align-items-center justify-content-between  w-100 ${customerSection ? "active_customers" : ""} ${(location.pathname === "/partner/customers/add") || (location.pathname === "/partner/modify")
                                    || (location.pathname === "/partner/customer") || (location.pathname === "/partner/transaction") || (location.pathname === "/partner/monetary")
                                    ? "active" : ""
                                    }`}
                            >
                                <span className="icon">
                                    <svg
                                        width="18"
                                        height="15"
                                        viewBox="0 0 18 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.8419 4.13715C11.0215 4.36869 11.2393 4.56915 11.471 4.71431C11.7924 4.92166 12.1311 5.02885 12.4801 5.02885C13.7727 5.02885 14.7784 3.56347 14.7784 2.30532C14.7784 1.0335 13.7484 0 12.4801 0C11.2083 0 10.1748 1.03337 10.1748 2.30532C10.1748 2.91708 10.4168 3.59104 10.8211 4.11294C10.8246 4.11645 10.8349 4.12673 10.8419 4.13714L10.8419 4.13715Z"
                                            fill="#333333"
                                        />
                                        <path
                                            d="M8.92542 8.42738C8.73712 8.60252 8.53966 8.75621 8.33243 8.88221C7.80992 9.2207 7.23587 9.39759 6.64253 9.39759C5.76449 9.39759 4.97882 9.017 4.35024 8.43117C1.815 9.36727 0 11.8003 0 14.6544C0 14.8479 0.155453 15 0.345644 15H12.9365H12.9434C13.1369 15 13.289 14.8479 13.289 14.6544C13.289 14.6302 13.289 14.606 13.2821 14.5853C13.2524 11.7544 11.4435 9.35249 8.92545 8.42725L8.92542 8.42738Z"
                                            fill="#333333"
                                        />
                                        <path
                                            d="M14.4754 4.77515C13.9546 5.33992 13.2638 5.72026 12.4803 5.72026C11.9992 5.72026 11.5335 5.5771 11.0965 5.29528C10.8784 5.15851 10.6675 4.98012 10.4788 4.7754C10.4648 4.7818 10.4516 4.79007 10.4376 4.79672C10.4413 4.86604 10.4481 4.93449 10.4481 5.00482C10.4481 5.96537 10.0721 7.02958 9.44132 7.85923C9.43455 7.86938 9.42816 7.87527 9.42139 7.88455C10.3315 8.2594 11.1414 8.80736 11.8162 9.48408L16.9351 9.48395C17.1251 9.48395 17.2807 9.32837 17.2807 9.13831C17.2807 7.20382 16.1287 5.53552 14.4753 4.77523L14.4754 4.77515Z"
                                            fill="#333333"
                                        />
                                        <path
                                            d="M6.64284 8.70657C7.10254 8.70657 7.55185 8.56491 7.97346 8.29186C8.29138 8.0983 8.58522 7.82876 8.84437 7.50044C8.85477 7.49004 8.86505 7.47963 8.87208 7.46584C9.42504 6.75039 9.75689 5.83108 9.75689 5.00506C9.75689 3.28733 8.36057 1.89102 6.64285 1.89102C4.92512 1.89102 3.52881 3.28733 3.52881 5.00506C3.52881 6.71579 4.89052 8.7067 6.64285 8.7067L6.64284 8.70657Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                </span>
                                <span className="j_sidebar_main_text">Customers</span>
                                <span className="icon down_icon" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </span>
                            </Nav.Link>
                            <div className="header_class">
                                <ul className={`content_class responsive_three ${customerSection ? "active_buisness" : ""} `
                                }>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="*"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleCreateCustomer();
                                            }}

                                            className={`d-flex align-items-center justify-content-between w-100" ${createCustomerSection ? "active_new_customer" : ""} ${(location.pathname === "/partner/customers/add") ? 'active' : ''}`}
                                        >
                                            <span className="icon">
                                                <svg
                                                    width="18"
                                                    height="15"
                                                    viewBox="0 0 18 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.8419 4.13715C11.0215 4.36869 11.2393 4.56915 11.471 4.71431C11.7924 4.92166 12.1311 5.02885 12.4801 5.02885C13.7727 5.02885 14.7784 3.56347 14.7784 2.30532C14.7784 1.0335 13.7484 0 12.4801 0C11.2083 0 10.1748 1.03337 10.1748 2.30532C10.1748 2.91708 10.4168 3.59104 10.8211 4.11294C10.8246 4.11645 10.8349 4.12673 10.8419 4.13714L10.8419 4.13715Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M8.92542 8.42738C8.73712 8.60252 8.53966 8.75621 8.33243 8.88221C7.80992 9.2207 7.23587 9.39759 6.64253 9.39759C5.76449 9.39759 4.97882 9.017 4.35024 8.43117C1.815 9.36727 0 11.8003 0 14.6544C0 14.8479 0.155453 15 0.345644 15H12.9365H12.9434C13.1369 15 13.289 14.8479 13.289 14.6544C13.289 14.6302 13.289 14.606 13.2821 14.5853C13.2524 11.7544 11.4435 9.35249 8.92545 8.42725L8.92542 8.42738Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M14.4754 4.77515C13.9546 5.33992 13.2638 5.72026 12.4803 5.72026C11.9992 5.72026 11.5335 5.5771 11.0965 5.29528C10.8784 5.15851 10.6675 4.98012 10.4788 4.7754C10.4648 4.7818 10.4516 4.79007 10.4376 4.79672C10.4413 4.86604 10.4481 4.93449 10.4481 5.00482C10.4481 5.96537 10.0721 7.02958 9.44132 7.85923C9.43455 7.86938 9.42816 7.87527 9.42139 7.88455C10.3315 8.2594 11.1414 8.80736 11.8162 9.48408L16.9351 9.48395C17.1251 9.48395 17.2807 9.32837 17.2807 9.13831C17.2807 7.20382 16.1287 5.53552 14.4753 4.77523L14.4754 4.77515Z"
                                                        fill="#333333"
                                                    />
                                                    <path
                                                        d="M6.64284 8.70657C7.10254 8.70657 7.55185 8.56491 7.97346 8.29186C8.29138 8.0983 8.58522 7.82876 8.84437 7.50044C8.85477 7.49004 8.86505 7.47963 8.87208 7.46584C9.42504 6.75039 9.75689 5.83108 9.75689 5.00506C9.75689 3.28733 8.36057 1.89102 6.64285 1.89102C4.92512 1.89102 3.52881 3.28733 3.52881 5.00506C3.52881 6.71579 4.89052 8.7067 6.64285 8.7067L6.64284 8.70657Z"
                                                        fill="#333333"
                                                    />
                                                </svg>
                                            </span>

                                            <span className="j_sidebar_main_text">Create new customers</span>
                                            <span className="icon sub_icon" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            </span>
                                        </Nav.Link>
                                        <div className="header_class">
                                            <ul className={`ps-0 content_class responsive_three ${createCustomerSection ? "active_buisness" : ""}`}>
                                                <li>
                                                    <Nav.Link
                                                        as={NavLink}
                                                        to="/partner/customers/add"

                                                        className="d-flex align-items-center w-100"
                                                    >
                                                        <span className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                            </svg>
                                                        </span>
                                                        <span>Add Customer</span>
                                                    </Nav.Link>
                                                </li>
                                                <li>
                                                    <Nav.Link
                                                        as={NavLink}
                                                        to="/partner/Upload"

                                                        className="d-flex align-items-center w-100"
                                                    >
                                                        <span className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-csv" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z" />
                                                            </svg>
                                                        </span>
                                                        <span>Upload CSV</span>
                                                    </Nav.Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/modify"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                    <path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z" />
                                                </svg>

                                                {/* <img src="assets/images/bitcoin.png" alt="...."  className="img-fluid"/> */}
                                            </span>
                                            <span>Modify Funds</span>
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/customer"
                                            className="d-flex align-items-center w-100"
                                        >
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z" />
                                                </svg>
                                            </span>
                                            <span>Customer List</span>
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/transaction"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                    <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                                </svg>
                                            </span>
                                            <span>Purchase Transactions</span>
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/partner/monetary"
                                            className="d-flex align-items-center w-100 "
                                        >
                                            <span className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                                                </svg>
                                            </span>
                                            <span>Monetary Transactions</span>
                                        </Nav.Link>
                                    </li>
                                </ul>
                            </div>
                        </li>






                        <li>
                            <Nav.Link
                                onClick={() => handleShow()}
                                className="d-flex align-items-center w-100 "
                            // to="/admin"
                            >
                                <span className="icon">
                                    <svg
                                        width="12"
                                        height="15"
                                        viewBox="0 0 12 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.68426 7.60306H5.04378C3.70605 7.60306 2.42323 8.13447 1.47726 9.08032C0.531294 10.0263 0 11.3093 0 12.647V13.4649C0 13.872 0.161691 14.2625 0.449565 14.5504C0.737459 14.8383 1.12783 15 1.53506 15H10.1931C10.6003 15 10.9907 14.8383 11.2786 14.5504C11.5665 14.2625 11.7282 13.872 11.7282 13.4649V12.647C11.7282 11.3093 11.1969 10.0263 10.2509 9.08032C9.30494 8.13451 8.02211 7.60306 6.68438 7.60306H6.68426Z"
                                            fill="#333333"
                                        />
                                        <path
                                            d="M5.86368 6.95181C6.78564 6.95181 7.66997 6.58544 8.3218 5.93347C8.97363 5.28153 9.33982 4.3972 9.33966 3.47524C9.33935 2.55312 8.97284 1.66911 8.3207 1.01728C7.6686 0.365605 6.78427 -0.000430388 5.86215 3.79781e-07C4.94019 0.000306303 4.05618 0.366979 3.4045 1.01939C2.75299 1.67165 2.38723 2.55614 2.3877 3.4781C2.38953 4.39916 2.75636 5.28207 3.40787 5.93312C4.05939 6.58432 4.9425 6.95052 5.86368 6.95177V6.95181Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                </span>
                                <span>Logout</span>
                            </Nav.Link>
                        </li>
                    </ul>
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                >

                    <Modal.Body>
                        <Col>
                            <img className="modal-img"
                                src="/assets/images/exclamation.svg"
                                // className="img-fluid"
                                alt=""
                            />
                            <br />
                            <br />
                            <h3 className="modal-text">Are you sure, you want to Log out?</h3>
                            <br />
                            <Row className="modal-row">
                                <Col md={1}></Col>
                                <Col md={4}>
                                    <Button className="btn btn-danger danger-btn" onClick={logout}>

                                        <span><b>Yes, Logout</b></span>
                                    </Button>
                                </Col>
                                <Col className="middle-col" md={2}></Col>
                                <Col md={4}>
                                    <Button className="btn btn-success success-btn" onClick={handleClose} >
                                        <span><b>Cancel</b></span>
                                    </Button>
                                </Col>
                                <Col md={1}></Col>

                            </Row>
                            <br />
                        </Col>
                    </Modal.Body>

                </Modal>
                {/* 
            <Button
                onClick={() => setIsToggled(!isToggled)}
                variant="white"
                className={`sideToggler fullRounded d-flex d-md-none justify-content-center align-items-center p-2  ${isToggled ? 'rotate' : ''}`}
                type="button"
                aria-label="Click to expand sidebar"
                style={{ width: "35px", height: "35px" }}
            >
                <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 76 76"
                    enableBackground="new 0 0 76.00 76.00"
                >
                    <path
                        fill="#000000"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 35.8724,37.6042L 39.0391,40.7708L 50.5182,51.8542L 40.2266,51.8542L 25.1849,37.6041L 40.2266,23.3542L 50.5182,23.3542L 39.0391,34.4375L 35.8724,37.6042 Z "
                    />
                </svg>
            </Button> */}
            </div>
            <Button
                onClick={() => { props.setToggleTwo(!props.toggleTwo); }}


                variant="white"
                className={` new_close_button  d-flex  d-xl-none justify-content-center align-items-center ${props.toggleTwo ? 'rotate' : ""} `}
                type="button"
                aria-label="Click to expand sidebar"
            >
                <svg
                    width="25px"
                    height="25px"
                    viewBox="0 0 76 76"
                    enableBackground="new 0 0 76.00 76.00"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        strokeWidth="0.2"
                        strokeLinejoin="round"
                        d="M 35.8724,37.6042L 39.0391,40.7708L 50.5182,51.8542L 40.2266,51.8542L 25.1849,37.6041L 40.2266,23.3542L 50.5182,23.3542L 39.0391,34.4375L 35.8724,37.6042 Z "
                    />
                </svg>
            </Button>
            <div id="freshworks-chat-widget-container"></div>
        </div>
    );
};

export default BusinessLeftSidebar;
