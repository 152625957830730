import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import { TbRefresh } from "react-icons/tb";

const BusinessTrash = () => {
  const user = JSON.parse(window.localStorage.getItem("client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [creditOrder, setCreditOrder] = useState("ASC");
  const [cityorder, setCityorder] = useState("ASC");
  const [phoneorder, setPhoneorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [customerOrder, setCustomerOrder] = useState("ASC");
  const [brandOrder, setBrandOrder] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const shouldLog = useRef(true);
  const [sort, setSort] = useState("");
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPage, setShowPage] = useState(1);
  const [clicked,setClicked] = useState(true)
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }

  const getAllUsers = (pageNumber) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/businesstrash?page=${pageNumber}&search=${search}&sort=${sort}`,
        {
          soft_delete: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem("client");
          localStorage.removeItem("user");
          navigate("/admin");
          toast.warning("Session Expired Please Login Again");
          // window.location.reload();
        }
        const businessPartner = response.data.businessPartner;
        if (businessPartner.length < 1) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        const totalPages = response.data.totalPages;
        //const slice = businessPartner.slice(offset - 1 , offset - 1 + postsPerPage)
        setCustomerData(businessPartner);
        setNumberOfPages(totalPages);
        //setPageCount(Math.ceil(businessPartner.length / postsPerPage))
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {
    items.push(
      <li
        key={number}
        className={number === pageNumber ? "active" : ""}
        onClick={() => {
          setPageNumber(number);
          setShowPage(number + 1);
        }}
      >
        {number + 1}
      </li>
    );
  }

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    } else {
      setPageNumber(0);
      setShowPage(pages);
    }
  };

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  };

  // name

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   creditOrder

  const creditSorting = (creditOrder) => {
    if (creditOrder === "DSC") {
      setSort("dscCreditAmount");

      setCreditOrder("DSC");
    } else {
      setSort("ascCreditAmount");

      setCreditOrder("ASC");
    }
  };

  //   type of user

  const citysorting = (cityorder) => {
    if (cityorder === "DSC") {
      setSort("dscCity");

      setCityorder("DSC");
    } else {
      setSort("ascCity");

      setCityorder("ASC");
    }
  };

  //   phone
  const phonesorting = (col) => {
    if (phoneorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("DSC");
    }
    if (phoneorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("ASC");
    }
  };

  //   status

  const statussorting = (col) => {
    if (statusorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("DSC");
    }
    if (statusorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("ASC");
    }
  };

  const customerSorting = (customerOrder) => {
    if (customerOrder === "DSC") {
      setSort("dscCustomerCount");

      setCustomerOrder("DSC");
    } else {
      setSort("ascCustomerCount");

      setCustomerOrder("ASC");
    }
  };

  const brandSorting = (brandOrder) => {
    if (brandOrder === "DSC") {
      setSort("dscBrandCount");

      setBrandOrder("DSC");
    } else {
      setSort("ascBrandCount");

      setBrandOrder("ASC");
    }
  };

  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };
  const deleteUser = (id) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletebusinesspartner/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        getAllUsers(pageNumber);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const userID = localStorage.getItem("client");
  const users = JSON.parse(userID);
  const userIds = users.user._id;
  
  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber);
      redirectToLogin();
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(getData);
  }, [pageNumber, search, sort, userIds]);

  const softDelete = async (e) => {
    const { name, checked, value } = e.target;
    if (name === "allSelect") {
      let tempUser = customerData.map((user) => {
        return { ...user, isChecked: checked };
      });
      setCustomerData(tempUser);
      localStorage.setItem("buss_retrive_Del", "All");
      
    } else {
      localStorage.setItem("buss_retrive_Del", "id");
      let tempUser = customerData.map((user) =>
        user.business_name === name ? { ...user, isChecked: checked } : user
        );
        setCustomerData(tempUser);
        const user = tempUser.filter((e) => e.isChecked);
        localStorage.setItem("buss_unchecke", JSON.stringify(user));
      }
      if(checked === false) {
        await localStorage.removeItem("buss_retrive_Del");
      }
    };
    
    const retriveFromTrash = async () => {
      const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    const result = localStorage.getItem("buss_retrive_Del");
    if (result === "All") {
      // Move all Partner to trash
      const isDeleted = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
        {
          soft_delete: false,
          type: "All",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
        if (isDeleted.data.partenerStatus.acknowledged === true) {
          toast.success(isDeleted.data.message);
          localStorage.setItem("buss_retrive_Del", " ");
        } else {
          toast.error("Failed to retrive");
        }
        navigate("/admin/businesspartners");
      } else if (result === "id") {
        // Move selected Partner to trash
        const store = JSON.parse(localStorage.getItem("buss_unchecke"));
        try {
          await store.map((e) =>
          axios.put(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
            {
              soft_delete: false,
              type: "id",
              id: e._id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        );
        toast("Business Partner Retrive Successfully");
        // navigate("/admin/businesspartners");
        localStorage.setItem("buss_retrive_Del", " ");
      } catch (error) {
        toast.error("Failed to retrive selected Partner");
      }
    } else {
      toast.warning("Please Select Any Input");
    }
  };

  const handleDelete = async () => {
    handleClose(); // Close the modal

    // Your axios code with the stored itemId
    if (itemId) {
      const user = JSON.parse(window.localStorage.getItem("client"));
      let token;

      if (user) {
        token = user.token;
      } else {
        token = null;
      }

      try {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletebusinesspartner/${itemId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        toast.success("Deleted Business Partner Successfully");
        localStorage.setItem("buss_retrive_Del", " ");
        navigate("/admin/businesspartners");
      } catch (error) {
        toast.error("Failed to Delete selected Partner");
      }
    }
  };

  const retriveTrashDirectly = async (userId,brandId) => {
    setTimeout(() => {
      setClicked(true)
    }, 3000);
    if(clicked==false){
      return toast.warning("Please Wait")
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    
    try {
      setClicked(false)
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/buisness/soft-delete`,
        {
          soft_delete: false,
          type: "id",
          id: userId,
          status:true,
          brandId:brandId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response)=> {

        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      
    } catch (error) {
      console.log(error);
    }
  };



  const [show, setShow] = useState(false);
const [itemId, setItemId] =useState('');

  const handleClose = () => {
      setShow(false);
    } 

    const handleOpen = (id) => {
      setShow(true);
      setItemId(id);
    } 
    

  return (
    <div>
      <div className="p-3 p-lg-4 p-xl-5">
        <div className="d-flex mb-4 align-items-center">
          <Link
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              />
            </span>
            <span>Back</span>
          </Link>
          <h1 className="font20 text-dark">Business Partner Trashlist</h1>
        </div>
        <Container fluid className="bg-white p-3 rounded shadow">
          <Row className="align-items-center">
            <Container>
              <Row>
                <Col sm={6} className="mt-1">
                  <div className="textGray font12">
                    <div style={{ maxWidth: "" }}>
                      <div className="d-flex align-items-center searchWrapper w-100 ms-sm-0 mx-auto justify-content-center justify-content-sm-start">
                        <Form.Control
                          type="text"
                          className="border-0 searchInput"
                          placeholder="Search"
                          onChange={({ currentTarget: input }) =>
                            handleSearch(input.value)
                          }
                        />
                        <Button
                          variant="primary"
                          title="Click here to search"
                          className="searchBtn d-flex justify-content-center align-items-center"
                        >
                          <img
                            src="/assets/images/search.png"
                            className="img-fluid"
                            alt=""
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
                <Col sm={6} className="mt-1">
                  <div className="d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0">

                    <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                  >

                    <Modal.Body>
                      <Col>
                        <img className="alert-img"
                          src="/assets/images/iccon.png"
                          alt=""
                        />
                        <br />
                        <br />
                        <h3 className="modal-text">Are you sure you want to delete this Business partner Permanently?</h3>
                        <br />
                        <Row className="modal-row">
                          <Col md={1}></Col>
                          <Col md={4}>
                            <Button className="btn btn-danger danger-btn" onClick={handleDelete}>

                              <span><b>Continue</b></span>
                            </Button>
                          </Col>
                          <Col className="middle-col" md={2}></Col>
                          <Col md={4}>
                            <Button className="btn btn-success success-btn" onClick={handleClose} >
                              <span><b>Cancel</b></span>
                            </Button>
                            
                          </Col>
                          <Col md={1}></Col>

                        </Row>
                        <br />
                      </Col>
                    </Modal.Body>

                  </Modal>
                  </div>
                  {/* <Button
                      variant="success"
                      onClick={retriveFromTrash}
                      className="fullRounded px-4 py-2 fw-bold font12"
                      style={{ width: "50px" , display: "flex",justifyContent:"center"}}
                    >
                      <span style={{ cursor: "pointer"}}>
                      <TbRefresh style={{ height: "25px" ,width:"25px" }} />
                      </span>
                    </Button> */}
                </Col>
              </Row>
            </Container>
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table
                  className="table fontGilroy"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead className="bgLightRed font14">
                    <tr>

                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1">Business Name</span>
                          {order === "ASC" ? (
                            <FaSortDown onClick={() => sorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => sorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1">Business Partner ID</span>
                        </div>
                      </th>

                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1">Phone</span>
                        </div>
                      </th>

                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1">Funds</span>
                          {creditOrder === "ASC" ? (
                            <FaSortDown onClick={() => creditSorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => creditSorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span>Customers</span>
                          {customerOrder === "ASC" ? (
                            <FaSortDown
                              onClick={() => customerSorting("DSC")}
                            />
                          ) : (
                            <FaSortUp onClick={() => customerSorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1 ps-lg-6">Brands</span>
                          {brandOrder === "ASC" ? (
                            <FaSortDown onClick={() => brandSorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => brandSorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="text-center">
                        <div className="d-flex align-items-center">
                          <span className="me-1">City</span>
                          {cityorder === "ASC" ? (
                            <FaSortDown onClick={() => citysorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => citysorting("ASC")} />
                          )}
                        </div>
                      </th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font12 fw-medium">
                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="9">
                          <Bars
                            height="80"
                            width="100%"
                            margin="auto"
                            color="#FF7020"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {noData ? (
                          <tr>
                            <td colSpan="9" className="text-center">
                              <h1 className="font16 text-dark">
                                No Business Partner
                              </h1>
                            </td>
                          </tr>
                        ) : (
                          customerData.map((cust, i) => (
                            <tr key={cust._id}>

                              <td className="font14">{cust.business_name}</td>
                              <td className="font14">
                                {cust.business_partner_id}
                              </td>
                              <td className="font14 p-0">
                                {cust.main_code} {cust.main_phone}
                              </td>
                              <td className="font14 ps-3 ">
                                $
                                {Number(cust.credit_amount)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="font14 ">
                                {cust.customer_count} Customers
                              </td>

                              <td className="font14 ">
                                {cust.brands.length} Brands
                              </td>
                              <td className="font14">
                                {cust.city.charAt(0).toUpperCase() +
                                  cust.city.slice(1)}
                              </td>

                              <td className="d-flex align-items-center justify-content-center">
                                <Button
                                  variant="none"
                                  onClick={() => retriveTrashDirectly(cust._id,cust.business_partner_id)}
                                  className="fullRounded p-1 fw-bold font12"
                                  style={{
                                    width: "31px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span style={{ cursor: "pointer" }}>
                                    <TbRefresh
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "green",
                                      }}
                                    />
                                  </span>
                                </Button>

                                <Button
                  variant="none"
                  // onClick={() => handleDelete(cust._id)}  
                  onClick={()=> handleOpen(cust._id)}
                  className="fullRounded px-4 py-2 fw-bold font12"
                  style={{
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    background: "#FF7020",
                    color: "white",
                  }}
                >
                  <span style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </span>
                </Button>

                              </td>
                            </tr>
                          ))
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>

            {/* <Col xs lg="2">
          <input  style={{width:"30px"}} type="number" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input>/ {numberOfPages} 
          </Col> */}
            {numberOfPages === 0 ? (
              ""
            ) : (
              <Col className="mt-5 mb-4">
                {
                  <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                    {pageNumber === 0 ? (
                      ""
                    ) : (
                      <li onClick={prevPage}>Previous </li>
                    )}

                    {items}

                    {numberOfPages - 1 === pageNumber ? (
                      ""
                    ) : (
                      <li onClick={nextPage}>Next</li>
                    )}
                    <li className="font14">
                      <input
                       className="input-sector"
                        style={{
                          width: "25px",
                          border: "1px solid #4C00EF",
                          borderRadius: "4px",
                          textAlign: "center",
                        }}
                        value={showPage ? showPage : ""}
                        type="number"
                        name="number"
                        id=""
                        onChange={(e) => {
                          pagenumberSearch(e.target.value);
                        }}
                      ></input>{" "}
                      <span style={{ color: "#4C00EF" }}>/</span>{" "}
                      {numberOfPages}
                    </li>
                  </ul>
                }
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BusinessTrash;
