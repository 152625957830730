import React, { useState, useEffect } from "react";
// import { FaMinus, FaPlus } from 'react-icons/fa';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import ProductInput from "./ProductInput";
import { toast } from "react-toastify";
import ViewCard from "./ViewCard";
import axios from "axios";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { getcart } from "../../Redux/CartType/actions";
import WebSocketClient from "websocket";
import { FaChevronLeft } from "react-icons/fa";

import Offcanvas from "react-bootstrap/Offcanvas";

import Button from "react-bootstrap/Button";
import RedeemProgress from "./RedeemProgress";
const ProductItem = (props) => {
  // console.log(props.modalOpenShow, "props")
  const optionGiftcard = {
    fontWeight: "700",
    // fontSize: "20px",
    lineHeight: "20px",
    // textAlign: "center",
    color: "#171F46",
    // width: "100%",
    // paddingLeft: "25px"
  };

  const cursorStyle = {
    cursor: "default",
    color:"#7A8992"
  };
  const cmsData = props.cmsData;

  const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
  localStorage.getItem("defaultNcentivaCms", props.defaultNcentiva);

  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartReducer);
  // console.log(cartData, "cartData");

  const customer = JSON.parse(window.localStorage.getItem("customer"));
  const customerId = customer?.customer?._id;
  let lists = JSON.parse(localStorage.getItem("listItem")) ?? [];
  if (lists) {
    lists = lists.filter((item) => item?.customer_id === customerId);
  }
  const contactList = cartData?.data?.cart ?? [];
  let token;
  var id;
  var bonus = 0;
  var minValue = 0;
  var minCost = 0;
  var bonusFee = 0;
  var max_discount = 0;
  var max_bonus_amount = 0;
  var max_cost = 0;
  var costValue = 0;
  var rbalance = 0;
  var balance = 0;
  var maxValue = 0;
  var rebate = 0;
  const [balances, setBalances] = useState("");
  // if (customer) {
  //     balance = customer.customer.balance;
  // }
  if (customer) {
    token = customer.token;
  } else {
    token = null;
  }
  balance = parseFloat(props.rbalance_redux);
  contactList.length !== 0
    ? contactList.findLast((item) => (id = item.id))
    : (id = 0);
  const data = props.data;
  if (data.minValue > 0) {
    minValue = data.minValue;
    minCost = data.minCost;
    maxValue = data.maxValue;
  }
  if (data.faceValue > 0) {
    minValue = data.faceValue;
    minCost = data.faceValue;
  }
  const [count, setCount] = useState(Number(minValue).toFixed(2));
  const [cminvalue] = useState(minValue);
  const [cmaxvalue] = useState(maxValue);
  const [cost, setCost] = useState(minCost);
  const [rFund, setrFund] = useState(balance);
  const [walletBalance, setWalletBalnce] = useState(balance);
  const [disabled, setDisabled] = useState(false);
  const [fixedValue, setFixedValue] = useState();
  const [fixedGiftId, setFixedGiftId] = useState();
  const [fixedDiscount, setFixedDiscount] = useState();
  const [fixedProcessing, setFixedProcessing] = useState();
  const [fixedBonus, setFixedBonus] = useState();

  const [fixedDis, setfixedDis] = useState(0);
  const [bonusDis, setBonusDis] = useState(0);
  const [bonuss, setBonuss] = useState(0);
  const [rbal, setRbal] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  let remaingWallet = Number(0);
  let localwallet = Number(0);
  let propsWalletBalance = props.wallet_balance;
  for (let i = 0; i < contactList?.length; i++) {
    localwallet = localwallet + parseFloat(contactList[i].ncentiva_wallet);
  }
  const { ws } = props;
  useEffect(() => {
    // Ensure ws exists
    if (ws) {
      // Handle incoming messages
      ws.onmessage = (event) => {
        console.log("Received message from server:", event.data);
        const message = JSON.parse(event.data);
        console.log("message", message.action);
        if (
          message.action === "addItemToCart" ||
          message.action === "deleteItemToCart" ||
          message.action === "redemItemFromCart"
        ) {
          dispatch(getcart(token));
        }
      };
    }

    // Cleanup function
    return () => {
      // No need to close the WebSocket here
    };
  }, [ws]); // Add ws as a dependency

  // localwallet = parseFloat(localwallet) +

  useEffect(() => {
    if (data.fixed.length > 0) {
      data.fixed.sort((a, b) => {
        return a.faceValue - b.faceValue;
      });
      setFixedValue(Number(data.fixed[0].faceValue));
      setfixedDis(
        Number(data.fixed[0].faceValue) +
          Number(data.fixed[0].faceValue) *
            (Number(data.fixed[0].discount) / 100)
      );
      const addBonusOnFix =
        (Number(data.fixed[0].discount) * Number(data.bonusFee / 100)) / 100;
      setFixedBonus(addBonusOnFix);
      setBonuss(data.bonus);
      if (data.bonusFee) {
        setBonusDis(
          Number(data.fixed[0].discount) * Number(data.bonusFee / 100)
        );
      } else {
        setBonusDis(Number(data.fixed[0].discount));
      }
      setFixedDiscount(Number(data.fixed[0].discount));
      setFixedGiftId(Number(data.fixed[0].giftcart_id));
      setFixedProcessing(data.fixed[0].processing);
    }
  }, []);

  if (data.bonusFee > 0) {
    bonusFee = data.bonusFee;
  } else {
    if (data.discount > 0) {
      bonus = data.discount;
    }
  }

  const discount = data.discount;
  if (discount) {
    if (data.bonusFee > 0) {
      bonus = discount * (bonusFee / 100);
    }
  } else {
  }
  let calcualtion = Number(0);
  if (data?.fixed?.length > 0) {
    calcualtion =
      (parseFloat(data.fixed[0].faceValue) *
        parseFloat(data.fixed[0].discount)) /
        100 +
      parseFloat(data.fixed[0].faceValue);
  } else {
    calcualtion =
      (parseFloat(data.minValue) * parseFloat(bonus)) / 100 +
      parseFloat(data.minValue);
  }
  const [b] = useState(Number(data.bonus).toFixed(2));
  var cust_id;
  if (customer) {
    cust_id = customer.customer._id;
  }

  const getcustomer = (cust_id) => {
    // axios
    //     .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcustomer/${cust_id}`)
    //     .then((response) => {

    if (contactList) {
      // var sbalance = response.data.customer.balance;
      var sbalance = props.rbalance_redux;
      let sum = 0;
      let bonus = 0;
      let costValue = 0;
      let ncentivaBalance = 0;
      for (var i = 0; i < contactList.length; i++) {
        if (contactList[i].wallet == false) {
          sum += parseFloat(contactList[i].cost);
          bonus += parseFloat(contactList[i].bonus);
          costValue += parseFloat(contactList[i].costValue);
          ncentivaBalance += parseFloat(contactList[i].ncentiva_wallet);
        }
      }
      rbalance = parseFloat(sbalance) - parseFloat(sum);
      setrFund(Number(rbalance).toFixed(2));
      setRbal();
      setWalletBalnce(Number(ncentivaBalance).toFixed(2));
    } else {
      // setrFund(response.data.customer.balance);
      setrFund(props.rbalance_redux);
    }
  };

  useEffect(() => {
    if (cust_id) {
      getcustomer(cust_id, token);
    }
    if (count === 0) {
      toast.warning(`insufficient balance `);
      return false;
    } else if (count < data.minValue) {
      setCost(Number(data.minValue).toFixed(2));
    } else {
      if (data.fixed.length > 0) {
        setIsFixed(true);
      }
      if (data.discount > 0) {
        const max_discount = parseFloat(count) * (data.bonus / 100);
        const max_bonus = parseFloat(max_discount) * (data.bonusFee / 100);
        const fixed_discount =
          parseFloat(data.maxValue) / (1 + data.bonus / 100);
        const max_cost = parseFloat(count) + parseFloat(max_discount);

        if (max_cost >= data.maxValue) {
          setCount(Number(fixed_discount).toFixed(2));
          setCost(Number(data.maxValue).toFixed(2));
        } else {
          setCost(Number(max_cost).toFixed(2));
        }
      } else {
        if (count > data.maxValue) {
          setCost(Number(data.maxValue).toFixed(2));
          setCount(Number(data.maxValue).toFixed(2));
        } else {
          setCost(Number(count).toFixed(2));
        }
      }
    }
  }, [count, b, cost, cust_id, rFund]);


  // const modalOnchange = (e) => {
  //   props.setPaymentOption(e.target.value);
  // };

  const handleConfirmModal = (selectedPaymentOption, isFixed) => {
    let result;
    if (isFixed === true) {
      result = addFixedCart(selectedPaymentOption);
    } else {
      result = addCart(selectedPaymentOption);
    }
    return result;
  };
  const handleConfirmAndNotify = (selectedPaymentOption, isFixed) => {
    const result = handleConfirmModal(selectedPaymentOption, isFixed);
    // Call the onConfirm prop with the result
    props.onConfirm(result);
  };
  //condition check ................

  // function checkBalance (selectedPaymentOption) {
  //     let walletUsedBalance = Number(0);
  //     let giftcardCost = Number(0);
  //     let walletBonus = Number(0);

  //     for (var i = 0; i < contactList.length; i++) {
  //         giftcardCost += parseFloat(contactList[i].costValue);
  //         walletBonus += parseFloat(contactList[i].ncentiva_wallet);
  //         if (contactList[i].wallet === true) {
  //             walletUsedBalance += parseFloat(contactList[i].cost);
  //         }
  //     }

  //     if (count < cminvalue) {
  //         toast.warning(`Please  Add Minimum Value of Card`);
  //         return false;
  //     }
  //     if (count > cmaxvalue) {
  //         toast.warning(`Please  Add Maximum Value of Card`);
  //         return false;
  //     }
  //     if (rFund < count) {
  //         toast.warning('Insufficient Balance');
  //         return false
  //     }

  // }

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  function addCart(selectedPaymentOption) {
    props.setDisableBtn(true);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      props.setDisableBtn(false);
    }, 500);
    let walletUsedBalance = Number(0);
    let walletBalance = Number(0);

    for (var i = 0; i < contactList.length; i++) {
      costValue += parseFloat(contactList[i].costValue);
      walletBalance += parseFloat(contactList[i].ncentiva_wallet);
      if (contactList[i].wallet === true) {
        walletUsedBalance += parseFloat(contactList[i].cost);
      }
    }

    const floatCount = parseFloat(count).toFixed(2);
    const floatrFund = parseFloat(rFund).toFixed(2);
    let floatWallet = Number(0);
    let walletBalanceDeduct;
    walletBalanceDeduct = parseFloat(0);

    let cal =
      parseFloat(localwallet).toFixed(2) - parseFloat(floatCount).toFixed(2);
    let p = parseFloat(localwallet);
    p = Math.abs(p);
    let usedBalance = Number(0);
    let customerBal = parseFloat(balance);
    for (let i = 0; i < contactList?.length; i++) {
      usedBalance += parseFloat(contactList[i].cost);
      floatWallet += parseFloat(contactList[i].ncentiva_wallet);
    }
    floatWallet = floatWallet + parseFloat(props.wallet_balance);

    floatWallet = (
      parseFloat(floatWallet) - parseFloat(walletUsedBalance)
    ).toFixed(2);

    if (selectedPaymentOption === "wallet") {
      // if (parseFloat(floatWallet) < count) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
      if (count < cminvalue) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      if (count > cmaxvalue) {
        toast.warning(`Please  Add Maximum Value of Card`);
        return false;
      }
      var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: data.user_id,
        cost: Number(count).toFixed(2),
        bonus: "0.00",
        costValue: Number(count).toFixed(2),
        discount: Number(data.discount).toFixed(2),
        brand_id: data.brand_id,
        processing: data.processing,
        ncentiva_wallet: 0,
        wallet: true,
      };

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(count).toFixed(2),
            bonus: "0.00",
            costValue: Number(count).toFixed(2),
            discount: Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: true,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            props.toggleShow();

            if (ws) {
              ws.send(JSON.stringify({ action: "addItemToCart" }));
            } else {
              console.error("WebSocket connection is not established");
            }
            dispatch(getcart(token));
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Failed to add data in cart");
          console.log(`Error ${err}`);
        });
      if (cost != "NaN") {
        // contactList.push(item);
      }
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      setTimeout(() => {
        // window.location.reload();
      }, 500);

      // toast.success(`${data.productname} is added on cart `);
      // dispatch(getcart(token));
      return false;
    }
    if (usedBalance === balance) {
      // if (parseFloat(floatWallet) < parseFloat(floatCount)) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
    }
    if (balance == 0.0) {
      // if (parseFloat(floatWallet) == 0.00) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
    }
    if (usedBalance >= balance) {
      if (parseFloat(count) < parseFloat(cminvalue)) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      // if (floatWallet < "0.00") {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
      if (floatWallet > 0.0) {
        // if (parseFloat(floatCount) > floatWallet) {
        //     toast.warning(`Insufficient Balance `);
        //     return false;
        //     // } else {
        //     //     toast.success(`${data.productname} is added on cart`);
        // }
      }
    }
    // if (count === 0) {
    //     toast.warning(`Insufficient Balance `);
    //     return false;
    // }
    if (usedBalance < balance) {
      // if (parseFloat(count) > parseFloat(rFund)) {
      //     toast.warning(`Insufficient Blance `);
      //     return false
      // }
    }
    var c = 0;
    if (data.faceValue > 0) {
      c = parseFloat(data.faceValue);
      max_cost = parseFloat(data.faceValue);
    } else {
      if (count < cminvalue) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      if (count > cmaxvalue) {
        toast.warning(`Please  Add Maximum Value of Card`);
        return false;
      }
      c = parseFloat(count);
      if (data.discount > 0) {
        max_discount = c * (data.discount / 100);
        if (data.bonusFee > 0) {
          max_bonus_amount = max_discount * (data.bonusFee / 100);
        }

        max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
        rebate = max_cost * (data.discount / 100) * (data.bonusFee / 100);
      } else {
        max_cost = parseFloat(c);
      }
    }

    var item = {
      id: id + 1,
      customer_id: customerId,
      name: data.productname,
      img_path: data.img_path,
      user_id: data.user_id,
      cost: Number(c).toFixed(2),
      bonus: Number(max_bonus_amount).toFixed(2),
      costValue: Number(max_cost).toFixed(2),
      discount: Number(data.discount).toFixed(2),
      brand_id: data.brand_id,
      processing: data.processing,
      ncentiva_wallet: 0,
      wallet: false,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          id: id + 1,
          customer_id: customerId,
          name: data.productname,
          brandCategory: data.brandCategory,
          img_path: data.img_path,
          user_id: data.user_id,
          cost: Number(c).toFixed(2),
          bonus: Number(max_bonus_amount).toFixed(2),
          costValue: Number(max_cost).toFixed(2),
          discount: Number(data.discount).toFixed(2),
          brand_id: data.brand_id,
          processing: data.processing,
          ncentiva_wallet: 0,
          wallet: false,
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          props.toggleShow();

          if (ws) {
            ws.send(JSON.stringify({ action: "addItemToCart" }));
          } else {
            console.error("WebSocket connection is not established");
          }
          dispatch(getcart(token));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Failed to add data in cart");
        console.log(`Error ${err}`);
      });
    // if(usedBalance > balance) {
    //     toast.warning(`Insufficient Balance `);
    //     return false;
    // }
    // if (usedBalance >= balance) {
    //     var item = {
    //         id: id + 1,
    //         name: data.productname,
    //         img_path: data.img_path,
    //         user_id: data.user_id,
    //         cost: Number(c).toFixed(2),
    //         bonus: "0.00",
    //         costValue: Number(c).toFixed(2),
    //         discount:Number(data.discount).toFixed(2),
    //         brand_id: data.brand_id,
    //         processing: data.processing,
    //         ncentiva_wallet: 0,
    //         wallet: true,
    //     }
    //     if(cost != "NaN"){

    //         // contactList.push(item)
    //     }
    //     localStorage.setItem('listItem', JSON.stringify(contactList))
    //     setTimeout(() => {
    //         // window.location.reload();
    //     },500)
    //     return false;
    // }
    if (cost != "NaN") {
      // contactList.push(item);
    }
    if (rFund > 0.0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))
    }
    if (p > 0.0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      const walletUpadte = (p - parseFloat(floatCount)).toFixed(2);
    }

    if (cal >= 0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      // if (cal < 0.00) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
    }

    var item = {
      id: id + 1,
      customer_id: customerId,
      name: data.productname,
      img_path: data.img_path,
      user_id: data.user_id,
      cost: Number(c).toFixed(2),
      bonus: Number(max_bonus_amount).toFixed(2),
      costValue: Number(max_cost).toFixed(2),
      discount: Number(data.discount).toFixed(2),
      brand_id: data.brand_id,
      processing: data.processing,
      ncentiva_wallet: 0,
      wallet: false,
    };

    if (contactList && cost != "NaN" && rFund > 0) {
      // toast.success(`${data.productname} is added on cart `);
      // dispatch(getcart(token))
      setTimeout(() => {
        // window.location.reload();
      }, 500);
    }
  }

  function incrementCount() {
    if (count == "") {
      setCount("1.00");
    } else {
      if (count < data.maxValue) {
        setCount(Number(parseFloat(count) + 1).toFixed(2));
        if (data.bonusType == "percentage") {
          const c = parseFloat(count) + 1;
          if (data.discount > 0) {
            const max_discount = c * (data.discount / 100);
            const max_bonus_amount = max_discount * (data.bonusFee / 100);
            const max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
            setCost(Number(max_cost).toFixed(2));
          } else {
            setCost(Number(c).toFixed(2));
          }
        }
      }
    }
  }
  function decrementCount() {
    if (count > data.minValue) {
      setCount(Number(parseFloat(count) - 1).toFixed(2));
      if (data.bonusType == "percentage") {
        const c = parseFloat(count) - 1;
        if (data.discount > 0) {
          const max_discount = c * (data.discount / 100);
          const max_bonus_amount = max_discount * (data.bonusFee / 100);
          const max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
          setCost(Number(max_cost).toFixed(2));
        } else {
          setCost(Number(c).toFixed(2));
        }
      }
    }
  }

  function addFixedCart(selectedPaymentOption) {
    props.setDisableBtn(true);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      props.setDisableBtn(false);
    }, 500);

    let usedBalance = Number(0);
    let walletUsedBalance = Number(0);
    let walletBalance = Number(0);
    let customerBal = parseFloat(balance);
    for (let i = 0; i < contactList?.length; i++) {
      usedBalance += parseFloat(contactList[i].cost);
      walletBalance += parseFloat(contactList[i].ncentiva_wallet);
      if (contactList[i].wallet === true) {
        walletUsedBalance += parseFloat(contactList[i].cost);
      }
    }

    walletBalance =
      parseFloat(walletBalance) + parseFloat(props.wallet_balance);

    walletBalance = (
      parseFloat(walletBalance) - parseFloat(walletUsedBalance)
    ).toFixed(2);

    if (selectedPaymentOption === "wallet") {
      let costValue = 0;
      const a = parseFloat(props.rbalance_redux);
      if (contactList) {
        for (let i = 0; i < contactList.length; i++) {
          costValue += parseFloat(contactList[i].cost);
        }
      }
      let left_balance = +a - costValue;
      var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: fixedGiftId,
        cost: (+fixedValue).toFixed(2),
        bonus: Number(max_bonus_amount).toFixed(2),
        costValue: (+fixedValue).toFixed(2),
        discount: fixedDiscount,
        brand_id: data.brand_id,
        processing: fixedProcessing,
        ncentiva_wallet: 0,
        wallet: true,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount: fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: 0,
            wallet: true,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            props.toggleShow();

            if (ws) {
              ws.send(JSON.stringify({ action: "addItemToCart" }));
            } else {
              console.error("WebSocket connection is not established");
            }
            dispatch(getcart(token));
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Failed to add data in cart");
          console.log(`Error ${err}`);
        });

      const wallet = localwallet;
      if (walletBalance < parseFloat(fixedValue)) {
        // toast.warning("Insufficient Balance ")
        // return false;
      } else {
        // contactList.push(item);
        // localStorage.setItem('listItem', JSON.stringify(contactList))
        const walletUpadte = Number(wallet) - Number(fixedValue);
        window.localStorage.setItem("ncentiva_wallet", walletUpadte);
        // toast.success(`${data.productname} is added on cart `);
        // dispatch(getcart(token));
        setTimeout(() => {
          // window.location.reload();
        }, 500);
        return false;
      }
    }

    // if( usedBalance >= balance) {

    // }
    let costValue = 0;
    const a = parseFloat(props.rbalance_redux);
    if (contactList) {
      for (let i = 0; i < contactList.length; i++) {
        costValue += parseFloat(contactList[i].cost);
      }
    }
    let left_balance = +a - costValue;
    var item = {
      id: id + 1,
      customer_id: customerId,
      name: data.productname,
      img_path: data.img_path,
      user_id: fixedGiftId,
      cost: (+fixedValue).toFixed(2),
      bonus: Number(max_bonus_amount).toFixed(2),
      costValue: (+fixedValue).toFixed(2),
      discount: fixedDiscount,
      brand_id: data.brand_id,
      processing: fixedProcessing,
      ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
      wallet: false,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          id: id + 1,
          customer_id: customerId,
          name: data.productname,
          brandCategory: data.brandCategory,
          img_path: data.img_path,
          user_id: fixedGiftId,
          cost: (+fixedValue).toFixed(2),
          bonus: Number(max_bonus_amount).toFixed(2),
          costValue: (+fixedValue).toFixed(2),
          discount: fixedDiscount,
          brand_id: data.brand_id,
          processing: fixedProcessing,
          ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
          wallet: false,
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          props.toggleShow();

          if (ws) {
            ws.send(JSON.stringify({ action: "addItemToCart" }));
          } else {
            console.error("WebSocket connection is not established");
          }
          dispatch(getcart(token));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Failed to add data in cart");
        console.log(`Error ${err}`);
      });

    // if(parseInt(left_balance)>costValue){
    if (parseInt(left_balance) < parseInt(fixedValue)) {
      // toast.warning("Insufficient Balance ")
    } else {
      // contactList.push(item);
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      // toast.success(`${data.productname} is added on cart `);
      // dispatch(getcart(token));
      setTimeout(() => {
        // window.location.reload();
      }, 500);
    }
  }

  function addVariable(remain) {
    props.setDisableBtn(true);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      props.setDisableBtn(false);
    }, 500);
    let walletUsedBalance = Number(0);
    let walletBalance = Number(0);

    for (var i = 0; i < contactList.length; i++) {
      costValue += parseFloat(contactList[i].costValue);
      walletBalance += parseFloat(contactList[i].ncentiva_wallet);
      if (contactList[i].wallet === true) {
        walletUsedBalance += parseFloat(contactList[i].cost);
      }
    }

    const floatCount = parseFloat(count).toFixed(2);
    const floatrFund = parseFloat(rFund).toFixed(2);
    let floatWallet = Number(0);
    let walletBalanceDeduct;
    walletBalanceDeduct = parseFloat(0);

    let cal =
      parseFloat(localwallet).toFixed(2) - parseFloat(floatCount).toFixed(2);
    let p = parseFloat(localwallet);
    p = Math.abs(p);
    let usedBalance = Number(0);
    let customerBal = parseFloat(balance);
    for (let i = 0; i < contactList?.length; i++) {
      usedBalance += parseFloat(contactList[i].cost);
      floatWallet += parseFloat(contactList[i].ncentiva_wallet);
    }
    floatWallet = floatWallet + parseFloat(props.wallet_balance);

    floatWallet = (
      parseFloat(floatWallet) - parseFloat(walletUsedBalance)
    ).toFixed(2);

    if (remain === "true") {
      if (parseFloat(floatWallet) < count) {
        // toast.warning(`Insufficient Balance `);
        // return false;
      }
      if (count < cminvalue) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      if (count > cmaxvalue) {
        toast.warning(`Please  Add Maximum Value of Card`);
        return false;
      }
      var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: data.user_id,
        cost: Number(count).toFixed(2),
        bonus: "0.00",
        costValue: Number(count).toFixed(2),
        discount: Number(data.discount).toFixed(2),
        brand_id: data.brand_id,
        processing: data.processing,
        ncentiva_wallet: 0,
        wallet: true,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: data.user_id,
            cost: Number(count).toFixed(2),
            bonus: "0.00",
            costValue: Number(count).toFixed(2),
            discount: Number(data.discount).toFixed(2),
            brand_id: data.brand_id,
            processing: data.processing,
            ncentiva_wallet: 0,
            wallet: true,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            props.toggleShow();

            if (ws) {
              ws.send(JSON.stringify({ action: "addItemToCart" }));
            } else {
              console.error("WebSocket connection is not established");
            }
            dispatch(getcart(token));
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Failed to add data in cart");
          console.log(`Error ${err}`);
        });
      if (cost != "NaN") {
        // contactList.push(item);
      }
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      setTimeout(() => {
        // window.location.reload();
      }, 500);
      // toast.success(`${data.productname} is added on cart `);
      // dispatch(getcart(token));
      return false;
    }

    if (usedBalance === balance) {
      // if (parseFloat(floatWallet) < parseFloat(floatCount)) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
    }
    if (balance == 0.0) {
      // if (parseFloat(floatWallet) == 0.00) {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
    }
    if (usedBalance >= balance) {
      if (parseFloat(count) < parseFloat(cminvalue)) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      // if (floatWallet < "0.00") {
      //     toast.warning(`Insufficient Balance `);
      //     return false;
      // }
      if (floatWallet > 0.0) {
        if (parseFloat(floatCount) > floatWallet) {
          // toast.warning(`Insufficient Balance `);
          // return false;
        } else {
          // toast.success(`${data.productname} is added on cart`);
        }
      }
    }
    if (count === 0) {
      // toast.warning(`Insufficient Balance `);
      // return false;
    }
    if (usedBalance < balance) {
      // if (parseFloat(count) > parseFloat(rFund)) {
      //     toast.warning(`Insufficient Blance `);
      //     setTimeout(() => {
      //         setDisabled(false);
      //         props.setDisableBtn(false);
      //     }, 3000);
      //     return false
      // }
    }
    var c = 0;
    if (data.faceValue > 0) {
      c = parseFloat(data.faceValue);
      max_cost = parseFloat(data.faceValue);
    } else {
      if (count < cminvalue) {
        toast.warning(`Please  Add Minimum Value of Card`);
        return false;
      }
      if (count > cmaxvalue) {
        toast.warning(`Please  Add Maximum Value of Card`);
        return false;
      }
      c = parseFloat(count);
      if (data.discount > 0) {
        max_discount = c * (data.discount / 100);
        if (data.bonusFee > 0) {
          max_bonus_amount = max_discount * (data.bonusFee / 100);
        }

        max_cost = parseFloat(c) + parseFloat(max_bonus_amount);
        rebate = max_cost * (data.discount / 100) * (data.bonusFee / 100);
      } else {
        max_cost = parseFloat(c);
      }
    }

    var item = {
      id: id + 1,
      customer_id: customerId,
      name: data.productname,
      img_path: data.img_path,
      user_id: data.user_id,
      cost: Number(c).toFixed(2),
      bonus: Number(max_bonus_amount).toFixed(2),
      costValue: Number(max_cost).toFixed(2),
      discount: Number(data.discount).toFixed(2),
      brand_id: data.brand_id,
      processing: data.processing,
      ncentiva_wallet: 0,
      wallet: false,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          id: id + 1,
          customer_id: customerId,
          name: data.productname,
          brandCategory: data.brandCategory,
          img_path: data.img_path,
          user_id: data.user_id,
          cost: Number(c).toFixed(2),
          bonus: Number(max_bonus_amount).toFixed(2),
          costValue: Number(max_cost).toFixed(2),
          discount: Number(data.discount).toFixed(2),
          brand_id: data.brand_id,
          processing: data.processing,
          ncentiva_wallet: 0,
          wallet: false,
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          props.toggleShow();

          if (ws) {
            ws.send(JSON.stringify({ action: "addItemToCart" }));
          } else {
            console.error("WebSocket connection is not established");
          }
          dispatch(getcart(token));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Failed to add data in cart");
        console.log(`Error ${err}`);
      });
    if (usedBalance > balance) {
      // toast.warning(`Insufficient Balance `);
      // return false;
    }

    if (cost != "NaN") {
      // // contactList.push(item);
    }
    if (rFund > 0.0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))
    }
    if (p > 0.0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      const walletUpadte = (p - parseFloat(floatCount)).toFixed(2);
    }

    if (cal >= 0) {
      // localStorage.setItem('listItem', JSON.stringify(contactList))

      if (cal < 0.0) {
        // toast.warning(`Insufficient Balance `);
        // return false;
      }
    }

    if (contactList && cost != "NaN" && rFund > 0) {
      // toast.success(`${data.productname} is added on cart `);
      // dispatch(getcart(token))
      setTimeout(() => {
        // window.location.reload();
      }, 500);
    }
  }

  function addFix(remain) {
    props.setDisableBtn(true);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      props.setDisableBtn(false);
    }, 500);

    let usedBalance = Number(0);
    let walletUsedBalance = Number(0);
    let walletBalance = Number(0);
    let customerBal = parseFloat(balance);
    for (let i = 0; i < contactList?.length; i++) {
      usedBalance += parseFloat(contactList[i].cost);
      walletBalance += parseFloat(contactList[i].ncentiva_wallet);
      if (contactList[i].wallet === true) {
        walletUsedBalance += parseFloat(contactList[i].cost);
      }
    }

    walletBalance =
      parseFloat(walletBalance) + parseFloat(props.wallet_balance);

    walletBalance = (
      parseFloat(walletBalance) - parseFloat(walletUsedBalance)
    ).toFixed(2);
    if (remain === "true") {
      let costValue = 0;
      const a = parseFloat(props.rbalance_redux);
      if (contactList) {
        for (let i = 0; i < contactList.length; i++) {
          costValue += parseFloat(contactList[i].cost);
        }
      }
      let left_balance = +a - costValue;
      var item = {
        id: id + 1,
        customer_id: customerId,
        name: data.productname,
        img_path: data.img_path,
        user_id: fixedGiftId,
        cost: (+fixedValue).toFixed(2),
        bonus: Number(max_bonus_amount).toFixed(2),
        costValue: (+fixedValue).toFixed(2),
        discount: fixedDiscount,
        brand_id: data.brand_id,
        processing: fixedProcessing,
        ncentiva_wallet: 0,
        wallet: true,
      };
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            id: id + 1,
            customer_id: customerId,
            name: data.productname,
            brandCategory: data.brandCategory,
            img_path: data.img_path,
            user_id: fixedGiftId,
            cost: (+fixedValue).toFixed(2),
            bonus: Number(max_bonus_amount).toFixed(2),
            costValue: (+fixedValue).toFixed(2),
            discount: fixedDiscount,
            brand_id: data.brand_id,
            processing: fixedProcessing,
            ncentiva_wallet: 0,
            wallet: true,
          }
        )
        .then((response) => {
          if (response.data.status === "success") {
            toast.success(response.data.message);
            props.toggleShow();
            // Access ws variable here and send WebSocket message
            if (ws) {
              ws.send(JSON.stringify({ action: "addItemToCart" }));
            } else {
              console.error("WebSocket connection is not established");
            }
            dispatch(getcart(token));
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Failed to add data in cart");
          console.log(`Error ${err}`);
        });
      const wallet = localwallet;
      if (walletBalance < parseFloat(fixedValue)) {
        // toast.warning("Insufficient Balance ")
        // return false;
      } else {
        // contactList.push(item);
        // localStorage.setItem('listItem', JSON.stringify(contactList))
        const walletUpadte = Number(wallet) - Number(fixedValue);
        window.localStorage.setItem("ncentiva_wallet", walletUpadte);
        // toast.success(`${data.productname} is added on cart `);
        // dispatch(getcart(token));
        setTimeout(() => {
          // window.location.reload();
        }, 500);
        return false;
      }
    }
    let costValue = 0;
    const a = parseFloat(props.rbalance_redux);
    if (contactList) {
      for (let i = 0; i < contactList.length; i++) {
        costValue += parseFloat(contactList[i].cost);
      }
    }
    let left_balance = +a - costValue;
    var item = {
      id: id + 1,
      customer_id: customerId,
      name: data.productname,
      img_path: data.img_path,
      user_id: fixedGiftId,
      cost: (+fixedValue).toFixed(2),
      bonus: Number(max_bonus_amount).toFixed(2),
      costValue: (+fixedValue).toFixed(2),
      discount: fixedDiscount,
      brand_id: data.brand_id,
      processing: fixedProcessing,
      ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
      wallet: false,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/add-on-hold-cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          id: id + 1,
          customer_id: customerId,
          name: data.productname,
          brandCategory: data.brandCategory,
          img_path: data.img_path,
          user_id: fixedGiftId,
          cost: (+fixedValue).toFixed(2),
          bonus: Number(max_bonus_amount).toFixed(2),
          costValue: (+fixedValue).toFixed(2),
          discount: fixedDiscount,
          brand_id: data.brand_id,
          processing: fixedProcessing,
          ncentiva_wallet: Number(fixedBonus) * Number(fixedValue),
          wallet: false,
        }
      )
      .then((response) => {
        if (response.data.status === "success") {
          toast.success(response.data.message);
          props.toggleShow();
          if (ws) {
            ws.send(JSON.stringify({ action: "addItemToCart" }));
          } else {
            console.error("WebSocket connection is not established");
          }
          dispatch(getcart(token));
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Failed to add data in cart");
        console.log(`Error ${err}`);
      });
    // if(parseInt(left_balance)>costValue){
    if (parseInt(left_balance) < parseInt(fixedValue)) {
      // toast.warning("Insufficient Balance ")
    } else {
      // contactList.push(item);
      // localStorage.setItem('listItem', JSON.stringify(contactList))
      // toast.success(`${data.productname} is added on cart `);

      setTimeout(() => {
        // window.location.reload();
      }, 500);
    }
  }

  if (cost == undefined) {
    setCost("");
  }
  let wUsedbalance = Number(0);
  for (let i = 0; i < contactList?.length; i++) {
    if (contactList[i].wallet == true) {
      wUsedbalance += parseFloat(contactList[i].cost);
    }
  }
  wUsedbalance = parseFloat(props?.wallet_balance) - parseFloat(wUsedbalance);

  wUsedbalance.toFixed(2);
  useEffect(() => {
    if (cartData.data !== null) {
      getcustomer(id, token);
    }
  }, [cartData.data, rFund, id]);
  const displayBonusPercentage = () => {
    if (customer) {
      if (data?.fixed?.length > 0) {
        if (fixedValue) {
          return `+${Number(bonuss).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        } else {
          return `+${Number(data.fixed[0].faceValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        }
      } else {
        if (data.faceValue == 0.0) {
          return `+${Number(b).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        } else {
          return `+${Number(data.bonuss).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        }
      }
    } else {
      if (data?.fixed?.length > 0) {
        if (fixedValue) {
          return `+${Number(data.fixed[0].discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        } else {
          return `+${Number(bonuss).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
        }
      } else {
        return `+${Number(bonus).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;
      }
    }
  };
  
  return (
    <>
      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 mb-4 ps-md">
        <div className="card border rounded-3  h-100 position-relative">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="bonus-tooltip">Bonus</Tooltip>}
        >
          <div className=" p-1 position-absolute text-center custom_discount" onClick={() => setModalShow(true)}>
            <span>{displayBonusPercentage()}</span>
          </div>
        </OverlayTrigger>
          <div className="card-header p-0 border-0">
            <img
              className="rounded-top-3 card_image_style"
              style={{
                maxWidth: "100%",
                objectFit: "cover",
                width: "100%",
                objectFit: "cover",
              }}
              src={data.img_path}
            />
          </div>
          <div className="card-body py-0">
            <div className="d-flex justify-content-between align-items-center bg-white text-dark  pt-1  responsive_text mt-0">
              <h6 className="">{data.productname}</h6>
              <ViewCard
                onChange={props.modalOnchange}
                payment={props.paymentOption}
                rfund={rFund}
                countValue={count}
                handleConfirm={handleConfirmAndNotify}
                walletBalace={wUsedbalance.toFixed(2)}
                brand_id={data.brand_id}
                primaryColor={props.primaryColor}
                secondaryColor={props.secondaryColor}
                btnColor={props.btnColor}
                btnText={props.btnText}
                isfixedValue={isFixed}
                coupon={data._id}
                data={data}
                rbalance_redux={props.rbalance_redux}
                disableBtn={props.disableBtn}
                setDisableBtn={props.setDisableBtn}
                wallet_balance={propsWalletBalance}
                setModalShow={setModalShow}
                modalShow={modalShow}
                ws={ws}
              />
            </div>
            {data.processing == "REALTIME" ? (
              <small
                style={cursorStyle}
                className="bg-white   responsive_text responsive_text_new mt-0"
                title="Within 30 mins"
                
              >
                Delivery Within 30 mins
              </small>
            ) : (
              <small
                style={cursorStyle}
                className="bg-white   responsive_text responsive_text_new mt-0"
                title="Within 24 hours"
              >
                Delivery Within 24 hours
              </small>
            )}
            {data.productname == "Prepaid Mastercard $0.01 - $2000" && (
              <>
                <br />{" "}
                <small
                  style={cursorStyle}
                  className="bg-white text-primary "
                  title="Six months expiration"
                >
                  Six months expiration
                </small>
              </>
            )}
            <div className="pt-0">
              <span style={{ color: "black" }}>You Receive: </span>
              {customer ? (
                data?.fixed?.length > 0 ? (
                  <>
                    {fixedValue ? (
                      <>
                        <span
                          className={`text-primary fw-bold mb-0 ${
                            data?.fixed.length > 0 ? "text_value" : ""
                          }`}
                          style={{ fontSize: "15px" }}
                        >
                          <span
                            className="fw-bold"
                            style={{
                              fontSize: "15px",
                              color: "rgb(245, 196, 0)",
                            }}
                          >
                            ${Number(fixedValue).toFixed(2)}
                          </span>
                          <span
                            className="fw-bold"
                            style={{
                              fontSize: "12px",
                              color: "rgb(245, 196, 0)",
                            }}
                          >
                            {" "}
                            +{" "}
                          </span>
                          <span
                            className="fw-bold"
                            style={{
                              whiteSpace: "nowrap",
                              color: "rgb(245, 196, 0)",
                            }}
                          >
                            {" "}
                            ${((fixedValue * bonusDis) / 100).toFixed(2)}{" "}
                          </span>
                        </span>
                            <br />
                        <span className="fw-bold responsive_text_new_bonus" 
                        // style={{ color: "black" }}
                        >Bonus Cash{" "}
                          <small
                            // style={{
                            //   fontSize: "12px",
                            //   color: "rgb(245, 196, 0)",
                            // }}
                          >
                            {/* Bonus Cash{" "} */}
                          </small>

                          {/* <span>{`(+${Number(bonuss)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)`}</span> */}
                        </span>
                      </>
                    ) : (
                      <span>
                        {" "}
                        {`$${Number(data.fixed[0].faceValue)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      </span>
                    )}
                  </>
                ) : (
                  <span className="fw-bold">
                    {data.faceValue == 0.0 ? (
                      <>
                        <span
                          style={{ color: "rgb(61, 5, 182)" }}
                          className="fw-bold"
                        >
                          $
                          {Number(cost)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                        <span style={{ color: "black" }} className="fw-bold">
                          <span> 
                            </span>
                            {/* (+$
                          {Number(b)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          %) */}
                        </span>
                      </>
                    ) : (
                      `$${Number(data.bonuss)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    )}
                  </span>
                )
              ) : (
                <>
                  {data?.fixed?.length > 0 ? (
                    <>
                      {fixedValue ? (
                        <>
                          <span
                            className={`text-primary fw-bold mb-0 ${
                              data?.fixed.length > 0 ? "text_value" : ""
                            }`}
                            style={{ fontSize: "15px" }}
                          >
                            <span
                              className="fw-bold"
                              style={{
                                fontSize: "15px",
                                color: "rgb(245, 196, 0)",
                              }}
                            >
                              ${Number(fixedValue).toFixed(2)}
                            </span>
                            <span
                              className="fw-bold"
                              style={{
                                fontSize: "12px",
                                color: "rgb(245, 196, 0)",
                              }}
                            >
                              {" "}
                              +{" "}
                            </span>
                            <span
                              className="fw-bold"
                              style={{
                                whiteSpace: "nowrap",
                                color: "rgb(245, 196, 0)",
                              }}
                            >
                              {" "}
                              ${((fixedValue * bonusDis) / 100).toFixed(2)}{" "}
                            </span>{" "}
                            <br />
                            <small
                              // style={{
                              //   fontSize: "12px",
                              //   color: "rgb(245, 196, 0)",
                              // }}
                              className="responsive_text_new_bonus"
                            >
                              {" "}
                              Bonus Cash
                            </small>
                          </span>

                          <span
                            style={{ color: "black" }}
                            className="mt-0 fw-bold"
                          >
                            {/* <span>
                              {" "}
                              {`(+${Number(data.fixed[0].discount)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)`}
                            </span> */}
                          </span>
                        </>
                      ) : (
                        <span className="fw-bold">
                          {`${Number(bonuss)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="fw-bold">
                      {`$${Number(calcualtion)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      <span
                        className="fw-bold"
                        style={{ fontSize: "15px", color: "black" }}
                      >
                        {/* <span>
                          {" "}
                          (+
                          {Number(bonus)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          %)
                        </span> */}
                      </span>
                    </span>
                  )}
                </>
              )}
            </div>

            {/* </div> */}
          </div>
          <div className="card-footer bg-transparent border-0">
            <div className="responsive_card_footer ">
              <div
                className="d-flex"
                style={{ maxWidth: "100%" }}
              >
                {data?.fixed?.length > 0 ? (
                  <div className="inputwithunit">
                    <select
                      style={optionGiftcard}
                      className="dropdown_listing form-select responsive_select_width"
                      onChange={(e) => {
                        const selectedValue = JSON.parse(e.target.value);
                        setFixedValue(selectedValue.faceValue);
                        setFixedDiscount(selectedValue.bonus);
                        setFixedGiftId(selectedValue.giftcart_id);
                        setfixedDis(
                          Number(selectedValue.faceValue) +
                            Number(selectedValue.faceValue) *
                              Number(selectedValue.discount / 100)
                        );
                      }}
                    >
                      {data?.fixed
                        ?.sort((a, b) => {
                          return a.faceValue - b.faceValue;
                        })
                        .map((data) => {
                          return (
                            <option
                              key={data.giftcart_id}
                              value={JSON.stringify(data)}
                              className="d-flex "
                            >
                              $ {(+data.faceValue)?.toFixed(2)}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                ) : (
                  <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                    {count > data.maxValue ? (
                      <button
                        type="button"
                        className="btn listing-input-btn btn-minus me-3 me-sm-1"
                        onClick={decrementCount}
                        disabled
                      >
                        {/* <FaMinus /> */}
                      <span className="text-center d-flex justify-content-center align-items-center" style={{fontSize:"20px", fontWeight:"600"}}>-</span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn listing-input-btn btn-minus me-3 me-sm-1"
                        onClick={decrementCount}
                      >
                        {/* <FaMinus /> */}
                        <span className="responsive_button_minus text-center d-flex justify-content-center align-items-center" style={{fontSize:"20px", fontWeight:"600"}}>-</span>
                      </button>
                    )}

                    <div className="inputwithunit  ">
                      <span className="mr-0 responsive_dollar">$</span>
                      <input
                        type="number"
                        className="form-control responsive_inputwithunit"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                    </div>

                    <button
                      type="button"
                      className="btn listing-input-btn btn-plus ms-3 ms-sm-1"
                      onClick={incrementCount}
                    >
                      {/* <FaPlus /> */}
                     <span className="text-center d-flex justify-content-center align-items-center" style={{fontSize:"20px", fontWeight:"600"}}>+</span>
                    </button>
                  </div>
                )}
              </div>
             
                {disabled || props.disableBtn ? (
                  <button
                    className="btn btn-main btn-add add_button_New py-1 responsive_button_style"
                    // style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, fontSize: "16px" }}
                    style={
                      defaultNcentiva
                        ? {
                            backgroundColor: `${props?.btnColor}80`,
                            color: props?.btnText,
                            border: `1px solid transparent`,
                          }
                        : {
                            backgroundColor: `${props?.btnColor}`,
                            color: props?.btnText,
                            border: `1px solid ${props?.btnColor}`,
                          }
                    }
                    // onClick={() => (cartData.data.remaingWalletBalance > 0 ? openModal() : "")}
                    onClick={props.toggleShow()}
                  >
                    Add
                  </button>
                ) : (
                  <>
                    {customer ? (
                      <>
                        {data?.fixed?.length > 0 ? (
                          <Link
                            to=""
                            // onClick={() => (wUsedbalance > 0 ? (rFund > 0 ? openModal() : addFix("true")) : addFix())}
                            onClick={() => {
                              if (Number(rFund) < Number(fixedValue)) {
                              }
                              if (
                                parseFloat(rFund) < parseFloat(fixedValue) &&
                                parseFloat(wUsedbalance) >
                                  parseFloat(fixedValue)
                              ) {
                                addFix("true");
                              } else if (
                                parseFloat(rFund) > parseFloat(fixedValue) &&
                                parseFloat(wUsedbalance) <
                                  parseFloat(fixedValue)
                              ) {
                                addFix();
                              } else if (
                                parseFloat(cartData.data.remaingBalance) >=
                                  parseFloat(fixedValue) &&
                                parseFloat(
                                  cartData.data.remaingWalletBalance
                                ) >= parseFloat(fixedValue)
                              ) {
                                openModal();
                              } else {
                                addFix();
                              }
                            }}
                            replace
                            className="btn btn-main btn-add add_button_New py-1 responsive_button_style"
                            // style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` , fontSize: "16px" }}
                            style={
                              defaultNcentiva
                                ? {
                                    backgroundColor: `${props?.btnColor}80`,
                                    color: props?.btnText,
                                    border: `1px solid transparent`,
                                  }
                                : {
                                    backgroundColor: `${props?.btnColor}`,
                                    color: props?.btnText,
                                    border: `1px solid ${props?.btnColor}`,
                                  }
                            }
                            // onClick={props.toggleShow()}
                          >
                            Add
                          </Link>
                        ) : (
                          <Link
                            to=""
                            onClick={() => {
                              if (Number(rFund) < Number(count)) {
                              }
                              if (
                                parseFloat(cartData.data.remaingBalance) <
                                  parseFloat(count) &&
                                parseFloat(wUsedbalance) >= parseFloat(count)
                              ) {
                                addVariable("true");
                              } else if (
                                parseFloat(cartData.data.remaingBalance) >
                                  parseFloat(count) &&
                                parseFloat(wUsedbalance) < parseFloat(count)
                              ) {
                                addVariable();
                              } else if (
                                parseFloat(cartData.data.remaingBalance) >=
                                  parseFloat(count) &&
                                parseFloat(
                                  cartData.data.remaingWalletBalance
                                ) >= parseFloat(count)
                              ) {
                                // props.toggleShow();
                                openModal();
                              } else {
                                addVariable();
                              }
                            }}
                            replace
                            className="btn btn-main btn-add add_button_New py-1 responsive_button_style"
                            // style={{ backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, fontSize: "16px" }}
                            style={
                              defaultNcentiva
                                ? {
                                    backgroundColor: `${props?.btnColor}80`,
                                    color: props?.btnText,
                                    border: `1px solid transparent`,
                                  }
                                : {
                                    backgroundColor: `${props?.btnColor}`,
                                    color: props?.btnText,
                                    border: `1px solid ${props?.btnColor}`,
                                  }
                            }
                          >
                            Add
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link
                        disabled
                        className="btn btn-main  add_button_New py-1 responsive_button_style"
                        style={
                          defaultNcentiva
                            ? {
                                backgroundColor: `${props?.btnColor}80`,
                                color: props?.btnText,
                                border: `1px solid transparent`,
                                cursor: "not-allowed"
                              }
                            : {
                                backgroundColor: `${props?.btnColor}`,
                                color: props?.btnText,
                                border: `1px solid ${props?.btnColor}`,
                                cursor: "not-allowed"
                              }
                        }
                       
                      >
                        Add
                      </Link>
                    )}
                    {data?.fixed?.length > 0
                      ? showModal && (
                          <PaymentConfirmationModal
                            paymentOption={props.paymentOption}
                            onChangeFunction={props.modalOnchange}
                            handleClose={closeModal}
                            onConfirm={handleConfirmModal}
                            value={fixedValue}
                            reaminingBonus={cartData.data.remaingWalletBalance}
                            btnColor={props.btnColor}
                            btnText={props.btnText}
                            rFund={cartData.data.remaingBalance}
                            isFixed={isFixed}
                            handleOpen={openModal}
                          />
                        )
                      : showModal && (
                          <PaymentConfirmationModal
                            paymentOption={props.paymentOption}
                            onChangeFunction={props.modalOnchange}
                            handleClose={closeModal}
                            onConfirm={handleConfirmModal}
                            value={count}
                            reaminingBonus={cartData.data.remaingWalletBalance}
                            btnColor={props.btnColor}
                            btnText={props.btnText}
                            rFund={cartData.data.remaingBalance}
                            isFixed={isFixed}
                            handleOpen={openModal}
                          />
                        )}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

ProductItem.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  price: PropTypes.number,
  percent: PropTypes.number,
};

ProductItem.defaultValue = {
  alt: "Product Image",
};

export default ProductItem;
