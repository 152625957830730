import axios from "axios";
import {SET_CART_DATA_REQUEST,SET_CART_DATA_SUCCESS,SET_CART_DATA_FAILURE} from "./actionTypes"

export const cart_req = () => ({
    type: SET_CART_DATA_REQUEST
  });
  
  export const cart_succ = (data) => ({
    type: SET_CART_DATA_SUCCESS,
    payload: data
  });
  
  export const cart_fail = (err) => ({
    type: SET_CART_DATA_FAILURE,
    payload: err
  });

export const getcart = (token) => async (dispatch) => {
  dispatch(cart_req());

  try {
    const customer = JSON.parse(window.localStorage.getItem('customer'));
    const customerId = customer?.customer?._id;
    if (customer ) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/getholdcart/${customerId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      dispatch(cart_succ(response.data));
    } else {
      dispatch(cart_fail());
    }
   
  } catch (error) {
    dispatch(cart_fail(error));
  }
};
