import React, { useState, useEffect } from 'react'
import { FaCaretDown, FaSortAmountDown, FaTimes, FaTrash } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import RedeemProgress from './elements/RedeemProgress'
import ReturnBtn from './elements/ReturnBtn'
import { toast } from 'react-toastify';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux'
import { Bars, ThreeDots } from "react-loader-spinner";
import { getCms } from '../Redux/GetCms/actions';
import { Button, Container, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getcart } from '../Redux/CartType/actions';
import WebSocketClient from 'websocket';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl'
import "./checkComp.css"
import { CheckOutSimilarBrandGiftCard } from './CheckOutSimilarBrandGiftCard'


const CheckoutComp = () => {

    const [modal_status, setModal_status] = useState(true)
    const nameDomain = (window.location.host.split('.'));
    const [usedFund, setUsedFund] = useState(0);
    const [usedBalance, setUsedBalance] = useState(0);
    const [totalUsedBalance, setTotalUsedBalance] = useState(0);
    const [totalUsedBonus, setTotalUsedBonus] = useState(0);
    const [totalBonusCash, setTotalBounsCash] = useState(0);
    const [walletFund, setWalletFund] = useState(0);
    const [rFund, setrFund] = useState(0);
    const [walletbalance, setWalletbalance] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [redeemDisabled, setRedeemDisabled] = useState(false);
    const [cartData, setCartData] = useState([]);
    const [cmsData, setCmsData] = useState(false)
    const dispatch = useDispatch()
    const reduxCartData = useSelector((state) => state.cartReducer)
    const reduxCartAllDataArray=reduxCartData?.data?.cart
    const arrayOfCartDataID = reduxCartAllDataArray?.map(item => ({ brand_id: item?.brand_id }));
    let lists = reduxCartData?.data?.cart;
    const customer = JSON.parse(window.localStorage.getItem('customer'));
    const [similarCardLoading, setSimilarcardLoading]=useState(false)
    
    useEffect(() => {
        if (reduxCartData?.data?.cart) {
            setCartData(reduxCartData?.data?.cart?.length);
        }
},[])
    let cusId = customer?.customer?._id
    if (lists) {
        lists = lists?.filter((item) => item?.customer_id === cusId)
    }
    const cartList = lists;
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [customer_first_name, setCustomerFirstName] = useState(customer?.customer?.first_name);
    const [customer_last_name, setCustomerLastName] = useState(customer?.customer?.last_name);
    const customer_email = customer?.customer?.email;
    const customer_id = customer?.customer?._id;
    const customer_business_partner = customer?.customer?.business_partner;
    const customer_business_partne_id = customer?.customer?.business_partner_id;
    const [custBalance, setCustBalance] = useState('')
    const [existWalletAmount, setExistWalletAmount] = useState(0);
    const [custoermAvalBalance, setCustoermAvalBalance] = useState(0);
    const rbalance_redux = useSelector((state) => state.customer_balance_Reducer.data);
    const [disableBtn, setDisableBtn] = useState(false);
    const [similarGiftCards, setSimilarGiftCards] =useState([])

    let token;
    if (customer) {
        token = customer.token;
    } else {
        token = null;
    }
    var balance = 0;
    let nBal = Number(0);
    const [allCartData, setAllCartData] = useState([])

    const [ws, setWs] = useState(null);
    // const [isConnected, setIsConnected] = useState(false); // Track connection status

    // useEffect(() => {
    //     if (!isConnected) { // Check if WebSocket connection is not already established
    //       const newWs = new WebSocket('ws://localhost:3001');

    //       newWs.onopen = () => {
    //         setIsConnected(true); // Update connection status
    //       };

    //       newWs.onmessage = (event) => {
    //           const message = JSON.parse(event.data);
    //           if (message.action === 'deleteItemToCart') {
    //             dispatch(getcart(token));
    //           }
    //       };

    //       newWs.onclose = () => {
    //         setIsConnected(false); // Reset connection status
    //       };

    //       setWs(newWs);
    //     }

    //     return () => {
    //       if (ws) {
    //         ws.close();
    //       }
    //     };
    //   }, [isConnected]); // Only re-run effect when isConnected changes


    const [isConnected, setIsConnected] = useState(false); // Track connection status
    useEffect(() => {
        if (!isConnected) { // Check if WebSocket connection is not already established
            const newWs = new WebSocket('wss://uat.backend.ncentiva.com/websocket');
        //   const newWs = new WebSocket('ws://localhost:3001');
        
          newWs.onopen = () => {
            console.log('WebSocket connection established');
            setIsConnected(true); // Update connection status
          };
        
          newWs.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.action === 'addItemToCart' || message.action === 'deleteItemToCart' || message.action === 'redemItemFromCart') {
              dispatch(getcart(token));
            } 
          };
        
          newWs.onclose = () => {
            console.log('WebSocket connection closed');
            setIsConnected(false); // Reset connection status
          };
        
          setWs(newWs);
        }

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [isConnected, dispatch, token]); // Only re-run effect when isConnected or token changes

    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
    };


    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [termsLink, setTermsLink] = useState('');
    const [privacyLink, setPrivacyLink] = useState('');

    useEffect(() => {
        getDocument();
    }, []);

    const getDocument = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getTermsConditionAndPrivacyPolicyFile`)
            .then((response) => {
                setTermsLink(response.data?.termsFile?.fileLink);
                setPrivacyLink(response.data?.privacyFile?.fileLink);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openPDF = (link) => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    const handleTextClick = (link) => {
        openPDF(link);
    };

    // const handleCheckboxChange = (type) => {
    //     if (type === 'terms') {
    //         setIsCheckedTerms(!isCheckedTerms);
    //     } else if (type === 'privacy') {
    //         setIsCheckedPrivacy(!isCheckedPrivacy);
    //     }
    // };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [isMaintenance, setIsMaintenance] = useState(false);
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
    const [maintenanceEndTime, setMaintenanceEndTime] = useState('');

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/check-maintenance`)
            .then((response) => {
                if (response.data.message === "Maintenance time is still ongoing") {
                    setIsMaintenance(true);
                    setMaintenanceMessage(response.data.message);
                    setMaintenanceEndTime(response.data.data.endTime); // Assuming the response contains the end time
                    setShowPopup(true);
                } else {
                    setIsMaintenance(false);
                }
            })
            .catch((error) => {
                console.log(`Error: ${error}`)
            })
    }, [])

    // const getAllData = () => {
    //     token = customer.token;
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/getholdcart/${customer_id}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }

    //         }
    //         )
    //         .then((response) => {

    //             if (response.data.message === "Please Login") {
    //                 localStorage.removeItem('customer');
    //                 navigate('/login');
    //                 toast.error("Session Expired Please Login Again")

    //             }
    //             if (response.data.status === "success") {
    //                 setAllCartData(response.data.cart);
    //             }
    //         })
    //         .catch((error) => console.error(`Error: ${error}`));
    // };

    // useEffect(()=> {
    //     // getAllData()
    //   dispatch(getcart(token));
    // }, []);

    useEffect(() => {
        dispatch(getcart(token));
        // const intervalId = setInterval(() => {
        //     dispatch(getcart(token));
        // }, 1000);
        // return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        if (reduxCartData) {
            if (reduxCartData?.data?.message === "Please Login") {
                localStorage.removeItem('customer');
                navigate('/login');
                toast.error("Session Expired Please Login Again")
            }
        }
    }, [reduxCartData?.data?.cart])
    
    function removeData(id, objId) {

        const localId = Number(id);
        setDisabled(true);
        setRedeemDisabled(true);
        setTimeout(() => {
            setDisabled(false);
            setRedeemDisabled(false);
        }, 500);
        var contactList;
        let carts = reduxCartData?.data?.cart;
        if (carts) {
            carts = carts.filter((item) => item.customer_id === cusId);
        }
        const cartList = carts
        contactList = cartList;
        let usedBalance = parseInt(0);
        for (let i = 0; i < contactList?.length; i++) {
            usedBalance = usedBalance + parseFloat(contactList[i].cost)
        }
        let customerBal = Number(customer.customer.balance);
        let deletedItem = contactList.filter(function (item) { return item.id === localId; });

        contactList = contactList.filter(function (value) {
            return value.id !== localId;
        });
        let walletAmount = Number(0);
        let usedWallet = Number(0);
        for (let i = 0; i < cartList?.length; i++) {
            walletAmount = walletAmount + parseFloat(cartList[i].ncentiva_wallet)
            if (cartList[i].wallet) {
                usedWallet += parseFloat(cartList[i].cost);
            }
        }
        if (customerBal < usedBalance) {
            if (deletedItem[0]?.wallet === true) {
                nBal = parseFloat(usedWallet) - parseFloat(deletedItem[0].cost);
                window.localStorage.setItem('ncentiva_wallet', nBal)

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(contactList));
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        toast.success(`Deleted Successfully `);
                        dispatch(getcart(token));
                        // setTimeout(() => {
                        //     window.location.reload();    
                        // }, 2000);
                        return false;
                    })
                    .catch((err) => console.log(`Error ${err}`));
            } else {
                // if(deletedItem[0].ncentiva_wallet > 0) {
                let walletBal = parseFloat(walletAmount) - parseFloat(deletedItem[0]?.ncentiva_wallet);
                walletBal.toFixed(2);
                const walletData = contactList.filter((cust) => cust.wallet === false);

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(walletData))
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        window.localStorage.setItem('ncentiva_wallet', walletBal)
                        toast.success(`Deleted Successfully `);
                        dispatch(getcart(token));
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 2000);
                        return false
                    })
                    .catch((err) => console.log(`Error ${err}`));


            }
        } else {
            for (let i = 0; i < contactList?.length; i++) {
                nBal += contactList[i].ncentiva_wallet
            }
            window.localStorage.setItem('ncentiva_wallet', nBal)
        }

        axios
            .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
            .then(() => {
                localStorage.setItem('listItem', JSON.stringify(contactList))
                if (ws) {
                    ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                //get data again
                toast.success(`Deleted Successfully `);
                dispatch(getcart(token));
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
            })
            .catch((err) => console.log(`Error ${err}`));

    }
    // var token;
    var id;
    var name;
    var rbalance;
    let toatalUsedBalance = Number(0);

    for (let a = 0; a < cartList?.length; a++) {
        toatalUsedBalance += parseFloat(cartList[a].cost)
    }




    const getcustomer = (id) => {

        // setIsLoading(true);
        if (customer) {
            token = customer?.token;
        } else {
            token = null;
        }

        if (!customer) {
            navigate('/')
        }
        if (id) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customerfrontend/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    setModal_status(response.data.customer.modal_status)
                    if (response.data.message === "Please Login") {
                        localStorage.removeItem('customer');
                        // localStorage.removeItem('listItem');
                        navigate('/login');
                        toast.error("Session Expired Please Login Again")
                        // window.location.reload();
                    }
                    if (cartList) {
                        var sbalance = response.data.customer.balance;
                        setExistWalletAmount(response.data.customer.wallet_balance)
                        setCustoermAvalBalance(response.data.customer.balance);
                        let sum = 0;
                        let bonus = 0;
                        let costValue = 0;
                        let ncentivaBalance = 0;
                        let totalCost = 0;
                        let saveWallet = Number(0);
                        let walletAmount = Number(0);
                        for (var i = 0; i < cartList.length; i++) {
                            if (cartList[i].wallet === false) {
                                totalCost += parseFloat(cartList[i].cost);
                            } else {
                                walletAmount += parseFloat(cartList[i].cost);
                            }
                            sum += parseFloat(cartList[i].cost);
                            bonus += parseFloat(cartList[i].bonus);
                            costValue += parseFloat(cartList[i].costValue);
                            ncentivaBalance += parseFloat(cartList[i].ncentiva_wallet);
                        }
                        // if (sbalance <= 0) {
                        //     setUsedBalance(costValue)
                        // } else {
                        setUsedBalance(totalCost);
                        // }
                        let totalAddedBonus = parseFloat(bonus) + parseFloat(ncentivaBalance) + parseFloat(sum)
                        let totalBouns = parseFloat(bonus) + parseFloat(ncentivaBalance)
                        setTotalUsedBonus(totalBouns.toFixed(2))
                        setTotalBounsCash(ncentivaBalance.toFixed(2))
                        setTotalUsedBalance(sum)
                        rbalance = parseFloat(sbalance) - parseFloat(sum);
                        rbalance = rbalance.toFixed(2);
                        setUsedFund(parseFloat(sum).toFixed(2));
                        saveWallet = parseFloat(ncentivaBalance) - parseFloat(walletAmount)
                        setCustBalance(parseFloat(sbalance).toFixed(2) - parseFloat(totalCost).toFixed(2))
                        setWalletFund(saveWallet.toFixed(2));
                        setBonus(bonus);
                        if (ncentivaBalance > 0) {
                            const vall = ncentivaBalance.toFixed(2)
                            const val = parseFloat(vall) + parseFloat(rbalance);
                            setrFund(val);
                            setWalletbalance(val);
                        } else {
                            setrFund(rbalance);
                            setWalletbalance(ncentivaBalance);
                        }


                        setTotalValue(totalAddedBonus);
                        setCustomerFirstName(response.data.customer.first_name);
                        setCustomerLastName(response.data.customer.last_name);
                    } else {
                        setrFund(response.data.customer.balance);
                    }
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);

                })
                .catch((error) => console.error(`Error: ${error}`));

        } else if (id === undefined) {
            navigate('/');
        }

    };

    var payoutItem = {
        totalValue: totalValue,
        usedFund: usedFund,
        value: totalUsedBalance
    }


    if (customer) {
        id = customer?.customer?._id;

        name = customer?.customer?.first_name + " " + customer?.customer?.last_name;
    } else {
        name = null;
        balance = null;
        navigate('/');
    }

    const modalStatus = async () => {
        var token;
        const user = JSON.parse(window.localStorage.getItem("customer"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customermodalstatus/${id}`, {
            modal_status: false
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });


    }
    const addCart = async (data) => {
        // e.preventDefault();
        setRedeemDisabled(true);
        setTimeout(() => {
            setRedeemDisabled(false);
        }, 500)
        setIsLoading(true);

        try {
            var token;
            const user = JSON.parse(window.localStorage.getItem("client"));
            if (user) {
                token = user.token;
            } else {
                token = null;
            }
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/addtocart`, {
                cartList,
                customer_first_name,
                customer_last_name,
                customer_email,
                customer_id,
                customer_business_partner,
                customer_business_partne_id,
                bonus,
                usedFund,
                walletFund,
                custBalance,
                totalUsedBonus,
                totalBonusCash,
                checkTermsOfUseAndPrivacyPolicy: isChecked,
                // checkedTermsOfUse: isCheckedTerms,
                // checkedPrivacyPolicy: isCheckedPrivacy
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (data.status === "Success") {
                localStorage.removeItem('listItem');
                localStorage.setItem('payoutItem', JSON.stringify(payoutItem))
                if (ws) {
                    ws.send(JSON.stringify({ action: 'redemItemFromCart' }));
                } else {
                    console.error('WebSocket connection is not established');
                }
                navigate('/payout')
                toast.success(data.message);
                if (modal_status === true) {
                    modalStatus();
                }

            } else {
                toast.error(data.status);
                setTimeout(() => {
                    navigate('/giftcards')
                }, 500);
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        } catch (err) {
            console.log(`Error : ${err}`);
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message;
                toast.error(errorMessage || "An error occurred while processing your request. Please check your input and try again.");
            } else {
                toast.error("An error occurred while processing your request.");
            }
            setTimeout(() => {
                setIsLoading(false)
            }, 500);

        }
    }

    useEffect(() => {
        if (reduxCartData?.data?.cart !== null) {
            // setTimeout(() => {
            getcustomer(id, token);
            // }, 500);
        }

    }, [reduxCartData?.data?.cart, usedFund, rFund, id]);

    const handleClick = event => {
        event.currentTarget.classList.toggle('active');
    };
    // const dispatch = useDispatch();
    // const cmsData = useSelector((state) => state.cmsReducer);

    useEffect(() => {
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            setIsLoading(true);
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    setIsLoading(true);
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setPrimaryColor(res.data?.cms?.primary_color);
                        setSecondaryColor(res.data?.cms?.secondary_color);
                    } else {
                        setCmsData(true)
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                        setPrimaryColor("#FF7020");
                        setSecondaryColor("#F5C400");
                    }

                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);



                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, []);

    // Implementing auto logout by inactivity
    useEffect(() => {
        let timeoutId;
        function onUserActivity() {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                localStorage.removeItem('customer');
                navigate('/login');
                // toast.error("Session Expired Please Login Again")
            }, 900000);
        }

        function resetTimeout() {
            onUserActivity();
        }

        // Events for desktop
        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keydown', resetTimeout);

        // Events for mobile
        window.addEventListener('touchstart', resetTimeout);
        window.addEventListener('touchmove', resetTimeout);
        window.addEventListener('touchend', resetTimeout);

        // Initialize timeout on component mount
        onUserActivity();

        // Cleanup event listeners and timeout on component unmount
        return () => {
            // Cleanup
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keydown', resetTimeout);
            window.removeEventListener('touchstart', resetTimeout);
            window.removeEventListener('touchmove', resetTimeout);
            window.removeEventListener('touchend', resetTimeout);
            clearTimeout(timeoutId);
        };
    }, [navigate]);


    let localWallets = window.localStorage.getItem('ncentiva_wallet');
    let listBalance = Number(0);
    let walletBonus = Number(0);
    let customerBal = Number(customer?.customer.balance);
    for (let i = 0; i < cartList?.length; i++) {
        listBalance = listBalance + parseFloat(cartList[i].cost)
        walletBonus = walletBonus + parseFloat(cartList[i].ncentiva_wallet)
    }
    const parsedBonus = Number(bonus) || 0;
    const parsedLocalWallets = Number(localWallets) || 0;

    const total = parsedBonus + walletBonus;

    const formattedBonus = parsedBonus.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedLocalWallets = parsedLocalWallets.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedTotal = total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');


    let remain = Number(0);
    // remain = parseFloat(customer?.customer.balance) - parseFloat(usedFund);

    let wBalance = Number(0);
    let totalWalletAmount = Number(0);
    let remaingWallet = Number(0);
    let walletCash = Number(0);
    let walletUsed = Number(0);
    let amountUsed = Number(0);
    let walletAmount = Number(0);
    let bonusAmount = Number(0);

    for (let i = 0; i < cartList?.length; i++) {
        wBalance += parseFloat(cartList[i]?.ncentiva_wallet);
        amountUsed += parseFloat(cartList[i]?.cost);
        if (cartList[i].wallet === true) {
            // remaingWallet += parseFloat(cartList[i].cost); 
            walletUsed += parseFloat(cartList[i].cost);
        } else {
            walletAmount += parseFloat(cartList[i].cost)
        }
    }
    walletCash = wBalance
    let reaminingBonuscash = Number(0);
    let totalfundUsed = usedBalance + walletUsed;
    reaminingBonuscash = parseFloat(existWalletAmount) - parseFloat(walletUsed);
    let totalBalance = Number(0);

    if (!customer || customer == null) {
        navigate('/');
    }
    bonusAmount = wBalance

    wBalance = parseFloat(wBalance) + parseFloat(existWalletAmount);
    wBalance = parseFloat(wBalance) - parseFloat(walletUsed);
    if (parseFloat(custoermAvalBalance) > 0.00) {
        remain = parseFloat(custoermAvalBalance) - parseFloat(walletAmount);
        remaingWallet = parseFloat(wBalance)
        totalWalletAmount = parseFloat(bonusAmount) + parseFloat(existWalletAmount);
        totalBalance = parseFloat(bonus) + parseFloat(bonusAmount);


    } else {
        remain = "0.00"
        remaingWallet = parseFloat(existWalletAmount) - parseFloat(amountUsed);
        totalWalletAmount = parseFloat(existWalletAmount);
        totalBalance = parseFloat(bonus) + parseFloat(amountUsed)

    }

    const [fundShow, setFundShow] = useState(false)
    const handleTotalFund = () => {
        setFundShow(!fundShow)
    }
    const [bonusShow, setBonusShow] = useState(false)
    const handleTotalbonus = () => {

        setBonusShow(!bonusShow)
    }
    const [reciveShow, setReciveShow] = useState(false)
    const handleTotalrecive = () => {
        setReciveShow(!reciveShow)
    }
   
    const CustomNextArrow = (props) => (
            <button {...props} className="carousel-button next-button">
                <SlArrowRight className='customArrow_customer'/>
            </button>
            );
      
      const CustomPrevArrow = (props) => (
        <button {...props} className="carousel-button prev-button">
                <SlArrowLeft className='customArrow_customer'/>
       </button>
      );
      const slidesToShow= similarGiftCards?.similarCards?.length===3 ? similarGiftCards?.similarCards?.length + 1
       : similarGiftCards?.similarCards?.length===2 ? similarGiftCards?.similarCards?.length + 2 
       : similarGiftCards?.similarCards?.length===1 ? similarGiftCards?.similarCards?.length + 3 : 4;
      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
          {
            breakpoint: 1440, // laptop
            settings: {
              slidesToShow: slidesToShow < 3 ? slidesToShow : 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 992, // laptop
            settings: {
              slidesToShow: slidesToShow < 3 ? slidesToShow : 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // tablet
            settings: {
              slidesToShow: slidesToShow<2 ? 1 : 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 530, // mobile
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      
      useEffect(()=>{
        if(reduxCartData?.data?.cart !== null && arrayOfCartDataID !== undefined){
            dataSimilar();
        }
      },[reduxCartData?.data?.cart])
      const dataSimilar = () =>{
        setSimilarcardLoading(true)
         axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/similar/cards/${customer_business_partne_id}`, 
          {  arrayOfCartDataID },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
            setSimilarGiftCards(response.data)
            setSimilarcardLoading(false)
        })
        .catch((error) => {
          console.log(error)
        })
      }

      
    // const totalUsedValue = parseFloat(totalUsedBalance) + parseFloat(totalBalance)
    return isLoading === true ? (
        <div className="d-flex justify-content-center mt-5 pt-5">
            <Bars
                height="80"
                width="100%"
                margin='auto'
                color="#FF7020"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    ) : (
        <section className="chekout-area">
            <div className="container-fluid">
                <div className="d-flex flex-xl-row flex-column">
                    <div className="col-12 col-xl-8 col-xxl-9 px-0 px-md-4 responsive_main_div">
                        <div className="mt-3">
                            <ReturnBtn to="/" />
                        </div>
                        <div className="checkout-left py-lg-3 py-3">
                            <div className="checkout-left-header mb-4">
                                <h3>Checkout Details</h3>
                                <div className="chekcout-filters">
                                    <ul>
                                        <li>
                                            <button type="button" className="active btn p-0 border-0" onClick={handleClick}>
                                                <span>
                                                    <FaSortAmountDown className="me-1" /> Sort
                                                </span>
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" className="btn p-0 border-0" onClick={handleClick}>
                                                <span>
                                                    <FaSortAmountDown className="me-1" /> Filter
                                                </span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="table-responsive d-none d-sm-block table_scroll_container">
                                <table className="table checkout-table" id="checkout-table">
                                    <thead className="bgLightRed font14 ">
                                        <tr>
                                            <th>
                                                <div className="d-flex align-items-center">
                                                    <span>Gift Card (s)</span>
                                                    <button
                                                        type="button"
                                                        className="btn p-0"
                                                        onClick={handleClick}
                                                    >
                                                        {/* <i className="fas fa-caret-down" /> */}
                                                        <FaCaretDown />
                                                    </button>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-items-center">
                                                    <span>Cost</span>
                                                    <button
                                                        type="button"
                                                        className="btn p-0"
                                                        onClick={handleClick}
                                                    >
                                                        {/* <i className="fas fa-caret-down" /> */}
                                                        <FaCaretDown />
                                                    </button>
                                                </div>
                                            </th>
                                            <th className='text-main'>
                                                <div className="d-flex align-items-center">
                                                    <span>Bonus</span>
                                                    <button
                                                        type="button"
                                                        className="btn p-0 text-main"
                                                        // style={{color:btnColor}}
                                                        onClick={handleClick}
                                                    >
                                                        {/* <i className="fas fa-caret-down" /> */}
                                                        <FaCaretDown />
                                                    </button>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex align-items-center">
                                                    <span>Card Value</span>
                                                    <button
                                                        type="button"
                                                        className="btn p-0"
                                                        onClick={handleClick}
                                                    >
                                                        {/* <i className=   "fas fa-caret-down" /> */}
                                                        <FaCaretDown />
                                                    </button>
                                                </div>
                                            </th>
                                            <th style={{ color: 'rgb(245, 196, 0)' }}>
                                                <div className="d-flex align-items-center">
                                                    <span>Bonus Cash</span>
                                                    <button
                                                        type="button"
                                                        style={{ color: 'rgb(245, 196, 0)' }}
                                                        className="btn p-0"
                                                        onClick={handleClick}
                                                    >
                                                        {/* <i className=   "fas fa-caret-down" /> */}
                                                        <FaCaretDown />
                                                    </button>
                                                </div>
                                            </th>
                                            <th style={{ width: 30 }} />
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {reduxCartData?.data?.cart?.map((cust, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="gift-logo  position-relative">
                                                            <img
                                                                src={cust.img_path}
                                                                className=""
                                                                alt="giftcard logo"
                                                            />
                                                            {/* <div className="rounded-4 p-1 w-25 position-absolute text-center custom_discount_checkout"  >+5.00%</div> */}

                                                        </div>
                                                        <span style={{ width: "max-content" }}>{cust.productname}</span>
                                                    </div>
                                                </td>

                                                {cust.wallet ? <td>${cust.cost} <span style={{ color: "#ff5300" }}>(Bonus Cash)</span></td> : <td>${cust.cost}</td>}
                                                <td className='text-main'>${cust.bonus}</td>
                                                <td className="text-dark" style={{ paddingLeft: '2.5rem' }}>${cust.costValue}</td>
                                                {
                                                    cust.ncentiva_wallet == "0.00" ? <td></td> :
                                                        <td style={{ paddingLeft: '2.5rem', color: 'rgb(245, 196, 0)' }}>${Number(cust.ncentiva_wallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                                }
                                                <td>
                                                    {disabled ? <button type="button" className="btn delete-check p-0 trash_icon">
                                                        <FaTrash />
                                                    </button> : <button type="button" className="btn delete-check p-0 trash_icon">
                                                        <FaTrash onClick={() => {
                                                            removeData(cust.local_id, cust._id);
                                                        }} />
                                                    </button>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-auto listBox" style={{ maxHeight: "300px", paddingTop: "10px" }}>

                                {reduxCartData?.data?.cart && btnColor ? (
                                    reduxCartData?.data?.cart?.map((cust, index) => (
                                        <div
                                            className="  p-0 px-3 col-12 justify-content-between d-block d-sm-none responsive_checout_transaction"
                                            style={{
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                                fontWeight: "400",
                                                padding: "10px",

                                            }}
                                            key={index}
                                        >
                                            <p
                                                className="text-start pb-2"
                                                style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                                {cust.productname}
                                            </p>
                                            <div className="d-flex pb-1 flex-row justify-content-between">
                                                <div className="col-4 position-relative">
                                                    <img
                                                        src={`${cust.img_path}`}
                                                        style={{ height: "75px", width: "120px" }}
                                                    />
                                                    {/* <div className="rounded-4 p-1 w-25 position-absolute text-center custom_discount">
                                            +5.01%
                                            </div> */}
                                                </div>
                                                <div className="col-5 ">
                                                    <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                        <p className="col-8">Cost</p>
                                                        <p className="col-4 ">${cust.cost}</p>
                                                    </div>
                                                    {cust?.bonus > 0 ? <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{ color: "#4221E5" }}>
                                                        <p className="col-8">Bonus</p>
                                                        <p className="col-4 ">${cust.bonus}</p>
                                                    </div> : ""}
                                                    <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                        <p className="col-8">Cart value</p>
                                                        <p className="col-4 ">${cust.costValue}</p>
                                                    </div>
                                                    {cust.ncentiva_wallet > 0 ? <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{ color: 'rgb(245, 196, 0)' }}>
                                                        <p className="col-8">Bonus Cash</p>
                                                        <p className="col-4 ">
                                                            $
                                                            {Number(cust.ncentiva_wallet)
                                                                .toFixed(2)
                                                                .toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </p>
                                                    </div> : ""}
                                                </div>
                                                <div className="d-flex   flex-row-reverse col-auto">
                                                    <FaTrash
                                                        style={{ cursor: "pointer", color: "grey" }}
                                                        className="trash_icon"
                                                        onClick={() => {
                                                            removeData(cust.local_id, cust._id)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <hr
                                                style={{
                                                    width: "100%",
                                                    borderTop: "1px solid black",
                                                    margin: "10px 0",
                                                    height: "1px",
                                                }}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-dark"> Cart Empty</p>
                                )}
                            </div>
                        </div>
                            <div className='responsive_fixed_bottom'>
                                <p className='checkoutText me-xl-5 ms-xl-2' style={{ fontSize: "16px" }}><strong style={{ fontSize: "20px" }}>*</strong><span style={{ fontWeight: "bold", color: 'rgb(245, 196, 0)', margin: '0px 2px' }}> Bonus Cash</span> - will be applied and added to available funds after checkout has been processed.  Can be used as additional funds to purchase other gift cards.  </p>
                                <p className='checkoutText mt-2 me-xl-5 ms-xl-2' style={{ fontSize: "16px" }}><strong style={{ fontSize: "20px" }}>**</strong><span className='text-main' style={{ fontWeight: "bold" }}> Bonus Value</span> - is applied to the current gift card in the cart automatically and will be reflected on the total card value.  Bonus Value is automatically adjusted in cart vs. <span style={{ fontWeight: "bold", color: 'rgb(245, 196, 0)', margin: '0px 2px' }}>"Bonus Cash"</span> that is available after checkout is processed.</p>
                            
                        <hr style={{width: "98%", borderTop: "1px solid black", margin: "10px 0", height: "1px", color:"#DEE2E6"}} />
                        {similarCardLoading ? 
                        <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Bars
                          height="80"
                          margin='auto'
                          width="100%"
                          color="#FF7020"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>:
                        <div className='container-fluid mt-3 p-0'>
                            {similarGiftCards?.similarCards?.length>0? <p className='mb-3 font_similar_card ms-md-3' >Similar GiftCards</p>: null}
                            {/* <div className='slider-container'> */}
                            <Slider {...settings}>
                                {similarGiftCards?.similarCards?.map((data, index) => (
                                <div key={index} className="carousel_slide carousel_slide_checkout">
                                    <CheckOutSimilarBrandGiftCard 
                                    data={data}
                                    btnColor={btnColor}
                                    btnText={btnText}
                                    rbalance_redux={rbalance_redux?.balance}
                                    wallet_balance={rbalance_redux?.wallet_balance}
                                    disableBtn={disableBtn}
                                    setDisableBtn={setDisableBtn}
                                    ws={ws}
                                    cmsData={cmsData}
                                    primaryColor={primaryColor}
                                    secondaryColor={secondaryColor}
                                    />
                                </div>
                                ))}
                            </Slider>
                            {/* </div> */}
                        </div>}
                           </div>
                    </div>

                    <div className="col-12 col-xl-4 col-xxl-3">
                        <div className="checkout-rightp sticky-top  border-start">
                            <div className="checkout-right-top pt-lg-5 pb-4 px-4 border-bottom">
                                {/* <div className="rdeem-progress mb-3 mt-5">
                        <div className="progress-left" style={{ width: "65%" }}>
                            <span>$0</span>
                            <span>$300</span>
                        </div>
                        <div
                            className="progress-right"
                            style={{ width: "20.00%", marginLeft: "auto" }}
                        >
                            <span>Bonus $10.60</span>
                        </div>
                    </div> */}
                                <RedeemProgress primaryColor={primaryColor} secondaryColor={secondaryColor} btnColor={btnColor} btnText={btnText} walletColor={"#f5c400"} bonus={bonus} amount={usedBalance} rFund={remain} wallet={walletUsed.toFixed(2)} custom="rdeem-progress mt-5 mb-3" />
                                <div className="progress-labels flex-wrap">
                                    <div className="pblock-label">
                                        <div
                                            className="pblock-icon"
                                            style={{ background: primaryColor }}
                                        ></div>
                                        <span>Amount Redeemed</span>
                                    </div>
                                    <div className="pblock-label">
                                        <div
                                            className="pblock-icon"
                                            style={{ background: "#ccc" }}
                                        ></div>
                                        <span>Remaining Balance</span>
                                    </div>
                                    <div className="pblock-label">
                                        <div
                                            className="pblock-icon text-main"
                                            style={{ background: 'rgb(61, 5, 182)' }}
                                        ></div>
                                        <span>Bonus</span>
                                    </div>
                                    {/* { walletBonus > 0 ? */}
                                    <div className="pblock-label">
                                        <div
                                            className="pblock-icon text-main"
                                            style={{ background: 'rgb(245, 196, 0)' }}
                                        ></div>
                                        <span>Bonus Cash</span>
                                    </div>
                                    {/* : 
                                    ""} */}

                                </div>
                            </div>
                            <div className="checkout-right-bottom py-4 px-4">

                                {/* <Container fluid className="mt-3 px-4 py-2 rounded shadow" style={{ backgroundColor: '#FF7020' }} onClick={handleTotalFund}> */}
                                <div onClick={handleTotalFund}>
                                    <div className='d-flex align-items-center justify-content-between '>
                                        <h1 className="font16 text-dark "><span className='me-2'>
                                            {fundShow ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            }
                                        </span>Total Funds Used</h1>
                                        {/* <h5>{fundShow ? null : ( */}
                                        <h5>${usedFund === 0 ? "0.00" : totalfundUsed.toFixed(2)}</h5>
                                        {/* )}</h5> */}
                                    </div>
                                </div>
                                {fundShow ?
                                    <div className="border-bottom mb-4 ms-4">
                                        <div className="d-flex align-items-center justify-content-between mt-3">
                                            <h5 style={{ color: primaryColor }}>Funds Used</h5>
                                            <h5 style={{ color: primaryColor }}>${usedFund === 0 ? "0.00" : Number(usedBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between my-3">
                                            <h5 className='text-yellow'>Used Bonus Cash</h5>
                                            <h5 className='text-yellow'>${Number(walletUsed).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between my-3">
                                            <h5 className="text-dark">Remaining Funds</h5>
                                            {listBalance < customerBal ? (
                                                <h5 className="text-dark">${Number(remain).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                            ) : (
                                                <h5 className="text-dark">$ 0.00</h5>
                                            )}
                                        </div>
                                        {/* { remaingWallet > 0 ?  */}
                                        <div className="d-flex align-items-center justify-content-between my-3">
                                            <h5 className="text-dark">Remaining Bonus Cash</h5>
                                            <h5 className="text-dark">${Math.abs(reaminingBonuscash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                        </div>
                                        {/* : 
                                   "" } */}
                                    </div> : <>
                                        <br></br>
                                    </>}
                                {/* <Container fluid className="" style={{ backgroundColor: 'blue' }} onClick={handleTotalbonus}> */}
                                <div onClick={handleTotalbonus}>

                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1 className="font16 text-dark "><span className='me-2'>
                                            {bonusShow ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                </svg>
                                            }
                                        </span>Total Bonus</h1>
                                        {/* { bonusShow ? null : (<h5>${Number(totalBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>)} */}
                                        <h5>${Number(totalBalance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                    </div>
                                </div>
                                {bonusShow ?
                                    <div className="border-bottom mb-4 ms-4">



                                        <div className="d-flex align-items-center justify-content-between my-3">
                                            <h5 className='text-main'>Bonus</h5>
                                            <h5 className='text-main'>${Number(bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                        </div>
                                        {/* {wBalance > 0 ?  */}
                                        <div className="d-flex align-items-center justify-content-between my-3">
                                            <h5 className='text-yellow'>Bonus Cash</h5>
                                            <h5 className='text-yellow'>${Number(walletCash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                                        </div>
                                        {/* :null} */}
                                    </div> :
                                    <>
                                        <br></br>
                                    </>
                                }
                                {/* <Container fluid className="mt-3 px-4 py-2 rounded shadow" style={{ backgroundColor: '#FF7020' }} onClick={handleTotalrecive}> */}
                                <div >

                                    <div className='d-flex align-items-center justify-content-between mb-4'>
                                        <h1 className="font16 text-dark ms-4">Total Received</h1>
                                        <h5><strong>${Number(totalValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></h5>
                                    </div>
                                </div>


                                <div className="link-container mb-3 px-2">
                                        <p className="link-text">
                                            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                            <span style={{ marginRight: '5px' }}></span>
                                            <span className='me-1'>
                                                I read and agree to <t></t>
                                            </span>
                                            <a className='terms_hover' href={termsLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(termsLink)}>Terms of use</a>
                                            <span className='ms-1 me-1'> & </span>
                                            <a className='terms_hover' href={privacyLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(privacyLink)}>Privacy policy</a>
                                        </p>
                                    </div>

                                {totalValue === 0 || isMaintenance ?
                                    <Link to="" className="btn btn-main w-100 "
                                        // style={{ border : `1px solid transparent`, color:btnText, backgroundColor: `${btnColor}80`, cursor: "default", }} 
                                        style={cmsData
                                            ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent`, cursor: "default" }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}`, cursor: "default" }
                                        }
                                        disabled>
                                        Redeem
                                    </Link>
                                    :
                                    <>
                                        {redeemDisabled ? <Link to="" className="btn btn-main w-100 redeem_hover"
                                            style={cmsData
                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            }
                                            disabled>
                                            Redeem
                                        </Link> :
                                            //     <Link
                                            //     onClick={() => {
                                            //         if (isCheckedPrivacy && isCheckedTerms) {
                                            //             addCart();
                                            //         }
                                            //     }}
                                            //     to=""
                                            //     className={`btn btn-main w-100 ${!(isCheckedPrivacy && isCheckedTerms) ? 'disabled' : ''}`}
                                            //     style={cmsData
                                            //         ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                            //         : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            //     }
                                            //     disabled={!(isCheckedPrivacy && isCheckedTerms)}
                                            // >
                                            //     Redeem
                                            // </Link>

                                        !isChecked ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="checkbox-tooltip">
                                                            Please accept Terms of use & Privacy policy
                                                        </Tooltip>
                                                    }
                                                    trigger={['hover', 'focus']}
                                                >
                                                    <span>
                                                        <Link
                                                            onClick={() => {
                                                                if (isChecked) {
                                                                    addCart();
                                                                }
                                                            }}
                                                            to=""
                                                            className={`btn btn-main w-100 redeem_hover disabled`}
                                                            style={cmsData
                                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            }
                                                            disabled
                                                        >
                                                            Redeem
                                                        </Link>
                                                    </span>
                                                </OverlayTrigger>
                                            ) : (
                                                <Link
                                                    onClick={() => {
                                                        if (isChecked) {
                                                            addCart();
                                                        }
                                                    }}
                                                    to=""
                                                    className={`btn btn-main w-100 redeem_hover`}
                                                    style={cmsData
                                                        ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                        : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                    }
                                                >
                                                    Redeem
                                                </Link>
                                            )
                                            
                                        }
                                    </>
                                }
                                <div className='mt-4'>
                                    {/* <div className="link-container">
                                            <p className="link-text">
                                                <input type="checkbox" checked={isCheckedTerms} onChange={() => handleCheckboxChange('terms')} />
                                                <span style={{ marginRight: '5px' }}></span>
                                                <span className='me-1'>
                                                    I read and agree to <t></t>
                                                </span>
                                                <a href={termsLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(termsLink)}>Terms of use</a>
                                            </p>
                                            <p className="link-text">
                                                <input type="checkbox" checked={isCheckedPrivacy} onChange={() => handleCheckboxChange('privacy')} />
                                                <span style={{ marginRight: '5px' }}></span>
                                                <span className='me-1'>
                                                    I read and agree to <t></t>
                                                </span>
                                                <a href={privacyLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(privacyLink)}>Privacy policy</a>
                                            </p>
                                         <p>
                                            By clicking on “Redeem” you accept the <a href={termsLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(termsLink)}>Terms of Use</a> and <a href={privacyLink} target="_blank" rel="noopener noreferrer" onClick={() => handleTextClick(privacyLink)}>Privacy Policy</a>
                                        </p> 
                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Modal show={showPopup} onHide={handleClosePopup} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Maintenance Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>{maintenanceMessage}</p> */}
                    <p>Our site is currently on maintenance.</p>
                    {isMaintenance && (
                        <p>
                            You can resume your order after {maintenanceEndTime} hours eastern time period.
                            {/* Maintenance will be enabled again at {maintenanceEndTime} EST */}
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </section>

    )
}

export default CheckoutComp
