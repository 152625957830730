import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const PayoutConfirm = () => {
    const payoutItem = JSON.parse(localStorage.getItem('payoutItem'));
    const customer = JSON.parse(window.localStorage.getItem('customer'));
    let customerBal = Number(customer?.customer.balance);
    const nameDomain = (window.location.host.split('.'));
    const [btnColor, setBtnColor] = useState("");
    const [btnText, setBtnText] = useState("");
    const [cmsData, setCmsData] = useState(false)
    const [primaryColor, setPrimaryColor] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        setPrimaryColor(res.data?.cms?.primary_color);
                    } else {
                        setCmsData(true)
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                        setPrimaryColor("#FF7020");
                    }
                    setIsLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setIsLoading(false);
        }
    }, []);
    return (
        <>
            {isLoading ? '' :
                <section className="pconfirmation-area py-lg-5 py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <img
                                    src="assets/images/congratulations.png"
                                    alt="payout confirmation"
                                    className="img-fluid mb-4"
                                />
                                <h3 className="cngt-title mb-0 mb-lg-5">Congratulations! {customer?.customer?.first_name} {customer.customer.last_name}</h3>
                                <h5 className="recvupdate mb-2 mb-lg-4">By the time you are reading this - you will have received an email regarding your order confirmation and details.</h5>
                                <div className="row mb-2 mb-lg-5">
                                    <div className="col-lg-5 d-flex justify-content-center justify-content-lg-end">
                                        <div className="cngta-block my-2 my-lg-0">
                                            <h2 className="text-blue" >${Number(payoutItem.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h2>
                                            <p>What you spent</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 d-flex justify-content-center align-items-center">
                                        <div className="cngt-vs">
                                            <h4>vs</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 d-flex justify-content-center justify-content-lg-start">
                                        <div className="cngta-block my-2 my-lg-0">
                                            <h2 className="text-orange" style={{ color: primaryColor }}>${Number(payoutItem.totalValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h2>
                                            <p>You received!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="cngt-btns">
                                    {customerBal === 0 ?
                                        (btnColor ? <Link to="/" className="btn btn-main w-100"
                                            //  style={{backgroundColor:`${btnColor}80`,color:btnText,border:`1px solid transparent`}}
                                            style={cmsData
                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            }
                                        >
                                            Continue
                                        </Link> : '')
                                        :
                                        (btnColor ? <Link to="/" className="btn btn-main w-100"
                                            style={cmsData
                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            }>
                                            Continue
                                        </Link> : '')
                                    }

                                    {/* <Link to="/giftcards" className="btn btn-outline-secondary otherp-btn w-100">
                Other {nameDomain[0]} products you can benefit from
            </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
        </>
    )
}

export default PayoutConfirm
