import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from 'react-loader-spinner';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
const Orders = () => {
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("client"));
  // var userrole;
  // var usersid;
  if (user) {
    var token;
    // userrole = user.user.role;
    // usersid = user.user._id;
  } else {
    // userrole = null;
    // usersid = null;
    navigate("/admin");
  }
  const [search, setSearch] = useState("");
  const [customerOrders, setCustomerOrders] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [costorder, setCostorder] = useState("ASC");
  const [bonusorder, setBonusorder] = useState("ASC");
  const [costvalueorder, setCostvalueorder] = useState("ASC");
  const [productnameorder, setProductnameorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [sort, setSort] = useState("");
  // const [customerCreate, setCustomerCreate] = useState([]);
  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };


  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");

    } else {
      setSort("ascName");

      setOrder("ASC");

    }
  };

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  const costsorting = (costorder) => {
    if (costorder === "DSC") {
      setSort("dscCost");

      setCostorder("DSC");
    } else {
      setSort("dscCost");

      setCostorder("ASC");
    }
  };

  const bonussorting = (bonusorder) => {
    if (bonusorder === "DSC") {
      setSort("dscBonus");

      setBonusorder("DSC");
    } else {
      setSort("ascBonus");

      setBonusorder("ASC");
    }
  };

  const costvaluesorting = (costvalueorder) => {
    if (costvalueorder === "DSC") {
      setSort("dscCostValue");

      setCostvalueorder("DSC");
    } else {
      setSort("ascCostValue");

      setCostvalueorder("ASC");
    }
  };

  const productnamesorting = (productnameorder) => {
    if (productnameorder === "DSC") {
      setSort("dscProductName");

      setProductnameorder("DSC");
    } else {
      setSort("ascProductName");

      setProductnameorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };


  const getTransactionData = () => {
    setIsLoading(true)
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getorders?page=${pageNumber}&search=${search}&sort=${sort}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    )
      .then((response) => {
        const allOrders = response.data.orders
        setCustomerOrders(allOrders)
        const totalPages = response.data.totalPages;
        setNumberOfPages(totalPages);
        setIsLoading(false);
      })
      .catch((error) =>
        console.log(error)
      )
  };



  // const gotoPrevious = () => {
  //   setIsLoading(true);
  //   setPageNumber(Math.max(0, pageNumber - 1));
  //   return getTransactionData(pageNumber);
  // };

  // const gotoNext = () => {
  //   setIsLoading(true);
  //   setPageNumber(Math.min(numberOfPages - 1, pageNumber + 1));
  //   return getTransactionData(pageNumber);
  // };

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }



  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<=numberOfPages){
      setPageNumber(page);
    }else if(pages>numberOfPages){
      setPageNumber(0);
    }else{
      setPageNumber(0);
    }
    }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
  };

  useEffect(() => {
    setIsLoading(true);
    getTransactionData(pageNumber);
    redirectToLogin();
    setIsLoading(false);
  }, [pageNumber, search, sort]);





  return (
    <>
      <div className="p-3 p-lg-5">
        <Row>
          <Col md={4}>
            <h1 className="font20 mb-4 text-dark">Orders</h1>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
        </Row>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row>
            <Col md={4}>
              <div style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={({ currentTarget: input }) =>
                      handleSearch(input.value)
                    }
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={4}></Col>
          </Row>
          <Row className="align-items-center">
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table className="table " style={{ verticalAlign: "middle" }}>
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                      <th className="text-start py-3 " >
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Name</span>
                          {order === "ASC" ? (
                            <FaSortDown onClick={() => sorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => sorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Email</span>
                          {emailorder === "ASC" ? (
                            <FaSortDown onClick={() => emailsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => emailsorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Cost</span>
                          {costorder === "ASC" ? (
                            <FaSortDown onClick={() => costsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => costsorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Bonus</span>
                          {bonusorder === "ASC" ? (
                            <FaSortDown onClick={() => bonussorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => bonussorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Costvalue</span>
                          {costvalueorder === "ASC" ? (
                            <FaSortDown onClick={() => costvaluesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => costvaluesorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Product Name</span>
                          {productnameorder === "ASC" ? (
                            <FaSortDown onClick={() => productnamesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => productnamesorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Updated At</span>
                          {updateorder === "ASC" ? (
                            <FaSortDown onClick={() => updatesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => updatesorting("ASC")} />
                          )}
                        </div>
                      </th>

                      <th className="py-3">Status</th>
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
                  {isLoading?(
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="#FF7020"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ):""}
                    {customerOrders.map((cust) => (
                      <tr
                        key={cust._id}
                        className={cust.id === 123 ? "bgLightRed" : ""}
                      >
                        <td className="font14 fw-medium  py-3">
                          {cust.customer_first_name}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                          {cust.customer_email}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                        ${Number(cust.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                        ${Number(cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                        ${Number(cust.costValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </td>
                        <td className="py-3 font14 fw-medium ">
                          {cust.productname}
                        </td>
                        <td className="py-3 font14 fw-medium">
                          {" "}
                          {cust.updatedAt.slice(0, 16).replace("T", "      ")}
                        </td>

                        <td className="py-3  font14 fw-medium">
                          {cust.status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>


            {/* <Col xs lg="2">
          <input  style={{width:"30px"}} type="number" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input>/ {numberOfPages} 
          </Col> */}
            <Col className="mt-5 mb-4">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">


                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                </ul>
              }
            </Col>


          </Row>
        </Container>
      </div>
    </>
  );
};

export default Orders;

















